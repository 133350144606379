import {chatConstants, userConstants} from '../_constants';

export function chat(state = {}, action) {
  switch (action.type) {
    case chatConstants.GETCONVERSATIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case chatConstants.GETCONVERSATIONS_SUCCESS:
    case chatConstants.DELETECONVERSATION_SUCCESS:
      return {
        ...state,
        loading: false,
        conversations: action.conversations
      };
    case chatConstants.GETCONVERSATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case chatConstants.GETCONVERSATION_SUCCESS:
      return {
        ...state,
        loading: false,
        conversation: action.conversation
      };
    case chatConstants.GETCONVERSATIONS_FAILURE:
    case chatConstants.GETCONVERSATION_FAILURE:
    case chatConstants.DELETECONVERSATION_FAILURE:
      return {
        error: action.error
      };
    case chatConstants.POSTNEWMESSAGES_REQUEST:
    case chatConstants.GETNEWMESSAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case chatConstants.POSTNEWMESSAGES_SUCCESS:
    case chatConstants.GETNEWMESSAGES_SUCCESS:
      if (action.message) {
        return {
          ...state,
          loading: false,
          conversation: { ...state.conversation, newMessages: { ...state.conversation.newMessages, [action.message.id]: action.message } }
        };
      } else {return state}
    case chatConstants.POSTNEWMESSAGES_FAILURE:
    case chatConstants.GETNEWMESSAGES_FAILURE:
      return {
        error: action.error
      };
    case chatConstants.CHAT_MERCURE_OPEN:
      return {
        ...state,
        eventSource: action.eventSource
      };
    case chatConstants.CHAT_LIST_RESET:
      return {
        ...state,
        eventSource: null
      };
    case chatConstants.DELETECONVERSATION_REQUEST:
      // add 'deleting:true' property to entry being deleted
      return {
        ...state,
        conversations: state.conversations.map(conversation =>
          conversation.identifier === action.identifier
            ? { ...conversation, deleting: true }
            : conversation
        )
      };
    default:
      return state
  }
}
