import { chatConstants } from '../_constants';
import { chatService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const chatActions = {
    getConversations,
    openConversation,
    getConversation,
    deleteConversation,
    postMessage,
    getNewMessages
};

function openConversation(data) {
  return dispatch => {
    dispatch(request(data));

    chatService.openConversation(data)
      .then(
        conversation => {
          dispatch(success(conversation));
          history.push('/chat/' + conversation.meta.identifier)
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data) { return { type: chatConstants.GETCONVERSATION_REQUEST, data } }
  function success(conversation) { return { type: chatConstants.GETCONVERSATION_SUCCESS, conversation } }
  function failure(error) { return { type: chatConstants.GETCONVERSATION_FAILURE, error } }
}

function getConversations() {
  return dispatch => {
    dispatch(request());

    chatService.getConversations()
      .then(
        conversations => {
          dispatch(success(conversations));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: chatConstants.GETCONVERSATIONS_REQUEST } }
  function success(conversations) { return { type: chatConstants.GETCONVERSATIONS_SUCCESS, conversations } }
  function failure(error) { return { type: chatConstants.GETCONVERSATIONS_FAILURE, error } }
}

function getConversation(identifier) {
  return dispatch => {
    dispatch(request());

    chatService.getConversation(identifier)
      .then(
        conversation => {
          dispatch(success(conversation));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: chatConstants.GETCONVERSATION_REQUEST } }
  function success(conversation) { return { type: chatConstants.GETCONVERSATION_SUCCESS, conversation } }
  function failure(error) { return { type: chatConstants.GETCONVERSATION_FAILURE, error } }
}

function deleteConversation(identifier) {
  return dispatch => {
    dispatch(request());

    chatService.deleteConversation(identifier)
      .then(
        conversations => {
          dispatch(success(conversations));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: chatConstants.DELETECONVERSATION_REQUEST } }
  function success(conversations) { return { type: chatConstants.DELETECONVERSATION_SUCCESS, conversations } }
  function failure(error) { return { type: chatConstants.DELETECONVERSATION_FAILURE, error } }
}

function getNewMessages(identifier) {
  return dispatch => {
    dispatch(request());

    chatService.getNewMessages(identifier)
      .then(
        messages => {
          for (const [key, value] of Object.entries(messages)) {
            dispatch(success(value));
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: chatConstants.GETNEWMESSAGES_REQUEST } }
  function success(message) { return { type: chatConstants.GETNEWMESSAGES_SUCCESS, message } }
  function failure(error) { return { type: chatConstants.GETNEWMESSAGES_FAILURE, error } }
}

function postMessage(postdata) {
  return dispatch => {
    dispatch(request(postdata));

    chatService.postMessage(postdata)
      .then(
        messages => {
          for (const [key, value] of Object.entries(messages)) {
            dispatch(success(value));
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data) { return { type: chatConstants.POSTNEWMESSAGES_REQUEST, data } }
  function success(message) { return { type: chatConstants.POSTNEWMESSAGES_SUCCESS, message } }
  function failure(error) { return { type: chatConstants.POSTNEWMESSAGES_FAILURE, error } }
}
