import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {userActions} from "../_actions";
import {history} from "../_helpers";

function Navi() {
    const user = useSelector(state => state.authentication.user);
    const context = useSelector(state => state.context);
    const dispatch = useDispatch();
    const localContext = useSelector(state => state.context.local);

    // only show nav when logged in
    if (!user) return null;

    function openSettings() {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: false,
            showProfileId: null,
            showSettings: true
        }
        dispatch(userActions.setLocalContext(localContextUpdated));

        setTimeout(() => {
            document.body.style.position = 'fixed';
            document.body.style.top = `-${window.scrollY}px`;
        }, 500);
    }

    return (
        <Navbar bg="secondary" variant="dark" className="pt-4 pb-2 fixed-top zammadoo-topnav bg-secondary" expand="false" style={{zIndex:1031}}>
            <div className="top-navigation-titel text-white position-absolute text-center w-100 pt-3" style={{left:'0', top:'0', zIndex:'0'}}>{context && context.local && context.local.title}</div>
            <button onClick={openSettings} type="button" className="btn btn-circle border-0 ml-auto" style={{zIndex:'1'}}>
                <img className="zammadoo-icon" src="/svg/settings-wheel.svg" alt="" style={{width:'24px', paddingTop:'0.25rem'}} />
            </button>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link className="nav-item nav-link">Profil</Nav.Link>
                    <Nav.Link target="_blank" href="https://www.zammadoo.com/impressum.html" className="nav-item nav-link">Impressum</Nav.Link>
                    <Nav.Link target="_blank" href="https://www.zammadoo.com/datenschutz-app.html" className="nav-item nav-link">Datenschutz</Nav.Link>
                    <Nav.Link target="_blank" href="https://www.zammadoo.com/nutzungsbedingungen-app.html" className="nav-item nav-link">Nutzungsbedingungen</Nav.Link>
                    <Nav.Link href="/register" className="nav-item nav-link">Logout</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export { Navi };
