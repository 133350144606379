import { userConstants } from '../_constants';

export function searchparams(state = {}, action) {
  switch (action.type) {
    case userConstants.GETSEARCHPARAMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETSEARCHPARAMS_SUCCESS:
      return {
        params: action.searchparams
      };
    case userConstants.GETSEARCHPARAMS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
