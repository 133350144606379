import React, {useEffect, useState} from 'react';
import {MediaObject} from "./MediaObject";
import {ENTRYPOINT} from "../config/entrypoint";
import {activityActions, userActions} from "../_actions";
import { useDispatch, useSelector } from 'react-redux';

const ENTRYPOINT_BIGDATA = 'https://api.bigdatacloud.net';

function MatchDetails(props) {
    const [myProps, setMyProps] = useState({
        style: props.style ? props.style : {},
    });
    const dispatch = useDispatch();
    const person = props.data;
    const activity = useSelector(state => state.activity);
    const localContext = useSelector(state => state.context.local);
    const weekdays = ['SO','MO','DI','MI','DO','FR','SA'];
    const today = new Date();
    const [locationData, setLocationData] = useState({});

    function openProfile(e,id) {
        e.stopPropagation();
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: true,
            showProfileId: id,
            showSettings: false
        }
        dispatch(userActions.setLocalContext(localContextUpdated));

        setTimeout(() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }, 500);
    }

function overrideSearch(e,person) {
}

/*
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
        };

        fetch(`${ENTRYPOINT_BIGDATA}/data/reverse-geocode-client?latitude=${myProps.latitude}&longitude=${myProps.longitude}&localityLanguage=de`, requestOptions)
            .then(response => response.json())
            .then(data => setLocationData(data))
            .catch();
    }, []);
*/
    return (
        <>
        {activity && person && person.profile &&
        <div style={myProps.style}>
            <div className="row">
                <div className="col-3"><a onClick={(e) => openProfile(e, person.profile.id)}>
                    {person.profile && person.profile.profileimage && <MediaObject alt={person.profile.firstname} mediaObjectUrl={person.profile.profileimage} className="rounded-zammadoo border border-primary" />}
                    {person.profile && !person.profile.profileimage && <img alt={person.profile.firstname} src="/profileImagePlaceholder.jpg" className="rounded-zammadoo border border-primary" />}
                </a></div>
                <div className="col d-flex align-items-center">
                    <div className="w-100 text-dark pl-3 h4">
                        {person.profile.firstname}, {person.age}
                    </div>
                </div>
            </div>
            <div className="row no-gutters text-primary pt-1">
                <div className="col text-center">
                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                        <img className="zammadoo-icon" style={{width:'3rem',height:'3rem'}} src={ENTRYPOINT+'/media/activities/'+person.activityIconfilename} alt="" />
                    </div>
                    <div className="align-middle" style={{lineHeight:'1.2', height:'30px'}}>
                        <table className="h-100 w-100">
                            <tbody>
                            <tr>
                                <td className="align-middle"><span className="strong" style={{hyphens:'auto', fontWeight:'bold'}}>{activity.items && activity.items[person.categoryId]['activities'][person.activityId]['name']}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row no-gutters text-primary pt-3 pb-3" style={{borderBottom:'2px solid var(--lightgray)'}}>
                <div className="col-4 text-center">
                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                        <img className="zammadoo-icon" src="/svg/Icon_Datum.svg" alt="" title="Wann?"/>
                    </div>
                    <div style={{lineHeight:'1.2', height:'30px'}}>
                        <table className="h-100 w-100">
                            <tbody>
                            <tr>
                                <td className="align-middle"><span className="text-nowrap ">
                                    {0 === person.remaining.days && 'Heute'}
                                    {1 === person.remaining.days && 'Morgen'}
                                    {1 < person.remaining.days && weekdays[(today.getDay() + person.remaining.days) % 7]}
                                                    </span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-4 text-center">
                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                        <img className="zammadoo-icon" src="/svg/Icon_Uhrzeit.svg" alt=""  />
                    </div>
                    <div style={{lineHeight:'1.2', height:'30px'}}>
                        <table className="h-100 w-100">
                            <tbody>
                            <tr>
                                <td className="align-middle"><span className="text-nowrap text-capitalize">{person.timeWindow.from}-{person.timeWindow.to} Uhr</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-4 text-center pl-1 position-relative overflow-visible pr-3">
                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                        <img className="zammadoo-icon" src="/svg/Icon_Entfernung.svg" alt="" title="Wann?"/>
                    </div>
                    <div style={{lineHeight:'1.2', height:'30px'}}>
                        <table className="h-100 w-100">
                            <tbody>
                            <tr>
                                <td><span className="text-nowrap">{person.distance} km</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {person.location &&
            <div className="row pt-3">
                <div className="col-2">
                    <img src="/svg/Icon-Location.svg" alt="" className="zammadoo-icon" title="Level?"/>
                </div>
                <div className="col">
                    <div className="small text-uppercase"><span className="small style={{lineHeight:'0.8rem'}}">Location-Vorschlag</span></div>
                    <div className="text-primary" style={{lineHeight:'1rem'}}>{person.location.name}</div>
                </div>
            </div>
            }
            {person.activitylevel &&
            <div className="row pt-3">
                <div className="col-2">
                    <img src="/svg/Icon-Level.svg" alt="" className="zammadoo-icon" title="Level?"/>
                </div>
                <div className="col">
                    <div className="small text-uppercase"><span className="small" style={{lineHeight:'0.8rem'}}>Spielstärke</span></div>
                    <div className="text-primary" style={{lineHeight:'1rem'}}>
                        {person.activitylevel == 1 && 'Rookie'}
                        {person.activitylevel == 2 && 'Fortgeschritten'}
                        {person.activitylevel == 3 && 'Pro'}
                    </div>
                </div>
            </div>
            }
            {person.description &&
            <div className="row pt-3">
                <div className="col-2">
                    <img src="/svg/Icon-description.svg" alt="" className="zammadoo-icon"
                         title="Comments to your search"/>
                </div>
                <div className="col">
                    <div className="small text-uppercase"><span className="small" style={{lineHeight: '0.8rem'}}>Info von {person.profile.firstname}</span>
                    </div>
                    <div className="text-primary" style={{lineHeight: '1.25rem'}}>
                        {person.description}
                    </div>
                </div>
            </div>
            }
        </div>
        }
        </>
    );
}

export { MatchDetails };
