import {userConstants} from "../_constants";

let storage = JSON.parse(localStorage.getItem('storage'));
let appContext = JSON.parse(localStorage.getItem('appContext'));
const initialState = storage ? { info:'from localStorage', storage, appContext } : {};

export function context(state = initialState, action) {
  switch (action.type) {
    case userConstants.HANDLE_LOCAL_CONTEXT:
      return {
        ...state,
        info: 'local from Hook',
        local: action.localContext
      };
    case userConstants.HANDLE_HIGHLIGHTERS:
      return {
        ...state,
        info: 'storage from Hook rand:' + Math.random().toString(36).substring(7),
        storage: action.storage
      };
    case userConstants.HANDLE_APP:
      return {
        ...state,
        info: 'load app context:' + Math.random().toString(36).substring(7),
        appContext: action.appContext
      };

    default:
      return state
  }
}
