import { ENTRYPOINT } from '../config/entrypoint';
import { authHeader } from '../_helpers';
import { userService } from './user.service';

export const locationService = {
  getLocations
};

function getLocations(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let concat = '';
    if (params) {
      concat = '/' + params.activity_id + ',' + params.latitude + ',' + params.longitude + ',' + params.distance;
    }

    return fetch(`${ENTRYPOINT}/api/locations${concat}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
