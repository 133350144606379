import { ENTRYPOINT } from '../config/entrypoint';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {activityActions, alertActions, userActions, chatActions} from "../_actions";
import {Modal} from 'react-bootstrap';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Plugins } from '@capacitor/core';
import {MatchDetails, MediaObject} from "../_components";
import {Link} from "react-router-dom";
import Fade from "react-bootstrap/Fade";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {history} from "../_helpers";
import {func} from "prop-types";

function HomePage() {
    const localContext = useSelector(state => state.context.local);
    const storage = useSelector(state => state.context.storage);
    const user = useSelector(state => state.authentication.user);
    const activity = useSelector(state => state.activity);
    const feeds = useSelector(state => state.feeds);
    const profile = useSelector(state => state.profile.profileData);
    const dispatch = useDispatch();
    const weekdays = ['SO','MO','DI','MI','DO','FR','SA'];
    const maxSpecialLikes = 10;
    const today = new Date();
    const [show, setShow] = useState(false);
    const { Geolocation } = Plugins;
    const [geoLocation, setGeoLocation] = useState({loading:true});
    const [searchparams, setSearchparams] = useState(null);
    const [specialLikeCountdown, setSpecialLikeCountdown] = useState({});
    const [open, setOpen] = useState(false);
    const [cardData, setCardData] = useState({});
    const [showTips, setShowTips] = useState(false);
    let geoLocationWatchId;

    useEffect(() => {
        if (profile && profile.id === user.profile.id) {
            if (profile.preferences) {
                const found = profile.preferences.find(val => 'HIDE_FEEDS_TIPS_OVERLAY' === val);
                if (found) {
                    setShowTips(false);
                } else {
                    setShowTips(true);
                }
            }
        } else {
            setShowTips(false);
        }
    }, [profile]);

    useEffect(() => {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            title: <div><button type="button" className="btn float-left text-white" style={{paddingTop: '12px'}}><a href="/searchagent"><img className="zammadoo-icon bell" src="/svg/Glocke_Weiss.svg" alt="" title="Suchagent"/></a></button><h5 className="pt-3" style={{paddingRight:'3rem'}}>Leute in deiner Nähe</h5></div>
        }
        dispatch(userActions.setLocalContext(localContextUpdated));
    }, []);

    useEffect(() => {
        let storageUpdated = {};
        if (storage) {
            storageUpdated = storage;
        }
        storageUpdated.homeLed = false;
        dispatch(userActions.setStorage(storageUpdated));
    }, [storage]);

    useEffect(() => {
        dispatch(activityActions.getActivities());
        dispatch(userActions.getProfile());
    }, []);

    useEffect(() => {
        geoLocationWatchId = Geolocation.watchPosition({timeout:1000, enableHighAccuracy: false}, (position, err) => {
            if (!err) {
                setGeoLocation(position);
                Geolocation.clearWatch({id: geoLocationWatchId});
                dispatch(userActions.getFeeds({latitude:position.coords.latitude, longitude:position.coords.longitude}));
            } else {
                if (!geoLocation.coords) {
//                    setGeoLocation({disabled:true});
//                    dispatch(alertActions.error(err.message));
                }
            }
        })
    }, []);

    function openProfile(e,id) {
        e.stopPropagation();
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: true,
            showProfileId: id,
            showSettings: false
        }
        dispatch(userActions.setLocalContext(localContextUpdated));

        setTimeout(() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }, 500);
    }

    function handleClose() {
        setSearchparams(null);
        setShow(false);
    }

    function overrideSearch(e,person) {
        e.stopPropagation();
        /*if (person.ilike && person.likesme) {
            let o = {person: person.uid, conversation: person.conversation};
            dispatch(chatActions.openConversation(o));
            return false;
        }*/
        setOpen(false);
        if (true == person.picked) {
            setSearchparams(null);
            setShow(true);
            return false;
        }
        let searchParams = person.searchParams;
        if (2 == person.type) {
            let o = {
                person: person.uid,
                ilike: !person.ilike,
                likesme: person.likesme,
            }
            dispatch(userActions.pushSearch(o));
            searchParams.superlike = true;
            searchParams.profileimage = person.profile.profileimage;
            searchParams.uid = person.uid;
            searchParams.conversation = person.conversation;
        }
        searchParams.latitude = geoLocation.coords.latitude;
        searchParams.longitude = geoLocation.coords.longitude;
        searchParams.othername = person.profile.firstname;
        searchParams.gender = person.profile.gender;
        searchParams.fromage = parseInt(person.age) - 5;
        searchParams.toage = parseInt(person.age) + 5;
        setSearchparams(searchParams);
        setShow(true);
    }

    function postSearch() {
        dispatch(userActions.updateSearchparams(searchparams));
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            referrer: {type:'superlike', searchparams: searchparams}
        }
        dispatch(userActions.setLocalContext(localContextUpdated));
        dispatch(userActions.postSearch(searchparams));
        setShow(false);
    }

    function gotoChat() {
        setShow(false);
        let o = {person: searchparams.uid, conversation: searchparams.conversation};
        dispatch(chatActions.openConversation(o));
    }

    function setOpenDatailcard(person) {
        console.log(person);
        setCardData(person);
        setOpen(true);
    }

    function countdown(person) {
        if (person.picked) {
            setSpecialLikeCountdown(specialLikeCountdown => ({...specialLikeCountdown, ['picked_'+person.id]: true}))
        }
    }

    function hideTips() {
        dispatch(userActions.updateProfile({hideFeedsTipsOverlay: true}));
        setShowTips(false);
    }

    return (
        <div className="feeds pl-2">
            {geoLocation.loading && <em>Loading position...</em>}
            {geoLocation.unavailable && <em>Your system is not able to retrieve your position.</em>}
            {geoLocation.disabled && <em>You have to enable permission to retrieve your position, to use this application.</em>}
            <em>&nbsp;{feeds.loading && 'Loading matches...'}</em>
            {feeds.items && 0 === feeds.items.length &&
            <div className="text-center px-4">
                <p>Leider ist noch niemand in Deiner Nähe aktiv.
                    <br/>
                    Teile Zammadoo doch mit Deinen Freunden und lege eine neue Suche an, damit andere Nutzer Dich finden.</p>
                <a className="btn btn-success rounded-pill mb-2" href="https://api.whatsapp.com/send?text=Schau%20mal%20auf%20Zammadoo,%20um%20Partner%20für%20Sport%20und%20Freizeit%20zu%20finden:%20https://www.zammadoo.com" target="_blank">Whats-App <WhatsAppIcon /></a>
                <br/><Link to="/searches" className="btn btn-secondary rounded-pill">Suche anlegen</Link>
            </div>
            }
            {feeds.items &&
            <>
                {feeds.items.map((person, index) =>
                    <div  key={index} className="feed-row row no-gutters align-items-center py-1 mb-2" style={{boxShadow:'0 4px 2px -2px rgba(0, 0, 0, 0.05)'}} onLoad={() => countdown(person)} onClick={() => setOpenDatailcard(person)} aria-controls="example-fade-text" aria-expanded={open}>
                        <div className="col-3 p-1 text-center align-self-start"><a onClick={(e) => openProfile(e, person.profile.id)}>
                            {person.profile.profileimage && <MediaObject alt={person.profile.firstname} mediaObjectUrl={person.profile.profileimage} className="rounded-zammadoo border border-primary" />}
                            {!person.profile.profileimage && <img alt={person.profile.firstname} src="/profileImagePlaceholder.jpg" className="rounded-zammadoo border border-primary" />}
                        </a></div>
                        <div className="col">
                            <div className="row no-gutters">
                                <div className="col-8 text-uppercase text-dark h6">{person.profile.firstname}, {person.age}</div>
                                {false && <div className="col text-dark pr-2" style={{lineHeight:'1'}}>
                                    <div className="p0 text-right"><em className="small">noch</em></div>
                                    <div className="p0 text-right"><em className="small">{(24*person.remaining.moddays + person.remaining.hours) + ' Stunden'}</em></div>
                                </div>}
                            </div>
                            <div className="row no-gutters text-primary text-uppercase">
                                <div className="col text-center" style={{maxWidth:'20%'}}>
                                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                        <img className="zammadoo-icon" src={ENTRYPOINT+'/media/activities/'+person.activityIconfilename} alt="" />
                                    </div>
                                    <div className="align-middle" style={{lineHeight:'1.2', height:'30px'}}>
                                        <table className="h-100 w-100">
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle"><span className="" style={{hyphens:'auto'}}>{activity.items && activity.items[person.categoryId]['activities'][person.activityId]['name']}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col text-center">
                                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                        <img className="zammadoo-icon" src="/svg/Icon_Datum.svg" alt="" title="Wann?"/>
                                    </div>
                                    <div style={{lineHeight:'1.2', height:'30px'}}>
                                        <table className="h-100 w-100">
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle"><span className="text-nowrap ">
                                                        {0 === person.remaining.days && 'Heute'}
                                                        {1 === person.remaining.days && 'Morgen'}
                                                        {1 < person.remaining.days && weekdays[(today.getDay() + person.remaining.days) % 7]}
                                                    </span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col text-center">
                                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                        <img className="zammadoo-icon" src="/svg/Icon_Uhrzeit.svg" alt=""  />
                                    </div>
                                    <div style={{lineHeight:'1.2', height:'30px'}}>
                                        <table className="h-100 w-100">
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle"><span className="text-nowrap text-capitalize">{person.timeWindow.from}-{person.timeWindow.to} Uhr</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col text-center pl-1 position-relative overflow-visible pr-3">
                                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                        <img className="zammadoo-icon" src="/svg/Icon_Entfernung.svg" alt="" title="Wann?"/>
                                    </div>
                                    <div style={{lineHeight:'1.2', height:'30px'}}>
                                        <table className="h-100 w-100">
                                            <tbody>
                                            <tr>
                                                <td><span className="text-nowrap">{person.distance} Km</span></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <button type="button" className="btn zammadoo-feeds-button position-absolute rounded-circle bg-secondary p-1" onClick={(e) => overrideSearch(e, person)} style={{left:'calc(100% - 1.5rem)',top:'0'}}>
                                        {!person.picked &&
                                        <>
                                        {!(person.ilike && person.likesme) && <img className="zammadoo-icon" src="/svg/Herz_mit-Stern_grau-01.svg" alt=""/>}
                                        {person.ilike && person.likesme && <img className="zammadoo-icon" src="/svg/Herz_mit-Stern_grau-01.svg" alt=""/>}
                                        </>
                                        }
                                        {person.picked &&
                                        <>
                                        {!person.likesme && <img className="zammadoo-icon" src="/svg/Herz_mit-Stern_Orange-01.svg" alt=""/>}
                                        {person.likesme && <img className="zammadoo-icon" src="/svg/Herz_mit-Stern_Orange-01.svg" alt=""/>}
                                        </>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </>
            }
            <div className="position-fixed w-100 text-center" style={{bottom:'18vh'}}>
                <a className="btn btn-primary text-white rounded-pill w-50" href="/matches">Meine Suchen</a>
            </div>
            <div className="separator" style={{height:'4.5rem'}}></div>
            <Fade in={open}>
                <div id="fade-card" className="fixed-top match-card w-100">
                    {cardData &&
                    <Card className="border-secondary bg-secondary" style={{borderWidth:'5px',borderRadius:'0.75rem', height:'75vh'}}>
                        <Card.Body className="bg-white pt-3" style={{borderRadius:'0.75rem', overflow:'scroll'}}>
                            <MatchDetails data={cardData} />
                        </Card.Body>
                        <Card.Footer className="text-center bg-secondary py-1">
                            <button type="button" className="btn zammadoo-matchcard-button rounded-circle bg-secondary p-2 border border-white" onClick={(e) => overrideSearch(e, cardData)}>
                                {!cardData.picked &&
                                <>
                                    {!(cardData.ilike && cardData.likesme) && <img className="zammadoo-icon" src="/svg/Special-Like_weiss.svg" alt=""/>}
                                    {cardData.ilike && cardData.likesme && <img className="zammadoo-icon" src="/svg/Special-Like_weiss.svg" alt=""/>}
                                </>
                                }
                                {cardData.picked &&
                                <>
                                    {!cardData.likesme && <img className="zammadoo-icon" src="/svg/Herz_mit-Stern_Orange-01.svg" alt=""/>}
                                    {cardData.likesme && <img className="zammadoo-icon" src="/svg/Herz_mit-Stern_Orange-01.svg" alt=""/>}
                                </>
                                }
                            </button><span className="px-3">&nbsp;</span>
                            <button type="button" className="btn zammadoo-matchcard-button rounded-circle bg-secondary p-2 border border-white" onClick={() => setOpen(!open)}>
                                <span aria-hidden="true" className="text-white zammadoo-icon" style={{fontSize:'1.5rem',lineHeight:'1.7rem', display:'block'}}>×</span><span className="sr-only">Close</span>
                            </button>
                        </Card.Footer>
                    </Card>
                    }
                </div>
            </Fade>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="text-center" closeButton>
                    {searchparams && searchparams.superlike && <Modal.Title className="w-100">Hurray!</Modal.Title>}
                    {searchparams && !searchparams.superlike && <Modal.Title className="w-100">Special Like</Modal.Title>}
                    {!searchparams && <Modal.Title className="w-100">Upps...</Modal.Title>}
                </Modal.Header>
                <Modal.Body className="text-center">
                    {!searchparams &&
                    <p>Du hast dieser Suche bereits ein Special Like gegeben.</p>
                    }
                    {searchparams && searchparams.superlike &&
                    <>
                        {searchparams.profileimage && <p><MediaObject alt={searchparams.othername} mediaObjectUrl={searchparams.profileimage} className="rounded-zammadoo border border-primary" /></p>}
                        <p>{searchparams.othername} hat Deine Suche ebenfalls favorisiert und möchte sich gerne mit Dir verabreden!
                            Sende {1===searchparams.gender && 'ihm'}{2===searchparams.gender && 'ihr'} am besten gleich eine Nachricht!</p>
                    </>
                    }
                    {searchparams && !searchparams.superlike && 0 < maxSpecialLikes - Object.keys(specialLikeCountdown).length &&
                    <>
                        <p>Wenn Du {searchparams.othername}s
                            Suche<br/> <br/><strong>{activity.items && activity.items[searchparams.category_id]['activities'][searchparams.activity_id]['name']}, {0 === searchparams.offsetdays && 'Heute'}
                            {1 === searchparams.offsetdays && 'Morgen'}
                            {1 < searchparams.offsetdays && weekdays[(today.getDay() + searchparams.offsetdays) % 7]}</strong><br/><br/> mit einem Special Like favorisierst,
                            kann {1===searchparams.gender && 'er'}{2===searchparams.gender && 'sie'} Dich direkt
                            in {1===searchparams.gender && 'seiner'}{2===searchparams.gender && 'ihrer'} Trefferliste sehen und sich mit Dir verabreden.
                        </p>
                        <p className="pt-4">Verbleibende Special Likes:</p>
                        <h3 className="text-center">{maxSpecialLikes - Object.keys(specialLikeCountdown).length} </h3>
                    </>
                    }
                    {searchparams && !searchparams.superlike && 0 >= maxSpecialLikes - Object.keys(specialLikeCountdown).length &&
                    <>
                        Du hast bereits 3 aktive Suchen favorisiert. Um eine weitere Suche mit einem Special Like zu favorisieren
                        musst Du warten, bis eine davon abläuft oder Du ein Match hast.
                    </>
                    }
                </Modal.Body>
                <Modal.Footer className="text-center justify-content-center">
                    {searchparams && searchparams.superlike &&
                    <>
                        <button className="btn btn-primary text-white rounded-pill" onClick={gotoChat}>
                            Jetzt Nachricht senden
                        </button>
                    </>
                    }
                    {searchparams && !searchparams.superlike && 0 < maxSpecialLikes - Object.keys(specialLikeCountdown).length &&
                    <>
                        <button className="btn btn-primary text-white rounded-pill" onClick={postSearch}>
                            Special Like vergeben
                        </button>
                    </>
                    }
                    {(!searchparams || !searchparams.superlike && 0 >= maxSpecialLikes - Object.keys(specialLikeCountdown).length) &&
                    <>
                        <button className="btn btn-outline-secondary rounded-pill" onClick={handleClose}>
                            OK
                        </button>
                    </>
                    }
                </Modal.Footer>
            </Modal>
            {showTips &&
            <div className="d-flex align-items-end flex-column  position-fixed w-100 h-100" style={{backgroundColor: 'rgba(0,0,0,0.875)', left:0, top:0, zIndex:1031}}>
                <div className="w-100 p-2"><img className="w-100" src="/svg/TipOverlayTop.svg" /></div>
                <div className="w-100 p-2"><img className="w-100" src="/svg/TipOverlayMiddle.svg" /></div>
                <div className="w-100 mt-auto p-2"><img className="w-100" src="/svg/TipOverlayBottom.svg" /></div>
                <div className="position-absolute" style={{bottom:'7vh', right:'10vw', width:'8vh', height:'8vh'}}>
                    <button onClick={hideTips} className="btn btn-white bg-white rounded-circle font-weight-bold w-100 h-100" style={{fontSize:'3vh'}}>OK</button>
                </div>
            </div>
            }
        </div>
    );
}

export { HomePage };
