import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {history} from "../_helpers";
import md5 from 'md5';
import {chatService} from "../_services";
import {chatActions, userActions} from "../_actions";
import { Plugins } from '@capacitor/core';
const { Device } = Plugins;

function NaviFooter() {
    const user = useSelector(state => state.authentication.user);
    const context = useSelector(state => state.context);
    const searches = useSelector(state => state.searches);
    const conversations = useSelector(state => state.chat.conversations);
    const [eventSource, setEventSource] = useState(null);
    const dispatch = useDispatch();
    let dispatching = false;

    useEffect(() => {
        async function loadDeviceInfo() {
            let deviceInfo = await Device.getInfo();
            console.log(deviceInfo, navigator.userAgent);
        }
        // Execute the created function directly
        loadDeviceInfo();
    }, []);

    useEffect(() => {
        if (user && context.appContext && (context.appContext.synch === false)) {
            let appContextObject = context.appContext;
            appContextObject.synch = true;
            // Create an scoped async function in the hook
            async function loadDeviceInfo() {
                let deviceInfo = await Device.getInfo();
                appContextObject.appVersion = deviceInfo.appVersion;
                dispatch(userActions.registerApp(appContextObject));
                dispatch(userActions.setAppContext(appContextObject));
            }
            // Execute the created function directly
            loadDeviceInfo();
        }
    }, [context]);

    useEffect(() => {
        if (history.location.pathname.indexOf('/login') === -1 && history.location.pathname.indexOf('/pwd') === -1
            && history.location.pathname!=='/register') {
            chatService.subscribeMercure().then(hubConfig => {
                hubConfig.hubUrl.searchParams.append('topic', 'zammadoo/api/leds/' + md5(user.username));
                setEventSource( new EventSource(hubConfig.hubUrl.toString()) );
            });
        }
        return () => {
            if (eventSource) {
                eventSource.close();
            }
            setEventSource( null );
        };
    }, []);

    useEffect(() => {
        if (eventSource) {
            eventSource.onmessage = e => {
                const payload = JSON.parse(e.data);
                if (payload) {
                    if ( ! dispatching ) {
                        dispatching = true;
                        let storageUpdated = {};
                        if (context.storage) {
                            storageUpdated = context.storage;
                        }
                        for (const [key, value] of Object.entries(payload)) {
                            storageUpdated[key] = value;
                        }
                        dispatch(userActions.setStorage(storageUpdated));
                        setTimeout(() => { dispatching = false; }, 500);
                    }
                }
            };
        }
    }, [eventSource]);

    useEffect(() => {
        if (history.location.pathname.indexOf('/login') === -1 && history.location.pathname.indexOf('/pwd') === -1
            && history.location.pathname!=='/register') {
            if (!searches.matches) {
                dispatch(userActions.getSearches());
            }
            if (!conversations) {
                dispatch(chatActions.getConversations());
            }
        }
    }, [user]);

    useEffect(() => {
        let hasMatches = false;
        let storageUpdated = {};
        if (searches.matches && searches.matches[0] && 0 <  searches.matches[0].searchMatches.length) {
            hasMatches = true;
        }
        if (context.storage) {
            storageUpdated = context.storage;
        }
        storageUpdated.matchesLed = hasMatches;
        dispatch(userActions.setStorage(storageUpdated));
    }, [searches]);

    useEffect(() => {
        let newMessages = false;
        if (conversations) {
            for (let i=0; i < conversations.length; i++) {
                if (conversations[i].newMessages > 0) {
                    newMessages = true;
                    break;
                }
            }
            let storageUpdated = {};
            if (context.storage) {
                storageUpdated = context.storage;
            }
            storageUpdated.chatLed = newMessages;
            dispatch(userActions.setStorage(storageUpdated));
        }
    }, [conversations]);

    // only show nav when logged in
    if (!user) return null;

    return (
        <Navbar bg="white" className="border-top fixed-bottom pt-2 pb-3 zammadoo-container" expand="true">
            <Nav.Link href="/" className="nav-item nav-link btn btn-lg btn-circle">
                <img className="zammadoo-icon" src={'/svg/zammadoo__z-outline'+(history.location.pathname==='/'?'-primary':'-secondary')+'-large.svg'} alt="" />
            </Nav.Link>
            <Nav.Link href="/matches" className="nav-item nav-link btn btn-lg btn-circle position-relative">
                {context && context.storage && context.storage.matchesLed && <span className="small position-absolute badge badge-pill badge-primary" style={{right: '-0.5rem'}}>&nbsp;</span>}
                <img className="zammadoo-icon" src={'/svg/Suche'+(history.location.pathname==='/matches'?'-primary':'-secondary')+'.svg'} alt="" />
            </Nav.Link>
{/*            <Nav.Link href="/matches" className="nav-item nav-link btn btn-lg btn-circle position-relative">
                {context && context.storage && context.storage.matchesLed && <span className="small position-absolute badge badge-pill badge-primary" style={{right: '-0.5rem'}}>&nbsp;</span>}
                <img className="zammadoo-icon" src={'/svg/'+(history.location.pathname==='/matches'?'Herz_orange_Herz_Orange':'Herz_grau_Herz_grau')+'.svg'} alt="" />
            </Nav.Link>*/}
            <Nav.Link href="/chat" className="nav-item nav-link btn btn-lg btn-circle">
                {context && context.storage && context.storage.chatLed && <span className="small position-absolute badge badge-pill badge-primary" style={{right: '1rem',top:'0.5rem'}}>&nbsp;</span>}
                <img className="zammadoo-icon" src={'/svg/zammadoo__chat-'+(history.location.pathname.indexOf('/chat')===0?'primary':'secondary')+'.svg'} alt="" />
            </Nav.Link>
        </Navbar>
    );
}

export { NaviFooter };
