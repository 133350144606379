import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.SMS_SUCCESS:
      return {
        smsconfirmed: true,
        result: action.data
      };
    case userConstants.LOGIN_FAILURE:
    case userConstants.DELETE_FAILURE:
      return {
        error: action.error
      };
    case userConstants.LOGOUT:
    case userConstants.DELETE_SUCCESS:
      return {};
    default:
      return state
  }
}
