import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { profile } from './profile.reducer';
import { searches } from './searches.reducer';
import { searchparams } from './searchparams.reducer';
import { feeds } from './feeds.reducer';
import { activity } from './activities.reducer';
import { alert } from './alert.reducer';
import { chat } from './chat.reducer';
import { geolocation } from './geolocation.reducer';
import { context } from './context.reducer';
import { appointment } from './appointment.reducer';
import { location } from './locations.reducer';
import {searchagent} from "./searchagent.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  profile,
  searches,
  searchparams,
  feeds,
  activity,
  alert,
  chat,
  geolocation,
  context,
  appointment,
  location,
  searchagent
});

export default rootReducer;
