import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { userActions } from '../_actions';

function LoginPage({ match }) {
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const { dblOptIn } = match.params;
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();
    //start temp get viewport sizes
    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    //end temp get viewport sizes

    // reset login status
    useEffect(() => {
        dispatch(userActions.logout());
        if (dblOptIn) {
            dispatch(userActions.confirm(dblOptIn));
        }
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (username && password) {
            dispatch(userActions.login(username, password));
        }
    }

    return (
        <div className="px-2 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
            <div className="col-lg-8 offset-lg-2">
                <div className="text-center mx-sm-5 px-sm-5 mt-md-5 mb-2"><img src="/svg/zammadoo__z-primary.svg" style={{maxWidth:'280px'}} /></div>
                {false && <><h1 className="display-4 text-center text-primary">zammadoo</h1>
                <h2 className="text-info text-center">Freunde. Treffen.</h2>
                <div className={"text-center mt-md-5"}>
                    <button type="button" className="btn btn-facebook btn-lg"><i className="fa fa-facebook fa-fw"></i> Mit Facebook anmelden
                    </button>
                </div>
                <div className={"text-center mt-md-5 pt-2"} style={{height:'0px'}}>
                    <span className={"bg-white pr-2 pl-2"}> oder </span>
                </div>
                <hr className={"mb-md-5"} /></>}
                <form name="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input placeholder="E-Mail Adresse" type="text" name="username" value={username} onChange={handleChange} className={'form-control' + (submitted && !username ? ' is-invalid' : '')} />
                        {submitted && !username &&
                            <div className="invalid-feedback">E-Mail Adresse is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <input placeholder="Passwort" type="password" name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
                        {submitted && !password &&
                            <div className="invalid-feedback">Password is required</div>
                        }
                    </div>
                    <div className="form-group row">
                        <div className="col-1 col-sm-3"></div>
                        <div className="text-center col-10 col-sm-6">
                            <button className="btn btn-primary rounded-pill mb-3 w-100 text-white">
                                {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Login
                            </button><br/>
                            <a href="/register" className="btn btn-secondary rounded-pill w-100 mb-3">Registrieren</a>
                            <Link to="/pwdfgtn" className="mt-2 w-100">Passwort vergessen</Link>
                        </div>
                    </div>
                </form>
            </div>
            <div className="text-center w-100 align-self-end">
                <a href="https://www.zammadoo.com/impressum.html" className="text-secondary mt-3">© Zammadoo UG</a>
            </div>
        </div>
    );
}

export { LoginPage };
