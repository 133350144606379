import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {activityActions, alertActions, chatActions, geolocationActions, userActions} from '../_actions';
import {LocationName, MatchDetails, MediaObject} from "../_components";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ENTRYPOINT} from "../config/entrypoint";
import {IonFab, IonFabButton, IonIcon, IonModal} from "@ionic/react";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Capacitor, Plugins} from "@capacitor/core";
import {add} from "ionicons/icons";
import Card from "react-bootstrap/Card";
import Fade from "react-bootstrap/Fade";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#f39325'
        },
        secondary: {
            main: '#878787'
        }
    }
});

function Matches() {
    const localContext = useSelector(state => state.context.local);
    const searches = useSelector(state => state.searches);
    const storage = useSelector(state => state.context.storage);
    const dispatch = useDispatch();
    const weekdays = ['SO','MO','DI','MI','DO','FR','SA'];
    const today = new Date();
    const geolocation = useSelector(state => state.geolocation);
    const [referrer, setReferrer] = useState(null);
    const [show, setShow] = useState(false);
    const [showExtendedsearch, setShowExtendedsearch] = useState(false);
    const [showHurray, setShowHurray] = useState(false);
    const [expanded, setExpanded] = useState('panel0');
    const [personToConfirm, setPersonToConfirm] = useState(false);
    const [modal, setModal] = useState(null);
    const searchparams = useSelector(state => state.searchparams);
    const whatsappButton = <>
            <a className="btn btn-success rounded-pill mb-2" href="https://api.whatsapp.com/send?text=Schau%20mal%20auf%20Zammadoo,%20um%20Partner%20für%20Sport%20und%20Freizeit%20zu%20finden:%20https://www.zammadoo.com" target="_blank">Whats-App <WhatsAppIcon /></a>
        </>
    const sliderClasses = {
        valueLabel: 'MuiSlider-valueLabel--zammadoo',
        thumbColorSecondary: 'MuiSlider-thumbColorSecondary--zammadoo'
    };
    const activity = useSelector(state => state.activity);
    const { Geolocation } = Plugins;
    const [geoLocation, setGeoLocation] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [errorType, setErrorType] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const handleCloseAlert = () => setShowAlert(false);
    const [platformClass, setPlatformClass] = useState('');
    const [open, setOpen] = useState(false);
    const [cardData, setCardData] = useState({});
    let geoLocationWatchId;

    useEffect(() => {
        setPlatformClass(Capacitor.platform);
    }, []);

    useEffect(() => {
        geoLocationWatchId = Geolocation.watchPosition({timeout:1000, enableHighAccuracy: false}, (position, err) => {
            if (!err) {
                setGeoLocation(position);
                Geolocation.clearWatch({id: geoLocationWatchId});
            } else {
                if (!geoLocation || !geoLocation.coords) {
                    setGeoLocation(null);
//                    dispatch(alertActions.error(err.message));
                }
            }
        })
    }, []);

    useEffect(() => {
        if (geoLocation && geoLocation.coords && !geolocation.data && !geolocation.loading) {
            dispatch(geolocationActions.getLocationData(geoLocation.coords));
        }
    }, [geoLocation]);

    useEffect(() => {
        if (localContext && localContext.referrer) {
            setReferrer(localContext.referrer);
        }
        dispatch(userActions.getSearches());
        dispatch(userActions.setLocalContext({
            referrer: null,
            title: <h5 className="pt-3">Meine Suchen</h5>
        }));
    }, []);

    useEffect(() => {
        dispatch(userActions.getSearchparams());
        dispatch(activityActions.getActivities());
    }, []);

    useEffect(() => {
        if (referrer) {
            if ('superlike' === referrer.type) {
                let idx = 0;
                if (searches.matches) {
                    idx = searches.matches.length - 1;
                }
                setExpanded('panel' + idx);
            }
        }
    }, [searches]);

    useEffect(() => {
        let storageUpdated = {};
        if (storage) {
            storageUpdated = storage;
        }
        storageUpdated.matchesLed = false;
        dispatch(userActions.setStorage(storageUpdated));
    }, [storage]);

    function openProfile(id) {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: true,
            showProfileId: id,
            showSettings: false
        }
        dispatch(userActions.setLocalContext(localContextUpdated));

        setTimeout(() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }, 500);
    }

    function handleLike(person) {
        let o = {
            person: person.uid,
            ilike: !person.ilike,
            likesme: person.likesme,
        }
        setOpen(false);
        if (!o.ilike) {
            if (personToConfirm) {
                setPersonToConfirm(null);
                setShow(false);
            } else {
                setPersonToConfirm(person);
                setModal('unlike');
                setShow(true);
                return false;
            }
        }

        if (o.ilike && o.likesme) {
            if (personToConfirm) {
                setPersonToConfirm(null);
                setShow(false);
                handleConversation(person);
                return false;
            } else {
                setPersonToConfirm(person);
                setModal('hurray');
                setShowHurray(true);
            }
        }

        dispatch(userActions.pushSearch(o));
    }

    function handleConversation(person) {
        let o = {person: person.uid, conversation: person.conversation};

        dispatch(chatActions.openConversation(o));
    }

    function handleReferrer(person, searchType) {
        if (referrer && 'superlike' === referrer.type && 2 === searchType && person.uid === referrer.searchparams.referrer) {
            if (person.ilike && person.likesme) {
                setPersonToConfirm(person);
                setModal('hurray');
                setShowHurray(true);
            }
        }
    }

    function handleClose() {
        setPersonToConfirm(null);
        setShow(false);
    }

    const handleAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function handleChange(e) {
        const { name, value } = e.target;
        let params = searchparams.params;
        params[name] = value;
        dispatch(userActions.updateSearchparams(params));
    }

    function handleChangeAge(event, newValue) {
        let params = searchparams.params;
        params.fromage = newValue[0];
        params.toage = newValue[1];
        dispatch(userActions.updateSearchparams(params));
    }

    function handleChangeDistance(event, newValue) {
        let params = searchparams.params;
        params.distance = newValue;
        dispatch(userActions.updateSearchparams(params));
    }

    function handleChangeTime(event, newValue) {
        let params = searchparams.params;
        params.fromtime = newValue[0];
        params.totime = newValue[1];
        dispatch(userActions.updateSearchparams(params));
    }

    function handleSubmit(e) {
        e.preventDefault();

        let params = searchparams.params;
        if (0 == params.offsetdays) {
            if (new Date().setHours(params.totime,0,0,0) < new Date()) {
                setErrorType(1);
                setShowAlert(true);
                return false;
            }
        }
        params.latitude = params.lat;
        params.longitude = params.lon;
        dispatch(userActions.updateSearchparams(params));
        dispatch(userActions.pushSearch(params));

        setShowExtendedsearch(false);
    }

    function openProfile(e,id) {
        e.stopPropagation();
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: true,
            showProfileId: id,
            showSettings: false
        }
        dispatch(userActions.setLocalContext(localContextUpdated));

        setTimeout(() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }, 500);
    }

    function setOpenDatailcard(person) {
        console.log(person);
        setCardData(person);
        setOpen(true);
    }

    function openThat(person) {
        setPersonToConfirm(person);
        setShowHurray(true);

    }
    return (
        <>
            {searches.loading && <em>Loading matches...</em>}
            {searches.matches && 0 === searches.matches.length && <div className="text-center px-4 pb-4 pt-3"><p>Du hast noch keine Suche angelegt.</p> {whatsappButton}</div>}
            {searches.matches && searches.matches[0] && 0 === searches.matches[0].searchMatches.length
            && true === searches.matches[0].searchDetail.expired && <div className="text-center px-4 pb-4 pt-3"><p>Du hast noch keine Suche angelegt.</p> {whatsappButton}</div> }
            {searches.matches && searches.matches[0] &&
            <div className="matches-container">
                {searches.matches.map((match, index) =>
                    <Accordion key={index} expanded={expanded === 'panel'+index} onChange={handleAccordion('panel'+index)} className={match.searchDetail.expired ? 'd-none' : ''}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel'+index+'bh-content'} id={'panel'+index+'bh-header'} className={'text-white font-italic'+(0 === match.searchMatches.length ? ' bg-gray' : ' bg-primary')}>
                            {1 == match.searchDetail.type &&
                                <>
                                    <div>{match.searchDetail.activity},&nbsp;</div>
                                    <div><LocationName latitude={match.searchDetail.lat} longitude={match.searchDetail.lon} /></div>
                                    <div>{match.searchDetail.fromtime}&nbsp;<br/>{match.searchDetail.fromtimeHour} - {match.searchDetail.totimeHour}h</div>
                                    {/*<div className="ml-auto flex-wrap">Noch {match.searchDetail.remaining.days > 0 && match.searchDetail.remaining.days + 'd ' }
                                    {match.searchDetail.remaining.hours > 0 && match.searchDetail.remaining.hours + 'h'}</div>*/}
                                </>
                            }
                            {2 == match.searchDetail.type &&
                            <>
                                Deine Special Likes
                            </>
                            }
                        </AccordionSummary>
                        <AccordionDetails className="matchResultAccordion">
                            {0 === match.searchMatches.length && <div className="text-center px-4 pb-4"><p className="pt-4">Leider gibt es noch keine Treffer zu Deiner Suche.
                                <br/>
                                Teile Zammadoo doch mit Deinen Freunden und erweitere Deine Suche, um einen Partner zu finden.</p> {whatsappButton}</div>}
                            {match.searchMatches.map((person, index) =>
                                <div key={index} className="match-row row no-gutters align-items-center py-1 mb-2" style={{boxShadow:'0 4px 2px -2px rgba(0, 0, 0, 0.05)'}} onClick={() => setOpenDatailcard(person)} aria-controls="example-fade-text" aria-expanded={open}>
                                    <div className="col-3 p-1 text-center align-self-start"><a onClick={() => openProfile(person.profile.id)}>
                                        {person.profile.profileimage && <MediaObject alt={person.profile.firstname} mediaObjectUrl={person.profile.profileimage} className="rounded-zammadoo border border-primary" />}
                                        {!person.profile.profileimage && <img alt={person.profile.firstname} src="/profileImagePlaceholder.jpg" className="rounded-zammadoo border border-primary" />}
                                    </a></div>
                                    <div className="col">
                                        <div className="row no-gutters">
                                            <div className="col-8 text-uppercase text-dark h6">{person.profile.firstname}, {person.age}</div>
                                            <div className="col text-dark pr-2" style={{lineHeight:'1'}}>
                                            </div>
                                        </div>
                                        <div className="row no-gutters text-primary text-uppercase">
                                            {2 === match.searchDetail.type &&
                                            <div className="col text-center" style={2 === match.searchDetail.type ? {maxWidth:'20%'} : {}}>
                                                <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                                    <img className="zammadoo-icon" src={ENTRYPOINT+'/media/activities/'+person.activityIconfilename} alt="" />
                                                </div>
                                                <div style={{lineHeight:'1.2', height:'30px'}}>
                                                    <table className="h-100 w-100">
                                                        <tbody>
                                                        <tr>
                                                            <td className="align-middle"><span className="" style={{hyphens:'auto'}}>{person.activityName}</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            }
                                            <div className="col text-center" style={2 === match.searchDetail.type ? {maxWidth:'20%'} : {}}>
                                                <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                                    <img className="zammadoo-icon" src="/svg/Icon_Datum.svg" alt="" title="Wann?"/>
                                                </div>
                                                <div style={{lineHeight:'1.2', height:'30px'}}>
                                                    <table className="h-100 w-100">
                                                        <tbody>
                                                        <tr>
                                                            <td className="align-middle"><span className="text-nowrap ">
                                                        {0 === person.remaining.days && 'Heute'}
                                                                {1 === person.remaining.days && 'Morgen'}
                                                                {1 < person.remaining.days && weekdays[(today.getDay() + person.remaining.days) % 7]}
                                                        </span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col text-center" style={2 === match.searchDetail.type ? {maxWidth:'20%'} : {}}>
                                                <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                                    <img className="zammadoo-icon" src="/svg/Icon_Uhrzeit.svg" alt=""  />
                                                </div>
                                                <div style={{lineHeight:'1.2', height:'30px'}}>
                                                    <table className="h-100 w-100">
                                                        <tbody>
                                                        <tr>
                                                            <td className="align-middle"><span className="text-nowrap text-capitalize">{person.timeWindow.from}-{person.timeWindow.to} Uhr</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col text-center" style={2 === match.searchDetail.type ? {maxWidth:'20%'} : {}}>
                                                <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                                    <img className="zammadoo-icon" src="/svg/Icon_Entfernung.svg" alt="" title="Wann?"/>
                                                </div>
                                                <div style={{lineHeight:'1.2', height:'30px'}}>
                                                    <table className="h-100 w-100">
                                                        <tbody>
                                                        <tr>
                                                            <td><span className="text-nowrap">{person.distance} Km</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col text-center pl-1 position-relative" style={2 === match.searchDetail.type ? {maxWidth:'20%'} : {}}>
                                                <div className="row no-gutters position-absolute" onLoad={() => handleReferrer(person, match.searchDetail.type)} style={{left:'calc(100% - 1.5rem)'}}>
                                                    {person.ilike && person.likesme &&
                                                    <div className="col btn rounded-circle p-2 bg-primary border-primary" onClick={() => handleConversation(person)}>
                                                        <img className="zammadoo-icon" src="/svg/zammadoo__chat-white.svg" />
                                                    </div>
                                                    }
                                                    {!(person.ilike && person.likesme) &&
                                                    <div className="col btn rounded-circle p-2 bg-white border-secondary" onClick={() => handleLike(person)}>
                                                        {person.ilike && !person.likesme && <img className="zammadoo-icon" src="/svg/Herz_orange_Herz_Orange.svg"/>}
                                                        {!person.ilike && !person.likesme && <img className="zammadoo-icon" src="/svg/Herz_grau_Herz_grau.svg"/>}
                                                        {!person.ilike && person.likesme && <img className="zammadoo-icon" src="/svg/Herz_Like_Herz--Like.svg"/>}
                                                    </div>
                                                    }
                                                    {/*<button onClick={() => openThat(person)}>hurray</button>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {1 === match.searchDetail.type &&
                            <div className="extendSearch w-100 text-center">
                                <button className="btn btn-primary text-white rounded-pill w-50" onClick={() => setShowExtendedsearch(true)}>Suche erweitern</button>
                            </div>
                            }
                        </AccordionDetails>
                    </Accordion>
                )}
                <IonFab vertical="bottom" horizontal="center" slot="fixed" style={{position:'fixed'}}>
                    <IonFabButton color="dark" className="new-search" href="/searches" style={{"--ion-color-base":'var(--secondary)'}}>
                        <IonIcon icon={add} className="text-white" />
                    </IonFabButton>
                    <div className="separator" style={{height:'0.5rem', marginBottom:'5.5rem'}}></div>
                </IonFab>
            </div>
            }
            {false && searches.matches && searches.matches[0] && 0 === searches.matches[0].searchMatches.length
            && false === searches.matches[0].searchDetail.expired && <div className="text-center px-4 pb-4"><p className="pt-4">Leider gibt es noch keine Treffer zu Deiner Suche.
                <br/>
                Teile Zammadoo doch mit Deinen Freunden und erweitere Deine Suche, um einen Partner zu finden.</p> {whatsappButton}</div>}
            <Fade in={open}>
                <div id="fade-card" className="fixed-top match-card w-100">
                    {cardData &&
                    <Card className="border-secondary bg-secondary" style={{borderWidth:'5px',borderRadius:'0.75rem', height:'75vh'}}>
                        <Card.Body className="bg-white pt-3" style={{borderRadius:'0.75rem', overflow:'scroll'}}>
                            <MatchDetails data={cardData} />
                        </Card.Body>
                        <Card.Footer className="text-center bg-secondary py-1">
                            {cardData.ilike && cardData.likesme &&
                            <button type="button" className="btn zammadoo-feeds-button rounded-circle bg-secondary p-2 border border-white" onClick={() => handleConversation(cardData)}>
                                <img className="zammadoo-icon" src="/svg/zammadoo__chat-white.svg" />
                            </button>
                            }
                            {!(cardData.ilike && cardData.likesme) &&
                            <button type="button" className="btn zammadoo-feeds-button rounded-circle bg-secondary p-2 border border-white" onClick={() => handleLike(cardData)}>
                                {cardData.ilike && !cardData.likesme && <img className="zammadoo-icon" src="/svg/Herz_orange_Herz_Orange.svg"/>}
                                {!cardData.ilike && !cardData.likesme && <img className="zammadoo-icon" src="/svg/Herz_weiss.svg"/>}
                                {!cardData.ilike && cardData.likesme && <img className="zammadoo-icon" src="/svg/Herz-Like_weiss.svg"/>}
                            </button>
                            }<span className="px-3">&nbsp;</span>
                            <button type="button" className="btn zammadoo-feeds-button rounded-circle bg-secondary p-2 border border-white" onClick={() => setOpen(!open)}>
                                <span aria-hidden="true" className="text-white zammadoo-icon" style={{fontSize:'1.5rem',lineHeight:'1.7rem', display:'block'}}>×</span><span className="sr-only">Close</span>
                            </button>
                        </Card.Footer>
                    </Card>
                    }
                </div>
            </Fade>
            <div className="separator" style={{marginBottom:'4.5rem'}}></div>
            {personToConfirm &&
            <Modal show={show} onHide={handleClose} className="zammadoo-modal-fromtop">
                <Modal.Header className="text-center" closeButton>
                    {'unlike' === modal && <Modal.Title className="w-100">Like aufheben</Modal.Title>}
                    {'hurray' === modal && <Modal.Title className="w-100">Hurray!</Modal.Title>}
                </Modal.Header>
                {'unlike' === modal &&
                <Modal.Body>
                    Möchtest Du Dein Like wirklich zurücknehmen?<br/>
                </Modal.Body>
                }
                {'hurray' === modal &&
                <Modal.Body className="text-center">
                    {personToConfirm.profile.profileimage && <p><MediaObject alt={personToConfirm.profile.firstname} mediaObjectUrl={personToConfirm.profile.profileimage} className="rounded-zammadoo border border-primary" /></p>}
                    <p>{personToConfirm.profile.firstname} hat Deine Suche ebenfalls favorisiert und möchte sich gerne mit Dir verabreden!
                        Sende {1===personToConfirm.profile.gender && 'ihm'}{2===personToConfirm.profile.gender && 'ihr'} am besten gleich eine Nachricht!</p>
                </Modal.Body>
                }
                {'unlike' === modal &&
                <Modal.Footer className="text-center justify-content-center">
                    <button className="btn btn-primary text-white rounded-pill" onClick={() => handleLike(personToConfirm)}>Zurücknehmen</button>
                </Modal.Footer>
                }
                {'hurray' === modal &&
                <Modal.Footer className="text-center justify-content-center">
                    <button className="btn btn-primary text-white rounded-pill" onClick={() => handleConversation(personToConfirm)}>Jetzt Nachricht senden</button>
                </Modal.Footer>
                }
            </Modal>
            }
            {personToConfirm &&
            <IonModal isOpen={showHurray} cssClass={'zammadoo-modal-frombottom zammadoo-bg-primary zammadoo-'+platformClass}>
                <div className="modal-header border-bottom-0 pb-0">
                    <button type="button" className="close" onClick={() => setShowHurray(false)}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <Modal.Body className="hurray pt-1">
                    <div className="settings d-flex flex-column justify-content-between" style={{minHeight:'87vh'}}>
                        <div className="text-center">
                            <div className="graphic here" style={{padding:'0 25% 2rem'}}>
                                <div><img src="/svg/and-action_hurray.svg" /></div>
                            </div>
                            {personToConfirm.profile.profileimage && <p><MediaObject alt={personToConfirm.profile.firstname} mediaObjectUrl={personToConfirm.profile.profileimage} className="rounded-zammadoo border border-primary" /></p>}
                            <p><strong className="h5">Auf gehts!</strong></p>
                            <p><strong className="h5">{personToConfirm.profile.firstname}</strong> <br/>möchte sich auch <br/>mit Dir verabreden!</p>
                            <p>Sende {1===personToConfirm.profile.gender && 'ihm'}{2===personToConfirm.profile.gender && 'ihr'} am besten gleich eine Nachricht.</p>
                        </div>
                        <div className="align-self-end text-center w-100 pb-4">
                            <button className="btn bg-white rounded-pill w-50" onClick={() => handleConversation(personToConfirm)}>Nachricht schreiben</button>
                        </div>
                    </div>
                </Modal.Body>
            </IonModal>
            }
            {searchparams.params &&
            <IonModal isOpen={showExtendedsearch} cssClass={'zammadoo-modal-frombottom zammadoo-'+platformClass}>
                <div className="modal-header border-bottom-0">
                    <div className="w-100 text-center modal-title h6 text-uppercase position-absolute" style={{left:'0'}}>Suche erweitern</div>
                    <button type="button" className="close" onClick={() => setShowExtendedsearch(false)} style={{zIndex:'1'}}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <Modal.Body className="activity-accordion">
                    <MuiThemeProvider theme={theme}>
                        {searchparams.loading && <em>Loading params...</em>}
                        {searchparams.params &&
                        <div className="col pt-3" style={{height:'100vh',overflow:'scroll'}}>
                            <div className="row pb-4">
                                <div className="col-2">
                                    <img src="/svg/Icon_Geschlecht.svg" alt="" width="40" height="40" title="Wem?"/>
                                </div>
                                <div className="col-10">
                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label className="btn text-uppercase btn-radiobtn--zammadoo active text-primary">
                                            {searchparams.params.gender == 1 && <span>Mann</span>}
                                            {searchparams.params.gender == 2 && <span>Frau</span>}
                                            {searchparams.params.gender == 3 && <span>egal</span>}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Alter.svg" alt="" width="40" height="40" title="Wie alt?"/>
                                </div>
                                <div className="col pt-2">
                                    <Slider
                                        value={[searchparams.params.fromage, searchparams.params.toage]}
                                        onChange={handleChangeAge}
                                        min={18}
                                        max={99}
                                        aria-labelledby="age-slider"
                                        color="secondary"
                                        classes={sliderClasses}
                                        valueLabelDisplay="on"
                                    />
                                </div>
                                <div className="col-2 pt-2">
                                    <Typography id="age-slider" className="text-darkgray" gutterBottom>
                                        <small>JAHRE</small>
                                    </Typography>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Ort.svg" alt="" width="40" height="40" title="Wo?"/>
                                </div>
                                <div className={"col pt-2"}>
                                    <Typography className="text-uppercase" gutterBottom>
                                        <LocationName latitude={searchparams.params.lat} longitude={searchparams.params.lon} />
                                    </Typography>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Entfernung.svg" alt="" width="40" height="40" title="Wie weit?"/>
                                </div>
                                <div className={"col pt-2"}>
                                    <Slider
                                        value={searchparams.params.distance}
                                        onChange={handleChangeDistance}
                                        aria-labelledby="distance-slider"
                                        valueLabelDisplay="on"
                                        color="secondary"
                                        classes={sliderClasses}
                                        min={1}
                                        max={100}
                                    />
                                </div>
                                <div className={"col-2 pt-2"}>
                                    <Typography id="distance-slider" className="text-uppercase text-darkgray" gutterBottom>
                                        <small>KM</small>
                                    </Typography>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Datum.svg" alt="" width="40" height="40" title="Wann?"/>
                                </div>
                                <div className={"col"}>
                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label className="btn text-uppercase btn-radiobtn--zammadoo active text-primary">
                                            {searchparams.params.offsetdays == 0 && <span>Heute</span>}
                                            {searchparams.params.offsetdays == 1 && <span>Morgen</span>}
                                            {searchparams.params.offsetdays == 2 && <span>{weekdays[(today.getDay() + 2) % 7]}</span>}
                                            {searchparams.params.offsetdays == 3 && <span>{weekdays[(today.getDay() + 3) % 7]}</span>}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Uhrzeit.svg" alt="" width="40" height="40" title="Wieviel Uhr?"/>
                                </div>
                                <div className={"col pt-2"}>
                                    <Slider
                                        value={[searchparams.params.fromtime, searchparams.params.totime]}
                                        onChange={handleChangeTime}
                                        aria-labelledby="time-slider"
                                        valueLabelDisplay="on"
                                        color="secondary"
                                        classes={sliderClasses}
                                        min={0}
                                        max={24}
                                        step={1}
                                    />
                                </div>
                                <div className={"col-2 pt-2"}>
                                    <Typography id="time-slider" className="text-uppercase text-darkgray" gutterBottom>
                                        <small>Uhr</small>
                                    </Typography>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon-description.svg" alt="" width="40" height="40" title="Comments to your search"/>
                                </div>
                                <div className={"col pt-2"}>
                                    <textarea name="description" className="form-control" placeholder="Leave a comment here" onChange={handleChange} value={searchparams.params.description}  maxLength="140">
                                    </textarea>
                                </div>
                            </div>

                            {activity.items && geoLocation &&
                            <div className="text-center mb-5">
                                <button type="button" className="btn btn-primary rounded-pill text-white w-50" onClick={handleSubmit}>
                                    {submitted && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Übernehmen
                                </button>
                            </div>
                            }

                            <div className="separator" style={{height:'4.5rem'}}></div>
                        </div>
                        }
                        <Modal show={showAlert} onHide={handleCloseAlert} className="zammadoo-modal-fromtop">
                            <Modal.Header closeButton>
                                <Modal.Title className="w-100 text-center">Bitte anpassen</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                                {1===errorType && <>Falscher Zeitraum: Deine Suche liegt in der Vergangenheit.<br/></>}
                                {2===errorType && <>Keine Aktivität: Bitte eine Aktivität auswählen.<br/></>}
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                                <button className="btn btn-secondary rounded-pill" onClick={handleCloseAlert}>
                                    Suche anpassen
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </MuiThemeProvider>
                </Modal.Body>
            </IonModal>
            }
        </>
    );
}

export { Matches };
