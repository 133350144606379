import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
/*Imports for onboarding dialog*/
import Carousel from 'react-bootstrap/Carousel'
import md5 from 'md5';
import imageCompression from "browser-image-compression";
import {MediaObject} from "../_components";
import dialingcodes from "../data/dialingcode.json";
import { Capacitor } from '@capacitor/core';

registerLocale("de", de);

function RegisterPage() {
    let maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    const [user, setUser] = useState({
        gender: '',
        firstname: '',
        lastname: '',
        dialingcode: '49',
        mobilephone: '',
        birthday: maxDate.toISOString(),
        email: '',
        password: '',
        confirmPassword: '',
        cb: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const registering = useSelector(state => state.registration.registering);
    const registered = useSelector(state => state.registration.registered);
    const registerError = useSelector(state => state.registration.error);
    const dispatch = useDispatch();
/*START Extended for the ONBOARDING-DIALOG */
    let minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 100);
    const [index, setIndex] = useState(0);
    const [birthdayInput, setBirthdayInput] = useState(
        {
            bdyear: '',
            bdmonth: '',
            bdday: '',
            bdfull: '',
            bdminor: false
        }
    );
    const [invalidEmail, setInvalidEmail] = useState(false);
    const confirmed = useSelector(state => state.authentication.smsconfirmed);
    const loginUser = useSelector(state => state.authentication.user);
    const loginError = useSelector(state => state.authentication.error);
    const profileData = useSelector(state => state.profile.profileData);
    const [smsFieldDisabled, setSmsFieldDisabled] = useState(false);
    const [loginClicked, setLoginClicked] = useState(false);
    const [handleLogin, setHandleLogin] = useState(false);
    const [countryIso, setCountryIso] = useState('de');
    const [datefocus, setDatefocus] = useState(false);
    const [platformName, setPlatformName] = useState('');

    const handleOnboardingStep = (selectedIndex) => {console.log('sele', selectedIndex);
        let passedIndex = selectedIndex;
        setSubmitted(false);
        if (4 < selectedIndex) {
            if (user.email && ! user.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g)) {
                setSubmitted(true);
                setInvalidEmail(true);
            } else {
                setInvalidEmail(false);
                if (5 === selectedIndex) {
                    let updateProfileData = loginUser.profile;
                    updateProfileData.email = user.email;
                    dispatch(userActions.updateProfile(updateProfileData));
                }
                if (profileData && profileData.profileimage) {
                    setIndex(passedIndex);
                } else {
                    setSubmitted(true);
                }
            }
            setSmsFieldDisabled(false);
            return;
        }
        if (3 < selectedIndex) {
            if (! user.smscode ) {
                setSubmitted(true);
            } else {
                setLoginClicked(true);
                dispatch(userActions.login2(md5('+' + user.dialingcode + user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '')) + '@zammadoo.com','hereTheDeviceToken'));
                setIndex(passedIndex);
            }
            return;
        }
        if (2 < selectedIndex) {
            if (! user.mobilephone || !user.cb) {
                setSubmitted(true);
                passedIndex = 2;
            } else {
                handleRegister();
            }
        }
        if (1 < selectedIndex) {
            if (! user.firstname ) {
                setSubmitted(true);
                passedIndex = 1;
            }
            if (! birthdayInput.bdfull ) {
                setSubmitted(true);
                passedIndex = 1;
            }
        }
        setIndex(passedIndex);console.log(user);
    };

    function loginWithSms(step) {
        if (7 === step) {
            if (! user.mobilephone) {
                setSubmitted(true);
                return;
            } else {
                let params = {};
                params.mobilephone = '+' + user.dialingcode + user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '');
                params.username = md5(params.mobilephone) + '@zammadoo.com';
                setSmsFieldDisabled(false);
                dispatch(userActions.resendConfirmationSMS(params));
            }
        }
        setSubmitted(false);
        setIndex(step);
    };

    function notyet() {
        setIndex(5);
    };

    function handleUpload(e) {
        const files = e.target.files;
        const imageForm = new FormData();
        let file;
        for(var x = 0; x<files.length; x++) {
            file = files[x];
            if (file.size > 1024) {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 640,
                    useWebWorker: true
                };
                imageCompression(file, options).then(x => {
                    file = x;
                    imageForm.append('file', file, file.name);
                    dispatch(userActions.postImage(imageForm));
                });
            } else {
                imageForm.append('file', file, file.name);
                dispatch(userActions.postImage(imageForm));
            }
        }
    }

    function handleChangeBirthdayInput(e) {console.log(e.key);
        const { maxLength, tabIndex, name, value } = e.target;
        let value2 = value + e.key;
        let parsedValue = value2.replace(/[^0123456789]/i, '');
        let bdfull = '';
        let bdminor = false;
        if (3 > tabIndex) {
            if (parsedValue.length >= maxLength) {
                let nextSibling;
                parsedValue = parsedValue.substring(0, maxLength);
                if (1 == tabIndex) {
                    nextSibling = document.querySelector(
                        `input[name=bdmonth]`
                    );
                }
                if (2 == tabIndex) {
                    nextSibling = document.querySelector(
                        `input[name=bdyear]`
                    );
                }
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        } else {
            parsedValue = parsedValue.substr(-1 * maxLength);
        }
        //validate Date
        let validateBirthdayInput = birthdayInput;
        validateBirthdayInput[name] = parsedValue;
        if (parseInt(validateBirthdayInput.bdyear) && parseInt(validateBirthdayInput.bdmonth) && parseInt(validateBirthdayInput.bdday)) {
            let dateToValidate = new Date(validateBirthdayInput.bdyear + '-' + validateBirthdayInput.bdmonth + '-' + validateBirthdayInput.bdday);
            if (isFinite(dateToValidate) && maxDate > dateToValidate && minDate < dateToValidate) {
                bdfull = dateToValidate.toISOString();
            }
            bdminor = (isFinite(dateToValidate) && !(maxDate > dateToValidate));
        }
        setBirthdayInput(birthdayInput => ({ ...birthdayInput, [name]: parsedValue, bdfull: bdfull, bdminor: bdminor }));
    }

    function resendSMS(e) {
        let params = {};
        params.mobilephone = '+' + user.dialingcode + user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '');
        params.username = md5(params.mobilephone) + '@zammadoo.com';
        setSmsFieldDisabled(false);
        dispatch(userActions.resendConfirmationSMS(params));
    }

    function handlenewbd(e) {
        console.log('value accepted', e.target.value);
    };

    function donothing(e) {
    };

    function handleRegister() {
        let regiuser = {};
        if (user.gender) {
            regiuser.gender = user.gender;
        }
        if (user.firstname) {
            regiuser.firstname = user.firstname;
            regiuser.lastname = '';
        }
        if (birthdayInput.bdfull) {
            regiuser.birthday = birthdayInput.bdfull;
        }
        if (user.mobilephone) {
            regiuser.mobilephone = '+' + user.dialingcode + user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '');
            regiuser.email = md5(regiuser.mobilephone) + '@zammadoo.com';
        }
        regiuser.password ='hereTheDeviceToken';
        if (regiuser.gender && regiuser.firstname && regiuser.birthday && regiuser.mobilephone && regiuser.email && regiuser.password) {
            dispatch(userActions.register(regiuser));
        }
    }

    function handleChangeSmsCode(e) {
        let smsdata = {};
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
        if (5 <= value.toString().length) {
            setSmsFieldDisabled(true);
            smsdata.smsCode = value;
            smsdata.email = md5('+' + user.dialingcode + user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '')) + '@zammadoo.com';
            dispatch(userActions.confirmSms(smsdata));
        }
    }

    useEffect(() => {
        if (loginClicked && loginUser && 3 === index) {
            setLoginClicked(false);
            setIndex(4);
            dispatch(userActions.getProfile());
        }
        if (loginUser && 7 === index) {
            setHandleLogin(true);
        }
    }, [loginUser]);

    useEffect(() => {
        if (confirmed && 7 === index) {
            dispatch(userActions.login2(md5('+' + user.dialingcode + user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '')) + '@zammadoo.com','hereTheDeviceToken'));
        }
    }, [confirmed]);

    useEffect(() => {
        if (3 === index) {
            setSmsFieldDisabled(false);
        }
    }, [loginError]);

    useEffect(() => {
        setPlatformName(Capacitor.platform);
    }, []);

    function handleChangeBirthday(e) {
        const { name, value } = e.target;
        let bdfull = null;
        let bdminor = false;
        let dateToValidate = new Date(value);
        if (isFinite(dateToValidate) && maxDate > dateToValidate && minDate < dateToValidate) {
            bdfull = dateToValidate.toISOString();
        }
        bdminor = (isFinite(dateToValidate) && !(maxDate > dateToValidate));
        setBirthdayInput(birthdayInput => ({...birthdayInput, bdfull: bdfull, bdminor: bdminor}));
        setUser(user => ({ ...user, [name]: value }));
    }
/*END Extended for the ONBOARDING-DIALOG */


    // reset login status
    useEffect(() => {
        dispatch(userActions.logout());
    }, []);


    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
    }

    function handleChangeCb(e) {
        const { name, checked } = e.target;
        setUser(user => ({ ...user, [name]: checked }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (user.gender && user.firstname && user.lastname && user.birthday && user.email && user.password && user.confirmPassword && user.password === user.confirmPassword) {
            dispatch(userActions.register(user));
        }
    }

    function handleDialingcode(e) {
        const { name } = e.target;
        const { id, value } = e.target.options[e.target.selectedIndex];
        setCountryIso(id.toString().toLowerCase());
        setUser(user => ({ ...user, [name]: value }));console.log(name, user);
    }

    return(
            <Carousel className="onboarding" activeIndex={index} interval={null} indicators={false} controls={false} touch={false} keyboard={false} style={{zIndex:'1069'}}>
                <Carousel.Item>
                    <div className="bg-primary px-2 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
                        <div className="divide-from-footer">
                            <div className="first-block text-center px-3 px-sm-5">
                                <img src="/svg/Zammadoo_Logo_weiss.svg" className="w-75 pb-2 pt-2 pt-sm-4" />
                                <h5 className="text-white font-italic">Finde Deinen<br/> Partner für<br/> Sport & Freizeit!</h5>
                            </div>
                            <div className="position-relative">
                                <div className="position-absolute" style={{left:'-0.5rem'}}>
                                    <img src="/Zammadoo_onboardingstart.png" className="w-100" />
                                </div>
                            </div>
                        </div>
                        <div className="text-center w-100 align-self-end pb-2" style={{zIndex:1}}>
                            <div className="onboarding-bottom w-100">
                                <button className="btn bg-white rounded-pill w-50" onClick={() => handleOnboardingStep(1)}>Neu registrieren</button><br/><br/>
                                <a onClick={() => loginWithSms(6)} className="text-white">Ich habe schon einen Account</a>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="bg-primary px-2 pt-4 pt-sm-5 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
                        <div className="divide-from-footer">
                            <div className="first-block text-center px-3 px-sm-5 pt-4">
                                <img src="/svg/Icon_Person.svg" className="w-25 w-md-50 py-2" />
                                <p className="text-white">Hallo bei Zammadoo! Sag den anderen Usern, wer Du bist. So ist es für Dich und andere einfacher, schnell den richtigen Partner zu finden.</p>
                                <h6 className="text-white text-left ml-auto">Geschlecht</h6>
                                <div className="form-group text-left" role="group" aria-labelledby="my-radio-group">
                                    <div className={'btn-group btn-group-toggle' + (submitted && !user.gender ? ' is-invalid' : '')} data-toggle="buttons">
                                        <label className={'btn btn-light' + (user.gender === '1' ? ' active text-white' : '')} htmlFor="genderBoy">
                                            <input type="radio" name="gender" id="genderBoy" autoComplete="off" value="1" onChange={handleChange} className={'form-check-input' + (submitted && !user.gender ? ' is-invalid' : '')} /> Herr
                                        </label>
                                        <label className={'btn btn-light' + (user.gender === '2' ? ' active text-white' : '')} htmlFor="genderGirl">
                                            <input type="radio" name="gender" id="genderGirl" autoComplete="off" value="2" onChange={handleChange} className={'form-check-input' + (submitted && !user.gender ? ' is-invalid' : '')} /> Frau
                                        </label>
                                    </div>
                                    {submitted && !user.gender &&
                                    <div className="invalid-feedback">Geschlecht is pflicht</div>
                                    }
                                </div>
                                <h6 className="text-white text-left ml-auto">Wie heißt Du?</h6>
                                <div className="form-group text-center">
                                    <input type="text" placeholder="Vorname" name="firstname" value={user.firstname} onChange={handleChange} className={'form-control' + (submitted && !user.firstname ? ' is-invalid' : '')} />
                                    {submitted && !user.firstname &&
                                    <div className="invalid-feedback">Vorname ist pflicht</div>
                                    }
                                </div>
                                <h6 className="text-white text-left ml-auto">Wann bist Du geboren?</h6>
                                {'android' != platformName &&
                                <div className="form-group">
                                    <div className="row no-gutters">
                                        <div className="col pr-1">
                                            <input type="number" maxLength="2" tabIndex="1" placeholder="Tag" name="bdday" value={birthdayInput.bdday} onKeyUp={handleChangeBirthdayInput} onChange={donothing} className={'form-control' + (submitted && !birthdayInput.bdfull ? ' is-invalid' : '')} />
                                        </div>
                                        <div className="col px-2">
                                            <input type="number" maxLength="2" tabIndex="2" placeholder="Monat" name="bdmonth" value={birthdayInput.bdmonth} onKeyUp={handleChangeBirthdayInput} onChange={donothing} className={'form-control' + (submitted && !birthdayInput.bdfull ? ' is-invalid' : '')} />
                                        </div>
                                        <div className="col pl-1">
                                            <input type="number" maxLength="4" tabIndex="3" placeholder="Jahr" name="bdyear" value={birthdayInput.bdyear} onKeyUp={handleChangeBirthdayInput} onChange={donothing} className={'form-control' + (submitted && !birthdayInput.bdfull ? ' is-invalid' : '')} />
                                        </div>
                                    </div>
                                    {submitted && !birthdayInput.bdfull && !birthdayInput.bdminor &&
                                    <div className="invalid-feedback">Geburtstag ist pflicht</div>
                                    }
                                    {submitted && birthdayInput.bdminor &&
                                    <div className="invalid-feedback">Nur volljärige erlaubt</div>
                                    }
                                </div>
                                }
                                {'android' == platformName &&
                                <div className="form-group">
                                    <input type="date" name="birthday" value={user.birthday} onChange={handleChangeBirthday} className={'form-control' + (submitted && !birthdayInput.bdfull ? ' is-invalid' : '')} />
                                    {submitted && !birthdayInput.bdfull && !birthdayInput.bdminor &&
                                    <div className="invalid-feedback">Geburtstag ist pflicht</div>
                                    }
                                    {submitted && birthdayInput.bdminor &&
                                    <div className="invalid-feedback">Nur volljärige erlaubt</div>
                                    }
                                </div>
                                }
                                <div className="text-white">
                                    <p className="small">Bitte achte auf die Richtigkeit Deiner Angaben, diese können im Nachhinein nicht mehr verändert werden.</p>
                                </div>
                            </div>
                        </div>
                        <div className="text-center w-100 align-self-end pb-2 d-flex justify-content-center">
                            <div className="onboarding-bottom w-75">1
                                <div className="bg-dark w-100 mb-3"><div className="bg-white pt-1" style={{width:'20%'}}></div></div>
                                <button className="btn bg-white rounded-pill w-100" onClick={() => handleOnboardingStep(2)}>Weiter</button><br/><br/>
                                <a onClick={() => handleOnboardingStep(0)} className="text-white">Zurück</a>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="bg-primary px-2 pt-4 pt-sm-5 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
                        <div className="divide-from-footer">
                            <div className="first-block text-center px-3 px-sm-5 pt-4">
                                <img src="/svg/Icon_Schloss_white.svg" className="w-25 w-md-50 py-2" />
                                <p className="text-white">Um die Sicherheit für Dich und andere User zu erhöhen und die Echtheit der Profile auf Zammadoo zu gewährleisten, werden alle Accounts verifiziert. Im nächsten Schritt erhälst Du hierzu einen Bestätigungscode auf Dein Mobiltelefon. Im Falle einer Sperrung kannst Du Deinen Account so auch reaktivieren.</p>
                                <h6 className="text-white text-left ml-auto">Deine Mobilfunknummer</h6>
                                <div className="form-group text-center">
                                    <div className="row no-gutters">
                                        <div className="col-2 pb-1 pr-2">
                                            <img src={'/flags/4x3/'+countryIso+'.svg'} className="w-100"/>
                                        </div>
                                        <div className="col-3 col-sm-2">
                                            <select className="form-select w-100 dialing" name="dialingcode" aria-label="Default select example" onChange={handleDialingcode}>
                                            {dialingcodes && dialingcodes.map((dialingcode, index) =>
                                                <option key={index} value={dialingcode.Dial} style={{display:dialingcode.Dial?'':'none'}} selected={'49'==dialingcode.Dial} id={dialingcode.ISO3166_1_Alpha_2}>
                                                    {dialingcode.ISO3166_1_Alpha_2} &nbsp;&nbsp;&nbsp;&nbsp;{dialingcode.ISO4217_Currency_Country_Name ? dialingcode.ISO4217_Currency_Country_Name : dialingcode.Country_Name}
                                                </option>
                                            )}
                                            </select>
                                        </div>
                                        <div className="col pl-1"><input type="number" placeholder="012345" name="mobilephone" value={user.mobilephone} onChange={handleChange} className={'form-control' + (submitted && !user.mobilephone ? ' is-invalid' : '')} /></div>
                                    </div>
                                    {submitted && !user.mobilephone &&
                                    <div className="invalid-feedback">Mobilfunknummer ist pflicht</div>
                                    }
                                </div>
                                <div className="form-group">
                                    <div className={'text-white custom-control' + (submitted && !user.cb ? ' is-invalid' : '')}>
                                        <input type="checkbox" className="custom-control-input" name="cb" id="termsnconds" checked={user.cb} value="1" onClick={handleChangeCb} onChange={donothing} />
                                        <label className="custom-control-label text-left" htmlFor="termsnconds">Ich stimme den <a
                                            className="text-light" target="_blank" href="https://www.zammadoo.com/nutzungsbedingungen-app.html">Nutzungsbedingungen</a> und der <a
                                            className="text-light" target="_blank" href="https://www.zammadoo.com/datenschutz-app.html">Datenschutzerklärung</a> zu.</label>
                                    </div>
                                    {submitted && !user.cb &&
                                    <div className="invalid-feedback">Nutzungsbedingungen bestätigen ist pflicht</div>
                                    }
                                </div>
                                <div className="text-white">
                                    <p className="small">Deine Telefonnummer verwenden wir ausschließlich zur Sicherung Deines Accounts. Sie wird ebenso wie alle Deine persönlichen Daten verschlüsselt und für Dritte unzugänglich abgespeichert.</p>
                                </div>
                            </div>
                        </div>
                        <div className="text-center w-100 align-self-end pb-2 d-flex justify-content-center">
                            <div className="onboarding-bottom w-75">
                                <div className="bg-dark w-100 mb-3"><div className="bg-white pt-1" style={{width:'40%'}}></div></div>
                                <button className="btn bg-white rounded-pill w-100" onClick={() => handleOnboardingStep(3)}>Weiter</button><br/><br/>
                                {!registered && <a onClick={() => setIndex(1)} className="text-white">Zurück</a>}
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="bg-primary px-2 pt-4 pt-sm-5 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
                        <div className="divide-from-footer">
                            <div className="first-block text-center px-3 px-sm-5 pt-4">
                                <img src="/svg/Icon_Schloss_white.svg" className="w-25 w-md-50 py-2" />
                                {!registerError && <p className="text-white">Wir haben einen Bestätigungscode an {user.mobilephone} geschickt. Bitte gib ihn hier ein:</p>}
                                {registerError && !(-1 < registerError.toString().indexOf('Unique violation: 7')) &&
                                <>
                                <div className="text-danger">Es ist ein Fehler mit Registrierung. Versuch später nochmal.</div>
                                <a onClick={() => handleOnboardingStep(2)} className="text-white">Zurück</a>
                                </>
                                }
                                {registerError && (-1 < registerError.toString().indexOf('Unique violation: 7')) &&
                                <>
                                <div className="text-danger">Diese Mobilfunknummer: +49 {user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '')} ist bereit registriert.</div>
                                <a onClick={() => handleOnboardingStep(2)} className="text-white">Mobilfunknummer apnassen</a>
                                </>
                                }
                                <h5 className="text-white text-left ml-auto">Bestätigungscode</h5>
                                <div className="form-group text-center">
                                    <input type="text" placeholder="" name="smscode" value={user.smscode} onChange={handleChangeSmsCode} className={'form-control w-50' + (submitted && !user.smscode ? ' is-invalid' : '')} disabled={smsFieldDisabled} />
                                    {submitted && !user.smscode &&
                                    <div className="invalid-feedback">SMS-Code ist pflicht</div>
                                    }
                                </div>
                                <div className="text-white text-left">
                                    <p>Du hast den Code nicht erhalten?<br/>
                                    <a onClick={resendSMS} className="text-secondary">Erneut senden</a>
                                    </p>
                                </div>
                                {confirmed &&
                                <div className="accepted">
                                    <img src="/svg/sms-confirmation-check.svg" className="w-25 w-md-50 pb-2" />
                                </div>
                                }
                                {'Code is not valid' === loginError &&
                                <div className="rejected">
                                    <p className="text-danger">Sms Code ist nicht gültig</p>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="text-center w-100 align-self-end pb-2 d-flex justify-content-center">
                            <div className="onboarding-bottom w-75">
                                <div className="bg-dark w-100 mb-3"><div className="bg-white pt-1" style={{width:'60%'}}></div></div>
                                {confirmed && <button className="btn bg-white rounded-pill w-100" onClick={() => handleOnboardingStep(4)}>Weiter</button>}<br/><br/>
                                {loginError && <a onClick={() => handleOnboardingStep(2)} className="text-white">Zurück</a>}
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="bg-primary px-2 pt-4 pt-sm-5 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
                        <div className="divide-from-footer">
                            <div className="first-block text-center px-3 px-sm-5 pt-4">
                                {profileData && profileData.profileimage && <><MediaObject mediaObjectUrl={profileData.profileimage} className="rounded-zammadoo profile border border-secondary" /></>}
                                {(!profileData || !profileData.profileimage) && <img src="/svg/Icon_Profilbild_white.svg" className="w-25 w-md-50 pb-2" />}
                                <p className="text-white"><strong>Schenke den anderen Usern ein Lächeln und lade ein Profilbild von Dir hoch.</strong></p>
                                <div className="position-relative">
                                    <img className="rounded-zammadoo profile border border-white bg-white p-2" src="/svg/addpic.svg" />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="upload"
                                        onChange={handleUpload}
                                    />
                                    {submitted && (!profileData || !profileData.profileimage) &&
                                    <>
                                        <div className="is-invalid"></div>
                                        <div className="invalid-feedback">Bitte lade ein Profilbild von Dir hoch. Das erleichtert die Nutzung von Zammadoo für alle User.</div>
                                    </>
                                    }
                                </div>
                                <div className="first-block text-center pt-4">
                                    <p className="text-white">Wenn Du uns Deine Email-Adresse gibst, informieren wir Dich so über Ereignisse in Deinem Account.</p>
                                    <div className="form-group">
                                        <input type="email" placeholder="@" name="email" value={user.email} onChange={handleChange} className={'form-control' + (submitted && invalidEmail ? ' is-invalid' : '')} />
                                        {submitted && invalidEmail &&
                                        <div className="invalid-feedback">Valide E-Mail ist pflicht</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center w-100 align-self-end pb-2 d-flex justify-content-center">
                            <div className="onboarding-bottom w-75">
                                <div className="bg-dark w-100 mb-3"><div className="bg-white pt-1" style={{width:'80%'}}></div></div>
                                <button className="btn bg-white rounded-pill w-100" onClick={() => handleOnboardingStep(5)}>Weiter</button><br/><br/>
                                {/*<a onClick={() => notyet()} className="text-white">Überspringen</a>*/}
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="bg-primary px-2 pt-4 pt-sm-5 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
                        <div className="divide-from-footer">
                            <div className="first-block text-center px-3 px-sm-5">
                                <p className="text-white"><strong>Fast geschafft!</strong></p>
                                <p className="text-white">Zammadoo verbindet Menschen, die spontan nach Sport- und Freizeitpartnern in ihrer Nähe suchen. So funktionierts:</p>
                                <div className="first-block text-center">
                                    <img className="pb-2" src="/svg/Z-Feed_weiss.svg" style={{width:'2rem'}} />
                                    <p className="text-white">Auf der <Link to="/" className="text-secondary">Startseite</Link> siehst Du alle für Dich relevanten Suchen in der Übersicht und kannst andere User direkt liken</p>
                                </div>
                                <div className="first-block text-center">
                                    <img className="pb-2" src="/svg/Lupe_white.svg" style={{width:'2rem'}} />
                                    <p className="text-white">Über die <Link to="/" className="text-secondary">Suche</Link> kannst Du selber Partner für eine Aktivität Deiner Wahl finden und liken. Bei einem gegenseitigen Like habt ihr ein Match
                                    </p>
                                </div>
                                {/*<div className="first-block text-center">
                                    <img className="pb-2" src="/svg/Herz_weiss.svg" style={{width:'2rem'}} />
                                    <p className="text-white">In der <Link to="/" className="text-secondary">Trefferliste</Link> siehst Du User, deren Suche Deiner Suche entspricht und kannst sie liken. Bei einem gegenseitigen Like habt Ihr ein Match</p>
                                </div>*/}
                                <div className="first-block text-center">
                                    <img className="pb-2" src="/svg/Sprechblase_weiss.svg" style={{width:'2rem'}} />
                                    <p className="text-white">Im <Link to="/" className="text-secondary">Chat</Link> kannst Du Dich mit Deinen Matches austauschen und verabreden
                                    </p>
                                </div>
                                <p className="text-white"><strong>Und jetzt: Viel Spaß!</strong></p>
                            </div>
                        </div>
                        <div className="text-center w-100 align-self-end pb-2 d-flex justify-content-center">
                            <div className="onboarding-bottom w-75">
                                <a className="btn bg-white rounded-pill w-100" href="/"> Los geht‘s!</a><br/><br/>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="bg-primary px-2 pt-4 pt-sm-5 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
                        <div className="divide-from-footer">
                            <div className="first-block text-center px-3 px-sm-5">
                                <p className="text-white"><strong>SMS LOGIN</strong></p>
                                <p className="text-white">Wenn Du bereits einen verifizierten Account hast, kannst Du Dich über Deine Mobilfunknummer wieder einloggen. Wir senden Dir dazu einen Bestätigungscode per SMS.</p>
                                <p className="text-white text-left"><strong>Deine Mobilfunknummer:</strong></p>
                            </div>
                            <div className="form-group text-center">
                                <div className="row no-gutters">
                                    <div className="col-2 pb-1 pr-2">
                                        <img src={'/flags/4x3/'+countryIso+'.svg' ? '/flags/4x3/'+countryIso+'.svg' : '/flags/4x3/fallback.svg'} className="w-100"/>
                                    </div>
                                    <div className="col-3 col-sm-2">
                                        <select className="form-select w-100 dialing" name="dialingcode" aria-label="Default select example" onChange={handleDialingcode}>
                                            {dialingcodes && dialingcodes.map((dialingcode, index) =>
                                                <option key={index} value={dialingcode.Dial} style={{display:dialingcode.Dial?'':'none'}} selected={'49'==dialingcode.Dial} id={dialingcode.ISO3166_1_Alpha_2}>
                                                    {dialingcode.ISO3166_1_Alpha_2} &nbsp;&nbsp;&nbsp;&nbsp;{dialingcode.ISO4217_Currency_Country_Name ? dialingcode.ISO4217_Currency_Country_Name : dialingcode.Country_Name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col pl-1"><input type="number" placeholder="012345" name="mobilephone" value={user.mobilephone} onChange={handleChange} className={'form-control' + (submitted && !user.mobilephone ? ' is-invalid' : '')} /></div>
                                </div>
                                {submitted && !user.mobilephone &&
                                <div className="invalid-feedback">Mobilfunknummer ist pflicht</div>
                                }
                                <div className="pt-4">
                                    <button className="btn bg-white rounded-pill w-100" onClick={() => loginWithSms(7)}>Senden</button><br/><br/>
                                </div>
                            </div>
                        </div>
                        <div className="text-center w-100 align-self-end pb-2 d-flex justify-content-center">
                            <div className="onboarding-bottom w-75">
                                <a className="text-white" onClick={() => loginWithSms(0)}> Zurück</a><br/><br/>
                                <a className="text-white" href="/login"> Login mit User und Passwort</a><br/><br/>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="bg-primary px-2 pt-4 pt-sm-5 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
                        <div className="divide-from-footer">
                            <div className="first-block text-center px-3 px-sm-5">
                                <img src="/svg/Icon_Schloss_white.svg" className="w-25 w-md-50 py-2" />
                                {!registerError && <p className="text-white">Wir haben einen Bestätigungscode an {user.mobilephone} geschickt. Bitte gib ihn hier ein:</p>}
                                {registerError && !(-1 < registerError.toString().indexOf('Unique violation: 7')) &&
                                <>
                                    <div className="text-danger">Es ist ein Fehler mit Registrierung. Versuch später nochmal.</div>
                                    <a onClick={() => handleOnboardingStep(2)} className="text-white">Zurück</a>
                                </>
                                }
                                {registerError && (-1 < registerError.toString().indexOf('Unique violation: 7')) &&
                                <>
                                    <div className="text-danger">Diese Mobilfunknummer: +49 {user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '')} ist bereit registriert.</div>
                                    <a onClick={() => handleOnboardingStep(2)} className="text-white">Mobilfunknummer apnassen</a>
                                </>
                                }
                                <h5 className="text-white text-left ml-auto">Bestätigungscode</h5>
                                <div className="form-group text-center">
                                    <input type="text" placeholder="" name="smscode" value={user.smscode} onChange={handleChangeSmsCode} className={'form-control w-50' + (submitted && !user.smscode ? ' is-invalid' : '')} disabled={smsFieldDisabled} />
                                    {submitted && !user.smscode &&
                                    <div className="invalid-feedback">SMS-Code ist pflicht</div>
                                    }
                                </div>
                                <div className="text-white text-left">
                                    <p>Du hast den Code nicht erhalten?<br/>
                                        <a onClick={resendSMS} className="text-secondary">Erneut senden</a>
                                    </p>
                                </div>
                                {confirmed &&
                                <div className="accepted">
                                    <img src="/svg/sms-confirmation-check.svg" className="w-25 w-md-50 pb-2" />
                                </div>
                                }
                                {'Code is not valid' === loginError &&
                                <div className="rejected">
                                    <p className="text-danger">Sms Code ist nicht gültig</p>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="text-center w-100 align-self-end pb-2 d-flex justify-content-center">
                            <div className="onboarding-bottom w-75">
                                {handleLogin && loginUser && <a className="btn bg-white rounded-pill w-100" href="/">Weiter</a>}<br/><br/>
                                <a onClick={() => loginWithSms(6)} className="text-white">Zurück</a>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
    )/*
    return (
        <div className="px-2 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
            <div className="divide-from-footer">
                <div className="text-center mx-sm-3 px-sm-3 mb-3"><img src="/svg/zammadoo__z-primary.svg" style={{maxWidth:'280px'}} /></div>
                <h2 className="text-secondary text-center pb-3">Registrieren</h2>
                <form name="form" onSubmit={handleSubmit}>
                    <div className="form-group" role="group" aria-labelledby="my-radio-group">
                        <div className={'btn-group btn-group-toggle' + (submitted && !user.gender ? ' is-invalid' : '')} data-toggle="buttons">
                            <label className={'btn btn-outline-secondary' + (user.gender === '1' ? ' active text-white' : '')} htmlFor="genderBoy">
                                <input type="radio" name="gender" id="genderBoy" autoComplete="off" value="1" onChange={handleChange} className={'form-check-input' + (submitted && !user.gender ? ' is-invalid' : '')} /> Herr
                            </label>
                            <label className={'btn btn-outline-secondary' + (user.gender === '2' ? ' active text-white' : '')} htmlFor="genderGirl">
                                <input type="radio" name="gender" id="genderGirl" autoComplete="off" value="2" onChange={handleChange} className={'form-check-input' + (submitted && !user.gender ? ' is-invalid' : '')} /> Frau
                            </label>
                        </div>
                        {submitted && !user.gender &&
                        <div className="invalid-feedback">Geschlecht is pflicht</div>
                        }
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="Vorname" name="firstname" value={user.firstname} onChange={handleChange} className={'form-control' + (submitted && !user.firstname ? ' is-invalid' : '')} />
                        {submitted && !user.firstname &&
                            <div className="invalid-feedback">Vorname ist pflicht</div>
                        }
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="Name" name="lastname" value={user.lastname} onChange={handleChange} className={'form-control' + (submitted && !user.lastname ? ' is-invalid' : '')} />
                        {submitted && !user.lastname &&
                            <div className="invalid-feedback">Nachname is pflicht</div>
                        }
                    </div>
                    <div className="form-group datepicker-zammadoo-register d-lg-none">
                        <div className="d-flex justify-around align-items-stretch">
                            <div className="pt-2 pr-2">
                                <label className="f-left">Geburtsdatum: </label>
                            </div>
                            <div className="flex-grow-1">
                                <IonDatetime
                                    pickerOptions={{
                                        buttons:[
                                            {
                                                text: 'Übernehmen',
                                                role: 'done',
                                                handler: (e) => {
                                                    handleChangeBirthdayWheel(e)
                                                }
                                            }
                                        ]
                                    }}
                                    locale="de"
                                    displayFormat="DD. MMM YYYY"
                                    min="1921-01-01"
                                    max={maxDate.toISOString()}
                                    value={user.birthday}
                                    monthShortNames="Jan, Feb, Mar, Apr, Mai, Jun, Jul, Aug, Sep, Okt, Nov, Dez"
                                    onIonChange={e => handleChangeBirthdayWheel(e.detail.value)}
                                ></IonDatetime>
                            </div>
                        </div>

                        <span className={(submitted && !user.birthday ? ' is-invalid' : '')}></span>
                        {submitted && !user.birthday &&
                        <div className="invalid-feedback">Geburtstag ist pflicht</div>
                        }
                    </div>
                    <div className="form-group datepicker-zammadoo-register d-none d-lg-block">
                        <div className="d-flex justify-around align-items-stretch">
                            <div className="pt-1 pr-2">
                                <label className="f-left">Geburtsdatum: </label>
                            </div>
                            <div className="flex-grow-1">
                                <DatePicker
                                    placeholderText="Geburtsdatum"
                                    selected={new Date(user.birthday)}
                                    locale="de"
                                    onChange={handleChangeBirthday}
                                    dateFormat="dd. MMMM yyyy"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={60}
                                    scrollableYearDropdown
                                    maxDate={maxDate}
                                    className="form-control"
                                    calendarClassName="zammadoo-register"
                                />
                            </div>
                        </div>

                        <span className={(submitted && !user.birthday ? ' is-invalid' : '')}></span>
                        {submitted && !user.birthday &&
                        <div className="invalid-feedback">Geburtstag ist pflicht</div>
                        }
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="E-Mail" name="email" value={user.email} onChange={handleChange} className={'form-control' + (submitted && !user.email ? ' is-invalid' : '')} />
                        {submitted && !user.email &&
                            <div className="invalid-feedback">E-Mail ist pflicht</div>
                        }
                    </div>
                    <div className="form-group">
                        <input type="password" placeholder="Passwort" name="password" value={user.password} onChange={handleChange} className={'form-control' + (submitted && !user.password ? ' is-invalid' : '')} />
                        {submitted && !user.password &&
                            <div className="invalid-feedback">Passwort ist pflicht</div>
                        }
                    </div>
                    <div className="form-group">
                        <input type="password" placeholder="Passwort bestätigen" name="confirmPassword" value={user.confirmPassword} onChange={handleChange} className={'form-control' + (submitted && !user.confirmPassword ? ' is-invalid' : '')} />
                        {submitted && !user.confirmPassword &&
                            <div className="invalid-feedback">Passwort bestätigen ist pflicht</div>
                        }
                    </div>
                    <div className="form-group">
                        <div className={'custom-control custom-switch' + (submitted && !user.cb ? ' is-invalid' : '')}>
                            <input type="checkbox" className="custom-control-input" name="cb" id="customSwitches" checked={user.cb} value="1" onClick={handleChangeCb} onChange={handleChange} />
                            <label className="custom-control-label" htmlFor="customSwitches">Ich stimme den <a
                                className="white" target="_blank" href="https://www.zammadoo.com/nutzungsbedingungen-app.html">Nutzungsbedingungen</a> und der <a
                                className="white" target="_blank" href="https://www.zammadoo.com/datenschutz-app.html">Datenschutzerklärung</a> zu.</label>
                        </div>
                        {submitted && !user.cb &&
                            <div className="invalid-feedback">Nutzungsbedingungen bestätigen ist pflicht</div>
                        }
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary rounded-pill">
                            {registering && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Registrieren
                        </button>
                        <Link to="/login" className="btn btn-secondary rounded-pill ml-2">Login</Link>
                    </div>
                </form>
            </div>
            <div className="text-center w-100 align-self-end">
                <a href="https://www.zammadoo.com/impressum.html" className="text-secondary mt-3">© Zammadoo UG</a>
            </div>
        </div>
    );*/
}

export { RegisterPage };
