const MIME_TYPE = 'application/json';
const ENTRYPOINT_BIGDATA = 'https://api.bigdatacloud.net';

export const geolocationService = {
  getLocationData
};

function getLocationData(coordinates) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
  };

  return fetch(`${ENTRYPOINT_BIGDATA}/data/reverse-geocode-client?latitude=${coordinates.latitude}&longitude=${coordinates.longitude}&localityLanguage=de`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
