import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {alertActions, userActions} from "../_actions";
import {chatService, userService} from "../_services";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {IonModal} from "@ionic/react";
import {Capacitor} from "@capacitor/core";
import { history } from '../_helpers';
import {MediaObject} from "../_components";

function UserDenounce({ match }) {
    const localContext = useSelector(state => state.context.local);
    const storage = useSelector(state => state.context.storage);
    const dispatch = useDispatch();
    const [eventSource, setEventSource] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [posted, setPosted] = useState(false);
    const user = useSelector(state => state.authentication.user);
    const { userId } = match.params;
    const [show, setShow] = useState(true);
    const [info, setInfo] = useState('');
    const [platformClass, setPlatformClass] = useState('');
    const profileData = useSelector(state => state.profile.profileData);

    function handleClose() {
        setShow(false);
        history.goBack();
    }

    useEffect(() => {
        setPlatformClass(Capacitor.platform);
    }, []);

    useEffect(() => {
        dispatch(userActions.getProfile(userId));console.log(userId);
    }, [userId]);

    function handleChange(e) {
        const { name, value } = e.target;
        setInfo(value);
    }

    function postDenunciation(){
        setPosted(true);
        const postData = {
            denounced: userId,
            info: info,
        };
        userService.denounce(postData).then(
            data => {
                setPosted(true);
                if(data.success) {
                    setShowSuccessMessage(true);
                }
            },
            error => {
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function age(dateString){
        const birthConst = new Date(dateString);
        let birth = new Date(dateString);
        let now = new Date();
        let beforeBirth = 0;
        birth.setMonth(now.getMonth());
        birth.setDate(now.getDate());
        beforeBirth = birth.getTime() < birthConst.getTime() ? 1 : 0;
        return now.getFullYear() - birth.getFullYear() - beforeBirth;
    }

    return (
        <div>
            <div>
                <p>...</p>
            </div>
            <IonModal isOpen={show} cssClass={'zammadoo-modal-frombottom zammadoo-'+platformClass}>
                <div className="modal-header border-bottom-0">
                    <div className="w-100 text-center modal-title h6 text-uppercase position-absolute"
                         style={{left:'0'}}>User Melden
                    </div>
                    <button type="button" className="close" onClick={handleClose} style={{zIndex:'1'}}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <Modal.Body className="activity-accordion">
                    <div className="settings d-flex flex-column justify-content-between" style={{minHeight:'87vh'}}>
                        <div className="text-center">
                            {profileData && profileData.profileimage && <MediaObject alt={profileData.firstname} mediaObjectUrl={profileData.profileimage} className="rounded-zammadoo profile border border-3 border-primary" />}
                            {profileData && !profileData.profileimage && <img alt={profileData.firstname} src="/profileImagePlaceholder.jpg" className="rounded-zammadoo profile border border-3 border-primary" />}
                            {profileData && <p>{profileData.firstname}, {age(profileData.birthday)}</p>}
                            <form className="w-100 pt-1 pb-5">
                                <p>Ein respektvoller Umgang miteinander zählt zu den Grundwerten von Zammadoo! Du möchtest {profileData && profileData.firstname} wegen unangemessener Inhalte melden? Hier kannst Du uns eine zusätzliche Nachricht zukommen lassen. Wir werden uns umgehend um Deine Beschwerde kümmern.</p>
                                <div className="input-group mb-3">
                                    <textarea type="text" name="axiom" value={info}
                                              onChange={handleChange} rows="5" maxLength="255"
                                              className="form-control">
                                    </textarea>
                                </div>
                            </form>
                            <div className="form-group">
                                {!posted &&
                                <button className="btn btn-primary text-white rounded-pill w-75" onClick={postDenunciation}>Beschwerde absenden</button>
                                }
                                {showSuccessMessage &&
                                    <p>Vielen Dank! Wir haben Deine Beschwerde erhalten.</p>
                                }
                            </div>
                        </div>
                        <div className="align-self-end text-center w-100 pb-4">
                        </div>
                    </div>
                </Modal.Body>
            </IonModal>
        </div>

    );
}

export { UserDenounce };

