import { ENTRYPOINT } from '../config/entrypoint';
import { authHeader } from '../_helpers';
import {userService} from "./user.service";

const MIME_TYPE = 'application/ld+json';

export const appointmentService = {
    getAppointment,
    postAppointment,
    updateAppointment
};

function getAppointment(identifier) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/api/invitation/${identifier}`, requestOptions).then(handleResponse);
}

function postAppointment(postdata) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
    body: JSON.stringify(postdata)
  };

  return fetch(`${ENTRYPOINT}/api/invitation`, requestOptions).then(handleResponse);
}

function updateAppointment(pushdata) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
    body: JSON.stringify(pushdata)
  };

  return fetch(`${ENTRYPOINT}/api/invitation`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
