import { userConstants } from '../_constants';

export function feeds(state = {}, action) {
  switch (action.type) {
    case userConstants.GETFEEDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETFEEDS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.feeds
      };
    case userConstants.GETFEEDS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
