import { activityConstants } from '../_constants';
import { activityService } from '../_services';

export const activityActions = {
    getActivities
};

function getActivities(params, flag) {
    return dispatch => {
        dispatch(request());

      activityService.getActivities(params, flag)
            .then(
                activity => dispatch(success(activity)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: activityConstants.GETACTIVITIES_REQUEST } }
    function success(activity) { return { type: activityConstants.GETACTIVITIES_SUCCESS, activity } }
    function failure(error) { return { type: activityConstants.GETACTIVITIES_FAILURE, error } }
}
