import {appointmentConstants} from '../_constants';

export function appointment(state = {}, action) {
  switch (action.type) {
    case appointmentConstants.POSTAPPOINTMENT_REQUEST:
    case appointmentConstants.GETAPPOINTMENT_REQUEST:
      return {
        loading: true
      };
    case appointmentConstants.POSTAPPOINTMENT_SUCCESS:
    case appointmentConstants.GETAPPOINTMENT_SUCCESS:
      return {
        invitation: action.appointment.id ? action.appointment : false
      };
    case appointmentConstants.POSTAPPOINTMENT_FAILURE:
    case appointmentConstants.GETAPPOINTMENT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
