import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import {IonModal} from "@ionic/react";
import {userActions} from "../_actions";
import {MediaObject} from "./MediaObject";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import imageCompression from "browser-image-compression";
import { Capacitor } from '@capacitor/core';


function SettingsProfile() {
    const user = useSelector(state => state.authentication.user);
    const localContext = useSelector(state => state.context.local);
    const profile = useSelector(state => state.profile);
    const profileData = useSelector(state => state.profile.profileData);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [pageidx, setPageidx] = useState('settings');
    const [submitted, setSubmitted] = useState(false);
    const [axiom, setAxiom] = useState('');
    const [email, setEmail] = useState('');
    const [showInputEmail, setShowInputEmail] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [axiomHasChanged, setAxiomHasChanged] = useState(false);
    const [preferences, setPreferences] = useState({
        EMAIL_ON: false
    });
    const [platformClass, setPlatformClass] = useState('');

    useEffect(() => {
        setPlatformClass(Capacitor.platform);
    }, []);

    useEffect(() => {
        if (localContext) {
            if (localContext.showSettings || localContext.showProfile) {
                if (!show) {
                    setShow(true);
                }
            }
            if (localContext.showProfile && localContext.showProfileId) {
                dispatch(userActions.getProfile(localContext.showProfileId));console.log('others prof', localContext.showProfileId);
                setPageidx('profile');
            }
            if (localContext.showSettings) {
                dispatch(userActions.getProfile());console.log('others settings', localContext)
                setPageidx('settings');
            }
        }
    }, [localContext]);

    useEffect(() => {
        if (profileData) {
            for (const [index, value] of profileData.preferences.entries()) {
                setPreferences(preferences => ({...preferences, [value]: true}))
            }
            setAxiom(profileData.axiom);
        }
    }, [profileData]);

    useEffect(() => {
        if (profile && profile.updated) {
            dispatch(userActions.getProfile());
        }
    }, [profile]);

    // only show nav when logged in
    if (!user) return null;

    function closeMod(dateString){
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);

        setShow(false);
        dispatch(userActions.getProfile());
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: false,
            showProfileId: null,
            showSettings: false
        }
        dispatch(userActions.setLocalContext(localContextUpdated));
    }

    function age(dateString){
        const birthConst = new Date(dateString);
        let birth = new Date(dateString);
        let now = new Date();
        let beforeBirth = 0;
        birth.setMonth(now.getMonth());
        birth.setDate(now.getDate());
        beforeBirth = birth.getTime() < birthConst.getTime() ? 1 : 0;
        return now.getFullYear() - birth.getFullYear() - beforeBirth;
    }

    function handleChangePreferences(e) {
        const { name, checked } = e.target;
        let preferences2array = [];
        if (checked && !profileData.email) {
            setShowInputEmail(true);
            return false;
        }
        let updateProfileData = profileData;
        setPreferences(preferences => ({ ...preferences, [name]: checked }));
        Object.keys(preferences).map(function(keyName, keyIndex) {
            if (name == keyName) {
                if (true == checked) {
                    preferences2array.push(keyName);
                }
            } else
            if (true == preferences[keyName]) {
                preferences2array.push(keyName);
            }
        });
        updateProfileData = { ...updateProfileData, ['preferences']: preferences2array };
        dispatch(userActions.updateProfile(updateProfileData));
    }

    function handleSubmitAxiom(pageIdx) {
        let updateProfileData = profileData;
        updateProfileData.axiom = axiom;
        dispatch(userActions.updateProfile(updateProfileData));
        setPageidx(pageIdx);
    }

    function handleSubmitEmail() {
        if (! email || ! email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g)) {
            setInvalidEmail(true);console.log(invalidEmail);
        } else {
            setInvalidEmail(false);
            let updateProfileData = profileData;
            updateProfileData.email = email;console.log('tete', email);
            setShowInputEmail(false);
            dispatch(userActions.updateProfile(updateProfileData));
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setAxiom(value);
        setAxiomHasChanged(true);
    }

    function handleChangeEmail(e) {
        const { name, value } = e.target;
        setInvalidEmail(false);
        setEmail(value);
    }

    function handleUpload(e) {
        const files = e.target.files;
        const imageForm = new FormData();
        let file;
        for(var x = 0; x<files.length; x++) {
            file = files[x];
            if (file.size > 1024) {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 640,
                    useWebWorker: true
                };
                imageCompression(file, options).then(x => {
                    file = x;
                    imageForm.append('file', file, file.name);
                    dispatch(userActions.postImage(imageForm));
                });
            } else {
                imageForm.append('file', file, file.name);
                dispatch(userActions.postImage(imageForm));
            }
        }
    }

    function handleDeleteImage(e,imagePath) {
        e.stopPropagation();
        dispatch(userActions.deleteImage({image:imagePath, reload:false}));
        if(imagePath === profileData.profileimage) {
            dispatch(userActions.getProfile());
        }
    }

    function handleProfileImage(process, imagePath) {
        if (process) {
            dispatch(userActions.updateProfileImage({image:imagePath}));
        }
    }

        return (
        <IonModal isOpen={show} cssClass={'zammadoo-modal-frombottom zammadoo-'+platformClass}>
{/* Page Index PROFILE */}
            {'profile' === pageidx && profile.loading && <img src="/tb-skeleton.gif" />}
            {'profile' === pageidx && profileData &&
            <>
            <div className="modal-header border-bottom-0">
                <div className="w-100 text-center modal-title h5 position-absolute" style={{left:'0'}}>{profileData.firstname}, {age(profileData.birthday)}</div>
                <button type="button" className="close" onClick={closeMod} style={{zIndex:'1'}}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </div>
            <div className="profile">
                {profileData &&
                <div className="overflow-auto" style={{height:'90vh'}}>
                    {profileData && profileData.profileimage &&
                        <div className="pb-1"><MediaObject mediaObjectUrl={profileData.profileimage} className="w-100 h-auto" /></div>
                    }
                    {profileData && profileData.images.map((image, index) => {
                        return <div key={index} className={'pb-1' + (image === profileData.profileimage ? ' d-none ': '')}><MediaObject mediaObjectUrl={image} className="w-100 h-auto" /></div>
                    })}
                    <div className="profile-axiom fixed-bottom bg-secondary text-center">
                        <div className="overflow-auto text-left" style={{height: '115px'}}>
                            <div style={{minHeight: '115px'}}>
                                <h6 className="text-white p-3 mb-2">{profileData.axiom && profileData.axiom.split('\n').map(function(item, key) {
                                    return (
                                        <span key={key}>{item}<br/></span>
                                    )
                                })}</h6>
                            </div>
                            {localContext.showProfileId &&
                            <div className="row no-gutters bg-light">
                                <div className="col text-center py-2">
                                    <a href={'/denounce/' + profileData.id} className="text-muted" style={{opacity:'0.5'}}>User melden</a>
                                </div>
                            </div>
                            }
                        </div>
                        {!localContext.showProfileId &&
                        <div className="w-100 position-absolute text-center" style={{bottom:'3rem'}}>
                            <a className="btn btn-primary rounded-pill w-50 text-white" onClick={() => setPageidx('edit-pics')}>Bearbeiten</a>
                        </div>
                        }
                    </div>
                    <div className="separator profile-view" style={{marginBottom:'12.5rem'}}></div>
                </div>
                }
            </div>
            </>
            }
{/* Page Index SETTINGS */}
            {'settings' === pageidx && profile.loading && <img src="/tb-skeleton.gif" />}
            {'settings' === pageidx && profileData &&
            <>
            <div className="modal-header border-bottom-0">
                <div className="w-100 text-center modal-title h6 text-uppercase position-absolute" style={{left:'0'}}>Einstellungen</div>
                <button type="button" className="close" onClick={closeMod} style={{zIndex:'1'}}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </div>
            <div className="settings d-flex flex-column justify-content-between justify-content-md-start" style={{minHeight:'87vh'}}>
                <div>
                    <div className="text-center px-4 position-relative" onClick={() => setPageidx('edit-pics')}>
                        {profileData.profileimage && <MediaObject alt={profileData.firstname} mediaObjectUrl={profileData.profileimage} className="rounded-zammadoo profile border border-3 border-primary" />}
                        {!profileData.profileimage && <img alt={profileData.firstname} src="/profileImagePlaceholder.jpg" className="rounded-zammadoo profile border border-3 border-primary" />}
                        <h5 className="text-center text-dark pt-3">{profileData.firstname}, {age(profileData.birthday)}</h5>
                        <div className="bg-secondary position-absolute rounded-circle" style={{width:'30px',height:'30px',left:'50%',top:'80px',marginLeft:'15px'}}>
                            <img className="zammadoo-icon" src="/svg/settings-wheel.svg" alt="" style={{width:'25px',height:'25px'}} />
                        </div>
                    </div>
                    <div className="row">
                        {'@zammadoo.com' !== user.username.toString().substr(32,13) &&
                        <div className="w-100 text-center pt-2 pb-4">
                            <a href="/verify" className="btn btn-primary rounded-pill w-50">Account verifizieren</a>
                        </div>
                        }
                    </div>
                    {false &&
                    <div className="row no-gutters bg-secondary text-white">
                        <div className="col-12 text-center py-1">Dein Account-Status</div>
                        <div className="col-6">
                            <div className="jumbotron mx-2">
                                <h6>Basic</h6>
                                <div style={{width:'4rem',height:'4rem'}}></div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="jumbotron mx-2">
                                <h6>Premium</h6>
                                <div style={{width:'4rem',height:'4rem'}}></div>
                            </div>
                        </div>
                    </div>
                    }
                    {profileData.preferences &&
                    <div className="px-4 pt-4">
                        <form name="form">
                            <div className="py-2"><h6><strong>Benachrichtigungen</strong></h6></div>
                            <div className="form-group custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" name="EMAIL_ON" checked={preferences.EMAIL_ON} value="1" id="emailOn" onChange={handleChangePreferences} />
                                <label className="custom-control-label" htmlFor="emailOn">Neue Treffer & Matches per eMail erhalten</label>
                            </div>
                        </form>
                    </div>
                    }
                    {showInputEmail &&
                    <div className="px-4">
                        <p className="text-left">Leider haben wir Deine EMail-Adresse nicht. Bitte ergänze sie hier, um Nachrichten zu erhalten.</p>
                        <div className="form-group">
                            <form name="form">
                                <input type="email" placeholder="@" name="email" value={email} required onChange={handleChangeEmail} className={'form-control' + (invalidEmail ? ' is-invalid' : '')} />
                                {invalidEmail &&
                                <div className="invalid-feedback">Valide E-Mail ist pflicht</div>
                                }
                            </form>
                        </div>
                        <div className="row">
                            <div className="col text-right">
                                <button className="btn bg-primary rounded-pill" onClick={() => handleSubmitEmail()}>Speichern</button>
                            </div>
                            <div className="col text-left">
                                <button className="btn border-primary rounded-pill" onClick={() => setShowInputEmail(false)}>Abrechen</button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className="w-100 m-0 footer-links align-self-end text-center small">
                    <div className="row">
                        <div className="w-100 text-center pb-4">
                            <a href="/register" className="btn btn-secondary rounded-pill w-50">Logout</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <a onClick={() => setPageidx('contact')}>Kontakt</a>
                        </div>
                        <div className="col-4">
                            <a onClick={() => setPageidx('data-protection')}>Datenschutz</a>
                        </div>
                        <div className="col-4">
                            <a onClick={() => setPageidx('terms-and-conditions')}>Nutzungs- bedingungen</a>
                        </div>
                    </div>
                    <div className="row bg-light">
                        <div className="col text-center py-2">
                            <a href="/deleteme" className="text-muted" style={{opacity:'0.5'}}>Account löschen</a>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
{/* Page Index EDIT-PICS */}
            {'edit-pics' === pageidx && profile.loading && <img src="/tb-skeleton.gif" />}
            {'edit-pics' === pageidx && profileData &&
            <>
            <div className="modal-header border-bottom-0">
                <div className="w-100 text-center modal-title h5 position-absolute" style={{left:'0'}}>{profileData.firstname}, {age(profileData.birthday)}</div>
                <button type="button" className="close" onClick={closeMod} style={{zIndex:'1'}}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </div>
            <div className="settings d-flex flex-column justify-content-between" style={{minHeight:'87vh'}}>
                <div className="px-4">
                    <div className="row edit-pics pr-4">
                        <div className="col-4 text-center">
                            {profileData.profileimage && <><MediaObject mediaObjectUrl={profileData.profileimage} className="rounded-zammadoo profile border border-secondary" /></>}
                        </div>
                        {profileData.images && 'function' === typeof profileData.images.map && profileData.images.map((image, index) => {
                            return (
                                <div onClick={() => handleProfileImage((image !== profileData.profileimage), image)} key={index} className={'col-4 text-center position-relative pb-3 ' + (image === profileData.profileimage ? 'd-none ': 'order-2')}>
                                    <MediaObject onClick={() => handleProfileImage(image)} mediaObjectUrl={image} className={'rounded-zammadoo profile border ' + (image === profileData.profileimage ? 'border-secondary ': 'border-primary ')} />
                                    <HighlightOffIcon className="position-absolute rounded-circle bg-white" onClick={(e) => handleDeleteImage(e, image)} style={{left:'85px',bottom:'0', margin:'0 15px 15px 0'}} />
                                </div>
                            )
                        })}
                        {profileData.images && [...Array(6 - profileData.images.length)].map((x, i) =>
                            <div key={i} className="col-4 text-center pb-3 order-3">
                                <img className="rounded-zammadoo profile border border-primary p-2" src="/svg/addpic.svg" />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="upload"
                                    onChange={handleUpload}
                                />
                            </div>
                        )}
                    </div>
                    <form name="form">
                        <div className="form-group pt-2">
                            <h6 className="py-3">Sag etwas über Dich</h6>
                            <textarea type="text" placeholder="Text" name="axiom" value={axiom}
                                      onChange={handleChange} rows="5" maxLength="255"
                                      className={'form-control' + (submitted && !axiom ? ' is-invalid' : '')}>
                            </textarea>
                            <small>Noch {255 - axiom.length} freie Zeichen.</small>
                        </div>
                    </form>
                </div>
                <div className="row footer-links align-self-end text-center w-100">
                    <div className="col-12">
                        <a className="btn btn-primary rounded-pill w-50 text-white" onClick={() => handleSubmitAxiom('settings')}>Fertig</a><br/>
                        <a className="btn border-0 pt-4" onClick={() => handleSubmitAxiom('profile')}>Vorschau</a>
                    </div>
                </div>
            </div>
            </>
            }
{/* Page Index CONTACT */}
            {'contact' === pageidx &&
            <>
            <div className="modal-header border-bottom-0">
                <div className="w-100 text-center modal-title h6 text-uppercase position-absolute" style={{left:'0'}}>Kontakt</div>
                <button type="button" className="close" onClick={closeMod} style={{zIndex:'1'}}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </div>
            <Modal.Body className="activity-accordion px-3">
                <address className="adress" itemProp="address" itemScope=""
                         itemType="http://data-vocabulary.org/Address">
                    <span itemProp="name">Zammadoo UG (haftungsbeschränkt)</span>
                    <br/>
                    <span itemProp="street-address">Klenzestraße 7</span>
                    <br/>
                    <span itemProp="postal-code">80469</span> <span itemProp="locality">München</span>
                </address>
                <p>
                    <span itemProp="tel">+49.89.21019370</span>
                    <br/>
                    <br/>
                    Geschäftsführer: Jens Rödel
                    <br/>
                    <br/>
                    Amtsgericht München
                    <br/>
                    HRB HRB 225950
                    <br/>
                    <br/>
                    <a itemProp="url" href="zammadoo.com">ZAMMADOO</a> ist eine
                    eingetragene Wort-/Bildmarke
                    <br/>
                    <br/>
                </p>
                <a className="btn btn-primary text-white" onClick={() => setPageidx('settings')}>Zurück</a>
            </Modal.Body>
            </>
            }
{/* Page Index TERMS AND CONDITIONS */}
            {'terms-and-conditions' === pageidx &&
            <>
            <div className="modal-header border-bottom-0">
                <div className="w-100 text-center modal-title h6 text-uppercase position-absolute" style={{left:'0'}}>Nutzungsbedingungen</div>
                <button type="button" className="close" onClick={closeMod} style={{zIndex:'1'}}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </div>
                <Modal.Body className="activity-accordion">
                    <div className="overflow-auto text-justify px-3" style={{height:'90vh'}}>
                        <a className="btn btn-primary text-white" onClick={() => setPageidx('settings')}>Zurück</a>
                        <p>
                            <br/><br/>
                            Allgemeine Geschäftsbedingungen für die Nutzung von „ZAMMADOO“
                            <br/><br/>
                            1. Geltungsbereich, Begriffsbestimmungen
                            <br/><br/>
                            1.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“) der Zammadoo UG (haftungsbeschränkt), Klenzestraße 7, 80469 München, (nachfolgend „Anbieter“) gelten für die Nutzung der vom Anbieter über das WWW sowie den Apple App Store oder den Google Play Store (nachfolgend jeweils „App Store“) bereitgestellten App mit der Bezeichnung „ZAMMADOO“ (nachfolgend „ZAMMADOO“) durch einen Verbraucher (nachfolgend „Nutzer“). Der Einbeziehung von eigenen Bedingungen des Nutzers wird hiermit widersprochen, es sei denn, es ist etwas anderes vereinbart.
                            <br/><br/>
                            1.2 Neben diesen AGB können im Hinblick auf die Nutzung der App zusätzlich besondere Nutzungsbedingungen (Lizenzbestimmungen) des jeweiligen App-Store-Betreibers zur Anwendung kommen. Soweit die Nutzungsbedingungen des App-Store-Betreibers im Hinblick auf die Nutzung der App diesen AGB widersprechen, gelten ausschließlich diese AGB. Im Übrigen gelten die Nutzungsbedingungen des App-Store-Betreibers ergänzend zu diesen AGB.
                            <br/><br/>
                            1.3 Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.
                            <br/><br/>
                            2. Leistungen des Anbieters
                            <br/><br/>
                            2.1 Der Anbieter stellt dem Nutzer mit ZAMMADOO eine App vorranging für mobile Endgeräte zur Verfügung, über die Nutzer eigene Nutzerprofile einstellen, Nutzerprofile anderer Nutzer abrufen und mit anderen Nutzern zum Zwecke des wechselseitigen Kennenlernens und Austauschens gemeinsamer Interessen direkt über ZAMMADOO in Kontakt treten können. Dabei kann der Nutzer zwischen einer kostenlosen Basisversion und einer kostenpflichtigen Premiumversion wählen. Der Funktionsumfang und die technischen Spezifikationen der App werden in der Leistungsbeschreibung im App Store näher beschrieben.
                            <br/><br/>
                            2.2 Der Anbieter schuldet lediglich die Bereitstellung einer technischen Möglichkeit für die Nutzer, untereinander in Kontakt treten zu können. Er steht aber nicht dafür ein, dass es zwischen den Nutzern tatsächlich zum Kontakt kommt. Ferner schuldet der Anbieter nicht die Herstellung und Aufrechterhaltung der Datenverbindung zwischen dem IT-System des Nutzers und dem Server des Anbieters.
                            <br/><br/>
                            2.3 ZAMMADOO wird unter dem Vorbehalt der Verfügbarkeit angeboten. Eine Verfügbarkeit zu 100 Prozent ist technisch nicht zu realisieren und kann deshalb dem Nutzer vom Anbieter nicht gewährleistet werden. Der Anbieter bemüht sich jedoch, den Dienst möglichst konstant verfügbar zu halten. Insbesondere Wartungs-, Sicherheits- oder Kapazitätsbelange sowie Ereignisse, die nicht im Machtbereich des Anbieters stehen (Störungen von öffentlichen Kommunikationsnetzen, Stromausfälle etc.), können zu Störungen oder zur vorübergehenden Stilllegung des Dienstes führen. Der Anbieter wird die Wartungsarbeiten, soweit dies möglich ist, in nutzungsarmen Zeiten durchführen.
                            <br/><br/>
                            2.4 Die App wird in unregelmäßigen Abständen vom Anbieter aktualisiert. Der Nutzer erhält dementsprechend nur ein Nutzungsrecht an der App in der jeweils aktuellen Fassung. Der Nutzer hat dagegen keinen Anspruch auf Herbeiführung eines bestimmten Zustandes der App.
                            <br/><br/>
                            3. Leistungsänderungen
                            <br/><br/>
                            3.1 Der Anbieter behält sich vor, die angebotenen Leistungen zu ändern oder abweichende Leistungen anzubieten, es sei denn, dies ist für den Nutzer nicht zumutbar.
                            <br/><br/>
                            3.2 Der Anbieter behält sich darüber hinaus vor, die angebotenen Leistungen zu ändern oder abweichende Leistungen anzubieten,
                            <br/><br/>
                            •	soweit er hierzu aufgrund einer Änderung der Rechtslage verpflichtet ist;<br/>
                            •	soweit er damit einem gegen sich gerichteten Gerichtsurteil oder einer Behördenentscheidung nachkommt;<br/>
                            •	soweit die jeweilige Änderung notwendig ist, um bestehende Sicherheitslücken zu schließen;<br/>
                            •	wenn die Änderung lediglich vorteilhaft für den Nutzern ist; oder<br/>
                            •	wenn die Änderung rein technischer oder prozessualer Natur ohne wesentliche Auswirkungen für den Nutzern ist.
                            <br/><br/>
                            3.3 Änderungen mit lediglich unwesentlichem Einfluss auf die Funktionen des Online-Dienstes stellen keine Leistungsänderungen im Sinne dieser Ziffer dar. Dies gilt insbesondere für Änderungen rein graphischer Art und die bloße Änderung der Anordnung von Funktionen.
                            <br/><br/>
                            4. Registrierung, Vertragsschluss
                            <br/><br/>
                            4.1 Um die Leistungen des Anbieters in Anspruch nehmen zu können, muss sich der Nutzer unter Angabe seiner persönlichen Daten für ZAMMADOO registrieren. Die Registrierung erfolgt durch Eröffnung eines Nutzerkontos, welches der Nutzer über die App des Anbieters einrichten kann.
                            <br/><br/>
                            4.2 Die Registrierung ist unbeschränkt geschäftsfähigen natürlichen Personen vorbehalten, die als Verbraucher handeln. Der Nutzer bestätigt mit der Abgabe seiner Einverständniserklärung zu diesen AGB, dass er diese Voraussetzung im Zeitpunkt der Registrierung erfüllt.
                            <br/><br/>
                            4.3 Jeder Nutzer kann nur ein Nutzerkonto für ZAMMADOO einrichten. Seine Berechtigung zur Nutzung von ZAMMADOO gilt nur für ihn persönlich und ist nicht übertragbar.
                            <br/><br/>
                            4.4 Nach Eingabe seiner Daten in das dafür vorgesehene Online-Formular kann sich der Nutzer durch Antippen des den Registrierungsprozess abschließenden Buttons anmelden. Das Absenden der Registrierungsdaten stellt das Angebot des Nutzers auf Abschluss des Nutzungsvertrages dar, das der Anbieter annehmen kann, aber nicht annehmen muss. Der Anbieter kann das Angebot des Nutzers innerhalb von fünf Tagen nach Zugang des Antrags durch eine per E-Mail übermittelte Auftragsbestätigung oder durch Freischaltung des Nutzerkontos annehmen. Nimmt der Anbieter das Vertragsangebot des Nutzers innerhalb der vorgenannten Frist nicht an, so gilt dies als Ablehnung des Angebots.
                            <br/><br/>
                            4.5 Der Vertragstext wird vom Anbieter gespeichert, ist dem Nutzer nach dessen Registrierung jedoch nicht mehr zugänglich.
                            <br/><br/>
                            4.6 Vor Abgabe seiner Vertragserklärung kann der Nutzer seine Eingaben laufend über die üblichen Tastaturfunktionen seines mobilen Endgerätes korrigieren.
                            <br/><br/>
                            4.7 Für den Vertragsschluss steht ausschließlich die deutsche Sprache zur Verfügung.
                            <br/><br/>
                            4.8 Die im Rahmen der Registrierung abgefragten Daten sind vom Nutzer vollständig und korrekt anzugeben. Der Nutzer ist verpflichtet, diese Daten (auch E-Mail-Adressen) stets auf dem aktuellen Stand zu halten. Die übermittelten Daten werden vom Anbieter grundsätzlich nicht auf ihre Richtigkeit und Vollständigkeit überprüft.
                            <br/><br/>
                            5. Widerrufsrecht für Verbraucher für kostenpflichtige Dienste
                            <br/><br/>
                            Nutzern, die eine entgeltliche Leistung in Anspruch nehmen (kostenpflichtige Dienste) steht das nachstehende Widerrufsrecht zu:
                            <br/><br/>
                            Widerrufsbelehrung
                            <br/><br/>
                            Widerrufsrecht
                            <br/><br/>
                            Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
                            <br/>
                            Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
                            <br/><br/>
                            Zammadoo UG (haftungsbeschränkt)<br/>
                            Klenzestrasse 7<br/>
                            80469 München<br/>
                            E-Mail: help@zammadoo.com<br/>
                            <br/><br/>
                            mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                            <br/><br/>
                            Folgen des Widerrufs
                            <br/><br/>
                            Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
                            <br/><br/>
                            Haben Sie verlangt, dass die Dienstleistung während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
                            <br/><br/>
                            ++++++++++++++++++++++++
                            <br/><br/>
                            Muster-Widerrufsformular
                            <br/><br/>
                            (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
                            <br/><br/>
                            An<br/>
                            Zammadoo UG (haftungsbeschränkt)<br/>
                            Klenzestrasse 7<br/>
                            80469 München<br/>
                            E-Mail: help@zammadoo.com<br/>
                            <br/><br/>
                            - Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
                            <br/><br/>
                            - Bestellt am (*)/erhalten am (*)<br/><br/>
                            _________________________________<br/>

                            - Name des/der Verbraucher(s)<br/><br/>
                            _________________________________<br/>

                            - Anschrift des/der Verbraucher(s)<br/><br/>
                            _________________________________<br/>

                            - Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)<br/><br/>

                            _________________________________<br/><br/>

                            - Datum<br/>
                            _________________________________<br/>

                            (*) Unzutreffendes streichen.<br/><br/>

                            Ende Muster-Widerrufsformular<br/><br/>
                            ++++++++++++++++++++++++<br/><br/>

                            6. Pflichten des Nutzers, Verantwortlichkeit für Inhalte
                            <br/><br/>
                            6.1 Jeder Nutzer ist für den Inhalt der Informationen, die er auf ZAMMADOO einstellt, allein verantwortlich, insbesondere für deren Wahrheitsgehalt und rechtliche Unbedenklichkeit. Der Nutzer versichert, dass die von ihm angegebenen Daten der Wahrheit entsprechen. Der Nutzer trägt dafür Sorge, dass die von ihm eingestellten Inhalte nicht gegen diese Nutzungsbedingungen oder gegen geltendes Recht verstoßen. Verboten ist  insbesondere die Veröffentlichung
                            <br/><br/>
                            •	urheberrechtlich geschützter Inhalte, wenn keine Berechtigung zur Nutzung vorliegt;<br/><br/>
                            •	falscher Tatsachenbehauptungen;<br/><br/>
                            •	von Beiträgen, die darauf abzielen, andere Nutzer persönlich anzugreifen oder zu diffamieren;<br/><br/>
                            •	rassistischer, fremdenfeindlicher, diskriminierender oder beleidigender Inhalte;<br/><br/>
                            •	Jugend gefährdender Inhalte;<br/><br/>
                            •	Gewalt verherrlichender Inhalte;<br/><br/>
                            •	von erfundenen Profilen (Fake-Profilen).<br/><br/>

                            6.2 Die vom Nutzer auf ZAMMADOO eingestellten Inhalte sind ausschließlich dem jeweiligen Nutzer zuzuordnen und geben nicht die Ansichten des Anbieters wieder. Eine Gewähr für die Richtigkeit der vom Nutzer eingestellten Inhalte übernimmt der Anbieter nicht. Jeder Nutzer ist selbst dafür verantwortlich, den Wahrheitsgehalt von Inhalten kritisch zu prüfen.
                            <br/><br/>
                            6.3 Der Nutzer darf ZAMMADOO nicht in einer Art und Weise nutzen, die dessen Verfügbarkeit nachteilig beeinflusst (z. B. Upload großer Dateien, Spam). Der Anbieter behält sich vor, die Datenmenge für Uploads zu beschränken, um die Verfügbarkeit von ZAMMADOO zu gewährleisten. Auf eine Beschränkung von Daten-Uploads wird der Anbieter ggf. im Rahmen der Leistungsbeschreibung hinweisen.
                            <br/><br/>
                            6.4 Der Nutzer erklärt und übernimmt die Verantwortung dafür, dass er das Recht besitzt, die von ihm auf ZAMMADOO eingestellten Inhalte zu nutzen. Er trägt insbesondere dafür Sorge, dass durch seine Inhalte keine Rechte Dritter verletzt werden, insbesondere Urheber-, Marken- und Persönlichkeitsrechte sowie Rechte wettbewerbsrechtlicher Natur.
                            <br/><br/>
                            6.5 Der Nutzer trägt dafür Sorge, dass die von ihm eingesetzte Hard- und Softwareumgebung den technischen Mindestanforderungen an die Nutzung der aktuell angebotenen App-Version entspricht.
                            <br/><br/>
                            6.6 Der Nutzer haftet grundsätzlich für alle Aktivitäten, die unter Verwendung seines Zugangs vorgenommen werden, soweit er dies zu vertreten hat. Für die Geheimhaltung der Zugangsdaten ist der Nutzer verantwortlich. Er hat dafür Sorge zu tragen, dass sie Dritten nicht zugänglich sind. Der Nutzer hat den Anbieter umgehend zu informieren, wenn es Anhaltspunkte dafür gibt, dass sein Zugang von Dritten genutzt wird oder wurde.
                            <br/><br/>
                            7. Rechte des Anbieters
                            <br/><br/>
                            7.1 Verstößt der Nutzer gegen gesetzliche Bestimmungen oder gegen diese AGB oder liegen dem Anbieter konkrete Anhaltspunkte hierfür vor, ist der Anbieter berechtigt ohne Vorankündigung und weitere Prüfung <br/><br/>
                            •	eine Abmahnung auszusprechen,<br/><br/>
                            •	den Zugang des Nutzers vorübergehend oder dauerhaft zu sperren oder<br/><br/>
                            •	sonstige erforderliche und geeignete Maßnahmen zu ergreifen.<br/><br/>
                            Welche Maßnahme der Anbieter ergreift, ist einzelfallabhängig und steht im billigen Ermessen des Anbieters.
                            <br/><br/>
                            7.2 Wird ein Nutzer gesperrt, darf er ZAMMADOO nicht mehr nutzen und sich nicht erneut hierfür registrieren.
                            <br/><br/>
                            7.3 Der Anbieter behält sich ausdrücklich vor, wegen eines Verstoßes des Nutzers gegen gesetzliche Bestimmungen oder gegen diese AGB rechtliche Schritte einzuleiten.
                            <br/><br/>
                            8. Einräumung von Nutzungsrechten durch den Anbieter
                            <br/><br/>
                            8.1 Der Anbieter räumt dem Nutzer ein einfaches, nicht übertragbares, auf die Dauer des Nutzungsvertrages befristetes Recht ein, ZAMMADOO im Rahmen dieser AGB zu privaten Zwecken zu nutzen. Ohne die schriftliche Zustimmung des Anbieters ist es dem Nutzer nicht gestattet, ZAMMADOO Dritten zugänglich zu machen. Der Nutzer trägt dafür Sorge, dass er Dritten keine Umgehungsmöglichkeit für den Zugang zu ZAMMADOO eröffnet.
                            <br/><br/>
                            8.2 Verletzt der Nutzer die vereinbarten Nutzungsrechte schwerwiegend, kann der Anbieter die Einräumung der Nutzungsrechte außerordentlich kündigen und den Zugang zu ZAMMADOO sperren.
                            <br/><br/>
                            8.3 Die sonstigen gesetzlichen und vertraglichen Regelungen bleiben unberührt.
                            <br/><br/>
                            9. Einräumung von Nutzungsrechten durch den Nutzer
                            <br/><br/>
                            9.1 Der Anbieter ist berechtigt, von Nutzern über ZAMMADOO zur Verfügung gestellte Inhalte und Informationen für die App sowie für seine eigene Website unter www.zammadoo.de &amp; www.zammadoo.com zu nutzen. Der Nutzer räumt dem Anbieter unentgeltlich nicht ausschließlich und zeitlich auf die Dauer des Nutzungsvertrages sowie einer darüber hinausgehenden, sich an den Zeitpunkt der Beendigung des Nutzungsvertrages anschließenden Übergangsfrist von 5 Tagen beschränkt, die hierfür erforderlichen Nutzungsrechte, insbesondere das Recht zur dauerhaften Vorhaltung und Speicherung und zur öffentlichen Zugänglichmachung, das Vervielfältigungs- und Veröffentlichungsrecht sowie das Bearbeitungs- und Verbreitungsrecht ein und sichert zu, zu dieser Nutzungsrechtseinräumung berechtigt zu sein.
                            <br/><br/>
                            9.2 Durch das Veröffentlichen von Inhalten erklärt sich der Nutzer damit einverstanden, dass alle Inhalte, die er auf ZAMMADOO einstellt, von anderen Nutzern eingesehen und von diesen gegebenenfalls an Dritte weitergeleitet werden können.
                            <br/><br/>
                            10. Vergütung
                            <br/><br/>
                            10.1 Die Nutzung von ZAMMADOO in der Basisversion ist kostenlos.
                            <br/><br/>
                            10.2 Die Nutzung von ZAMMADOO in der Premiumversion ist kostenpflichtig. Die Zahlung erfolgt monatlich über das vom jeweiligen App-Store-Betreiber zur Verfügung gestellte Zahlungssystem. Insoweit gelten ergänzend die Zahlungsbedingungen des jeweiligen App-Store-Betreibers, die der Nutzer vor einem Zahlungsauftrag mitgeteilt bekommt und akzeptieren muss.
                            <br/><br/>
                            11. Haftungsfreistellung
                            <br/><br/>
                            Der Nutzer stellt den Anbieter von sämtlichen Ansprüchen frei, die andere Nutzer oder sonstige Dritte dem Anbieter gegenüber wegen Verletzung ihrer Rechte aufgrund vom Nutzer eingestellter Inhalte oder wegen dessen sonstiger Nutzung von ZAMMADOO geltend machen. Der Nutzer übernimmt hierbei die Kosten der notwendigen Rechtsverteidigung einschließlich aller Gerichts- und Anwaltskosten in angemessener Höhe. Dies gilt nicht, wenn die Rechtsverletzung vom Nutzer nicht zu vertreten ist. Der Nutzer ist verpflichtet, dem Anbieter im Falle einer Inanspruchnahme durch Dritte unverzüglich, wahrheitsgemäß und vollständig alle Informationen zur Verfügung zu stellen, die für die Prüfung der Ansprüche und eine Verteidigung erforderlich sind.
                            <br/><br/>
                            12. Haftung
                            <br/><br/>
                            Der Anbieter haftet dem Nutzer aus allen vertraglichen, vertragsähnlichen und gesetzlichen, auch deliktischen Ansprüchen auf Schadens- und Aufwendungsersatz wie folgt:
                            <br/><br/>
                            12.1 Der Anbieter haftet aus jedem Rechtsgrund uneingeschränkt<br/><br/>
                            •	bei Vorsatz oder grober Fahrlässigkeit, <br/><br/>
                            •	bei vorsätzlicher oder fahrlässiger Verletzung des Lebens, des Körpers oder der Gesundheit, <br/><br/>
                            •	aufgrund eines Garantieversprechens, soweit diesbezüglich nichts anderes geregelt ist, <br/><br/>
                            •	aufgrund zwingender Haftung wie etwa nach dem Produkthaftungsgesetz.
                            <br/><br/>
                            12.2 Verletzt der Anbieter fahrlässig eine wesentliche Vertragspflicht, ist die Haftung auf den vertragstypischen, vorhersehbaren Schaden begrenzt, sofern nicht gemäß vorstehender Ziffer unbeschränkt gehaftet wird. Wesentliche Vertragspflichten sind Pflichten, die der Vertrag dem Anbieter nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf.
                            <br/><br/>
                            12.3 Im Übrigen ist eine Haftung des Anbieters ausgeschlossen.
                            <br/><br/>
                            12.4 Vorstehende Haftungsregelungen gelten auch im Hinblick auf die Haftung des Anbieters für seine Erfüllungsgehilfen und gesetzlichen Vertreter.
                            <br/><br/>
                            12.5 Eine Haftung des Anbieters für durch technisch bedingte Ausfälle verursachte Datenverluste, abgebrochene Datenübertragungen oder sonstige Probleme und Schäden in diesem Zusammenhang, welche von ihm nicht zu vertreten sind, ist ausgeschlossen. Der Anbieter haftet nicht für Schäden, die durch die Störung seines Betriebs bzw. des Betriebs seiner App infolge von höherer Gewalt, Aufruhr, Kriegs- und Naturereignissen oder infolge von sonstigen von ihm nicht zu vertretenden Vorkommnissen (z. B. Streik, Aussperrung, Verkehrsstörungen, Verfügungen von öffentlicher Hand des In- und Auslands) veranlasst oder auf nicht schuldhaft verursachte technische Probleme zurückzuführen sind. Dies gilt auch, soweit diese Störungen bei vom Anbieter beauftragten Dritten eintreten.
                            <br/><br/>
                            13. Laufzeit, Kündigung
                            <br/><br/>
                            13.1 Der Vertrag über die Nutzung von ZAMMADOO in der Basisversion wird auf unbestimmte Zeit geschlossen und kann von beiden Seiten jederzeit ohne Einhaltung einer Kündigungsfrist und ohne Angabe von Gründen mit sofortiger Wirkung gekündigt werden.
                            <br/><br/>
                            13.2 Wenn nicht anders vereinbart, können kostenpflichtige Leistungspakete, die eine Laufzeit von einem Monat oder länger haben, vom Nutzer bis 1 Tag vor Ende der vereinbarten Vertragslaufzeit zum Ende der Vertragslaufzeit gekündigt werden. Kostenpflichtige Leistungspakete verlängern sich automatisch und wiederkehrend um die jeweils vertraglich vereinbarte Laufzeit, sofern der Nutzer seinen Vertrag vor Ablauf der jeweiligen Laufzeit nicht form- und fristgerecht kündigt. Der Anbieter kann den Vertrag über entgeltliche Dienste jederzeit mit einer 14-tägigen Kündigungsfrist zum Ende der bezahlten Vertragslaufzeit kündigen.
                            <br/><br/>
                            13.3 Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
                            <br/><br/>
                            Ein wichtiger Grund liegt vor, wenn dem kündigenden Teil unter Berücksichtigung aller Umstände des Einzelfalls und unter Abwägung der beiderseitigen Interessen die Fortsetzung des Vertragsverhältnisses bis zur vereinbarten Beendigung oder bis zum Ablauf einer Kündigungsfrist nicht zugemutet werden kann.
                            <br/><br/>
                            Ein wichtiger Grund ist insbesondere:
                            <br/><br/>
                            •	ein erheblicher Verstoß gegen wesentliche Bestimmungen dieser AGB, der auch nach Fristsetzung nicht beseitigt oder wiederholt begangen wird und die Fortführung des Vertragsverhältnisses unmöglich oder unzumutbar macht;<br/><br/>
                            •	die erneute Registrierung während einer noch laufenden Sperrung gemäß Ziffer 6.1;<br/><br/>
                            •	wenn im Falle einer kostenpflichtigen Nutzung von ZAMMADOO der Nutzer für zwei aufeinander folgende Termine mit der Entrichtung des Entgelts oder eines nicht unerheblichen Teils des Entgelts in Verzug ist;<br/><br/>
                            •	wenn im Falle einer kostenpflichtigen Nutzung von ZAMMADOO der Nutzer seine Zahlungen allgemein einstellt;<br/><br/>
                            •	wenn im Falle einer kostenpflichtigen Nutzung von ZAMMADOO gegen das gesamte Vermögen der anderen Partei oder eines wesentlichen Teils dieses Vermögens ein Zwangsvollstreckungsverfahren eingeleitet wurde oder Gründe für die Eröffnung eines Insolvenzverfahrens gegen die andere Partei vorliegen, ein solches Verfahren mangels Masse abgelehnt wurde, gegen die andere Partei ein Insolvenzverfahren beantragt wurde oder von der anderen Partei eine eidesstattliche Versicherung über ihr Vermögen abgegeben wurde.
                            <br/><br/>
                            13.4 Im Falle einer berechtigten außerordentlichen Kündigung des Nutzungsvertrages durch den Anbieter, ist der Nutzer nicht berechtigt, erneut ein Nutzerkonto für ZAMMADOO anzulegen.
                            <br/><br/>
                            13.5 Kündigungen können schriftlich, in Textform (z. B. E-Mail) oder seitens des Nutzers auch durch Deinstallation der App von allen Endgeräten des Nutzers erfolgen.
                            <br/><br/>
                            13.6 Die Kündigung von kostenpflichtigen Leistungspaketen hat im Zweifel nicht die Kündigung des Nutzungsvertrages insgesamt zur Folge. Der Nutzer wird auf die kostenlose Basisversion des Dienstes zurückgestuft. Die Kündigung des Nutzungsvertrages insgesamt bedarf einer gesonderten und eindeutigen Kündigung.
                            <br/><br/>
                            13.7 Wird der Nutzungsvertrag insgesamt und auch im Hinblick auf die Nutzung der kostenlosen Basisversion gekündigt, verliert der Nutzer den Zugriff auf sein Nutzerkonto. Mit Kündigung des Nutzungsvertrages erlischt auch die Pflicht des Anbieters zur Speicherung der eingestellten Daten.
                            <br/><br/>
                            14. Änderung der AGB
                            <br/><br/>
                            14.1 Der Anbieter behält sich vor, diese AGB jederzeit ohne Angabe von Gründen zu ändern, es sei denn, dies ist für den Nutzer nicht zumutbar. Der Anbieter wird den Nutzern über Änderungen der AGB rechtzeitig in Textform benachrichtigen. Widerspricht der Nutzer der Geltung der neuen AGB nicht innerhalb einer Frist von vier Wochen nach der Benachrichtigung, gelten die geänderten AGB als vom Nutzer angenommen. Der Anbieter wird den Nutzer in der Benachrichtigung auf sein Widerspruchsrecht und die Bedeutung der Widerspruchsfrist hinweisen. Widerspricht der Nutzer den Änderungen innerhalb der vorgenannten Frist, so besteht das Vertragsverhältnis zu den ursprünglichen Bedingungen fort.
                            <br/><br/>
                            14.2 Der Anbieter behält sich darüber hinaus vor, diese AGB zu ändern,<br/><br/>
                            •	soweit er hierzu aufgrund einer Änderung der Rechtslage verpflichtet ist;<br/><br/>
                            •	soweit er damit einem gegen sich gerichteten Gerichtsurteil oder einer Behördenentscheidung nachkommt;<br/><br/>
                            •	soweit er zusätzliche, gänzlich neue Dienstleistungen, Dienste oder Dienstelemente einführt, die einer Leistungsbeschreibung in den AGB bedürfen, es sei denn, dass bisherige Nutzungsverhältnis wird dadurch nachteilig verändert;<br/><br/>
                            •	wenn die Änderung lediglich vorteilhaft für den Nutzer ist; oder<br/><br/>
                            •	wenn die Änderung rein technisch oder prozessual bedingt ist, es sei denn, sie hat wesentliche Auswirkungen für den Nutzer.
                            <br/><br/>
                            14.3 Das Kündigungsrecht des Nutzers gemäß Ziffer 13 bleibt hiervon unberührt.
                            <br/><br/>
                            15. Schlussbestimmungen
                            <br/><br/>
                            Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.
                            <br/><br/>
                        </p>
                        <a className="btn btn-primary text-white" onClick={() => setPageidx('settings')}>Zurück</a>
                        <p><br/><br/><br/><br/><br/><br/></p>
                    </div>
                </Modal.Body>
            </>
            }
{/* Page Index DATA PROTECTION */}
            {'data-protection' === pageidx &&
            <>
            <div className="modal-header border-bottom-0">
                <div className="w-100 text-center modal-title h6 text-uppercase position-absolute" style={{left:'0'}}>Datenschutzerklärung</div>
                <button type="button" className="close" onClick={closeMod} style={{zIndex:'1'}}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </div>
                <Modal.Body className="activity-accordion">
                    <div className="overflow-auto text-justify px-3" style={{height:'90vh'}}>
                        <a className="btn btn-primary text-white" onClick={() => setPageidx('settings')}>Zurück</a>
                        <p>1) Information über die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen<br/>
                            1.1 Wir freuen uns, dass Sie unsere Applikation (nachfolgend „App“) verwenden. Im Folgenden informieren wir Sie über den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer App. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie persönlich identifiziert werden können.<br/><br/>
                            1.2 Verantwortlicher für die Datenverarbeitung bezüglich dieser App im Sinne der Datenschutz-Grundverordnung (DSGVO) ist Zammadoo UG (haftungsbeschränkt), Klenzestrasse 7, 80469 München, Deutschland, Tel.: +49 (0)89 2101 93 70, E-Mail: info@zammadoo.com. Der für die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.<br/><br/>
                            1.3 Diese App nutzt aus Sicherheitsgründen und zum Schutz der Übertragung personenbezogene Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschlüsselung. Sie können eine verschlüsselte Verbindung an der Zeichenfolge „https://“ und dem Schloss-Symbol in Ihrer Browserzeile erkennen.<br/><br/>
                            2) Logfiles bei Nutzung unserer mobilen App<br/>
                            Wenn Sie unsere mobile App über einen App-Store herunterladen, werden die erforderlichen Informationen an den App Store übertragen, also insbesondere Nutzername, E-Mail-Adresse und Kundennummer Ihres Accounts, Zeitpunkt des Downloads, Zahlungsinformationen und die individuelle Gerätekennziffer. Auf diese Datenerhebung haben wir keinen Einfluss und sind hierfür nicht verantwortlich. Wir verarbeiten die Daten nur, soweit es für das Herunterladen der mobilen App auf ihr mobiles Endgerät notwendig ist.<br/>
                            Bei Nutzung unserer mobilen App erheben wir die nachfolgend beschriebenen personenbezogenen Daten, um die komfortable Nutzung der Funktion zu ermöglichen. Wenn Sie unsere mobile App nutzen möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um ihnen die Funktionen unserer mobilen App anzubieten und die Stabilität und Sicherheit zu gewährleisten:<br/>
                            - Datum und Uhrzeit der Anfrage<br/>
                            - Zeitzonendifferenz zur Greenwich Mean Time (GMT)<br/>
                            - Inhalt der Anforderung<br/>
                            - Zugriffsstatus/ http-Statuscode<br/>
                            - Menge der gesendeten Daten in Byte<br/>
                            - Quelle/Verweis, von welchem Sie auf die Seite gelangten<br/>
                            - Verwendeter Browser<br/>
                            - Sprache und Version der Browsersoftware<br/>
                            - Verwendetes Betriebssystem und dessen Oberfläche<br/>
                            - Verwendete IP-Adresse (ggf.: in anonymisierter Form)<br/>
                            Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer App. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die vorbenannten Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.<br/>
                            Weiterhin benötigen wir Ihre eindeutige Nummer des Endgeräts (IMEI = International Mobile Equipment Identity), eindeutige Nummer des Netzteilnehmers (IMSI = International Mobile Subscriber Identity), Mobilfunknummer (MSISDN), evtl. MAC-Adresse für die WLAN-Nutzung und den Namen Ihres mobilen Endgerätes.<br/><br/>
                            3) Cookies<br/>
                            Um unsere App attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Einige der von uns verwendeten Cookies werden nach dem Schließen der App wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns Sie wiederzuerkennen (sog. persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten diese im individuellen Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann.<br/>
                            Teilweise dienen die Cookies dazu, durch Speicherung von Einstellungen den Betrieb der App zu vereinfachen. Sofern durch einzelne von uns eingesetzte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gemäß Art. 6 Abs. 1 lit. b DSGVO zur Durchführung des Vertrages, gemäß Art. 6 Abs. 1 lit. a DSGVO im Falle einer erteilten Einwilligung oder gemäß Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der bestmöglichen Funktionalität der App sowie einer kundenfreundlichen und effektiven Ausgestaltung der App-Nutzung.<br/>
                            Sie können die Einstellungen Ihres mobilen Betriebssystems und der App nach Ihren Wünschen konfigurieren und zum Beispiel die Annahme von Third--Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie jedoch darauf hin, dass sie in diesem Fall eventuell nicht mehr alle Funktionen unserer mobilen App nutzen können.<br/><br/>
                            4) Verwendung Ihres Adressbuchs, Kalenders, Ihrer Fotos und Erinnerungen<br/>
                            Zu Beginn der Nutzung unserer mobilen App bitten wir Sie in einem Pop-up um die Erlaubnis zur Nutzung Ihres Adressbuchs und/ oder Kalenders und/ oder Ihrer Fotos und/ oder Erinnerungen. Wenn Sie die Einwilligung nicht erteilen, nutzen wir diese Daten nicht. Eventuell können Sie in diesem Fall nicht alle Funktionen unserer App nutzen. Sie können die Einwilligung später in den Einstellungen Ihres Betriebssystems erteilen oder widerrufen.<br/>
                            Wenn Sie den Zugriff auf diese Daten gestatten, wird die mobile App nur auf ihre Daten zugreifen und sie auf unseren Server übertragen, soweit es für die Erbringung der Funktionalität notwendig ist. Ihre Daten werden von uns vertraulich behandelt und gelöscht, wenn sie die recht zur Nutzung widerrufen oder zur Erbringug der Leistung nicht mehr erforderlich sind und keine rechtlichen Aufbewahrungspflichten bestehen. Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 S. 1 Lit. f DSGVO.<br/><br/>
                            5) Erhebung von Standortdaten<br/>
                            - Mit Einwilligung<br/>
                            Unser Angebot umfasst sog. Location Based Services, mit welchen wir Ihnen spezielle Angebote bieten, die auf ihren jeweiligen Standort zugeschnitten sind. Diese Funktion können Sie erst nutzen, nachdem sie über ein Pop-up zugestimmt haben, dass wir zu Zwecken der Leistungserbringung ihrer Standortdaten mittels GPS und Ihre IP-Adresse in anonymisierter Form erheben können. Sie können die Funktion in den Einstellungen der App oder ihres mobilen Betriebssystems jederzeit erlauben oder widerrufen. Ihr Standort wird nur an uns übertragen, wenn sie bei Nutzung der App Funktionen in Anspruch nehmen, die wir Ihnen nur bei Kenntnis ihres Standortes anbieten können.<br/><br/>
                            6) Kontaktaufnahme<br/>
                            Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular in der App ersichtlich. Diese Daten werden ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht. Dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.<br/><br/>
                            7) Online-Terminvereinbarung<br/>
                            Wir verarbeiten Ihre personenbezogenen Daten im Rahmen der zur Verfügung gestellten Online-Terminvereinbarung. Welche Daten wir zur Online-Terminvereinbarung erheben, können Sie aus dem jeweiligen Eingabeformular bzw. der Terminabfrage zur Terminvereinbarung ersehen. Sofern gewisse Daten notwendig sind, um eine Online-Terminvereinbarung durchführen zu können, machen wir diese im Eingabeformular bzw. bei der Terminabfrage entsprechend kenntlich. Sofern wir Ihnen ein Freitextfeld beim Eingabeformular zur Verfügung stellen, können Sie dort Ihr Anliegen näher beschreiben. Sie können dann auch selbst steuern, welche Daten Sie zusätzlich eintragen möchten.<br/>
                            Ihre mitgeteilten Daten werden ausschließlich zum Zweck der Terminvereinbarung gespeichert und verwendet. Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages mit Ihnen erforderlich sind (dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind), dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Haben Sie uns eine Einwilligung für die Verarbeitung Ihrer Daten erteilt, erfolgt die Verarbeitung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Eine erteilte Einwilligung kann jederzeit durch eine Nachricht an den zu Beginn dieser Erklärung genannten Verantwortlichen widerrufen werden.<br/><br/>
                            8) Datenverarbeitung bei Eröffnung eines Kundenkontos und zur Vertragsabwicklung<br/>
                            Gemäß Art. 6 Abs. 1 lit. b DGSVO werden personenbezogene Daten weiterhin erhoben und verarbeitet, wenn Sie uns diese zur Durchführung eines Vertrages oder bei der Eröffnung eines Kundenkontos mitteilen. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Eine Löschung Ihres Kundenkontos ist jederzeit möglich und kann durch eine Nachricht an die o.g. Adresse des Verantwortlichen erfolgen. Wir speichern und verwenden die von Ihnen mitgeteilten Daten zur Vertragsabwicklung. Nach vollständiger Abwicklung des Vertrages oder Löschung Ihres Kundenkontos werden Ihre Daten mit Rücksicht auf steuer- und handelsrechtliche Aufbewahrungsfristen gesperrt und nach Ablauf dieser Fristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder eine gesetzlich erlaubte weitere Datenverwendung von unserer Seite vorbehalten wurde, über die wir Sie nachstehend entsprechend informieren.<br/><br/>
                            9) Datenverarbeitung zur Vertragsabwicklung<br/>
                            Zur Abwicklung von über die App geschlossenen Verträgen arbeiten wir mit dem / den nachstehenden Dienstleistern zusammen, die uns ganz oder teilweise bei der Durchführung geschlossener Verträge unterstützen. An diese Dienstleister werden nach Maßgabe der folgenden Informationen gewisse personenbezogene Daten übermittelt.<br/>
                            Die von uns erhobenen personenbezogenen Daten werden im Rahmen der Vertragsabwicklung an das mit der Lieferung beauftragte Transportunternehmen weitergegeben, soweit dies zur Lieferung der Ware erforderlich ist. Ihre Zahlungsdaten geben wir im Rahmen der Zahlungsabwicklung an das beauftragte Kreditinstitut weiter, sofern dies für die Zahlungsabwicklung erforderlich ist. Sofern Zahlungsdienstleister eingesetzt werden, informieren wir Sie hierüber nachstehend explizit. Rechtsgrundlage für die Weitergabe der Daten ist Art. 6 Abs. 1 lit. b DSGVO.<br/><br/>
                            10) Registrierung in der App<br/>
                            Sie können sich in unserer App unter Angabe von personenbezogenen Daten registrieren. Welche personenbezogenen Daten für die Registrierung verarbeitet werden, ergibt sich aus der Eingabemaske, die für die Registrierung verwendet wird. Wir verwenden für die Registrierung das sog. Double-opt-in-Verfahren, d. h. Ihre Registrierung ist erst abgeschlossen, wenn Sie zuvor Ihre Anmeldung über eine Ihnen zu diesem Zweck zugesandte Bestätigungs-E-Mail durch Klick auf den darin enthaltenen Link bestätigt haben. Falls Ihre diesbezügliche Bestätigung nicht binnen 24 Stunden erfolgt, wird Ihre Anmeldung automatisch aus unserer Datenbank gelöscht. Die Angabe der zuvor genannten Daten ist verpflichtend. Alle weiteren Informationen können Sie freiwillig durch Nutzung unseres Portals bereitstellen.<br/><br/>
                            Wenn Sie unsere App nutzen, speichern wir Ihre zur Vertragserfüllung erforderlichen Daten, auch evtl. Angaben zur Zahlungsweise, bis Sie Ihren Zugang endgültig löschen. Weiterhin speichern wir die von Ihnen freiwillig angegebenen Daten für die Zeit Ihrer Nutzung des Portals, soweit Sie diese nicht zuvor löschen. Alle Angaben können Sie im geschützten Kundenbereich verwalten und ändern. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO.<br/>
                            Darüber hinaus speichern wir alle von Ihnen veröffentlichten Inhalte (wie z.B. öffentliche Beiträge, Pinnwandeinträge, Gästebucheintragungen, etc.) um die App zu betreiben. Wir haben ein berechtiges Interesse in der der Bereitstellung der App mit dem vollständigen User-Generated-Content. Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. f DSGVO. Wenn Sie Ihren Account löschen, bleiben Ihre insbesondere im Forum veröffentlichten Äußerungen weiterhin für alle Leser sichtbar, Ihr Account ist jedoch nicht mehr abrufbar. Alle übrigen Daten werden in diesem Fall gelöscht.<br/><br/>
                            11) Nutzung Ihrer Daten zur Direktwerbung<br/>
                            11.1 Anmeldung zu unserem E-Mail-Newsletter<br/>
                            Wenn Sie sich zu unserem E-Mail Newsletter anmelden, übersenden wir Ihnen regelmäßig Informationen zu unseren Angeboten. Pflichtangabe für die Übersendung des Newsletters ist allein Ihre E-Mailadresse. Die Angabe weiterer Daten ist freiwillig und wird verwendet, um Sie persönlich ansprechen zu können. Für den Versand des Newsletters verwenden wir das sog. Double Opt-in Verfahren. Dies bedeutet, dass wir Ihnen erst dann einen E-Mail Newsletter übermitteln werden, wenn Sie uns ausdrücklich bestätigt haben, dass Sie in den Empfang von Newsletter einwilligen. Wir schicken Ihnen dann eine Bestätigungs-E-Mail, mit der Sie gebeten werden durch Anklicken eines entsprechenden Links zu bestätigen, dass Sie künftig den Newsletter erhalten wollen.<br/>
                            Mit der Aktivierung des Bestätigungslinks erteilen Sie uns Ihre Einwilligung für die Nutzung Ihrer personenbezogenen Daten gemäß Art. 6 Abs. 1 lit. a DSGVO. Bei der Anmeldung zum Newsletter speichern wir Ihre vom Internet Service-Provider (ISP) eingetragene IP-Adresse sowie das Datum und die Uhrzeit der Anmeldung, um einen möglichen Missbrauch Ihrer E-Mail- Adresse zu einem späteren Zeitpunkt nachvollziehen zu können. Die von uns bei der Anmeldung zum Newsletter erhobenen Daten werden ausschließlich für Zwecke der werblichen Ansprache im Wege des Newsletters benutzt. Sie können den Newsletter jederzeit über den dafür vorgesehenen Link im Newsletter oder durch entsprechende Nachricht an den eingangs genannten Verantwortlichen abbestellen. Nach erfolgter Abmeldung wird Ihre E-Mailadresse unverzüglich in unserem Newsletter-Verteiler gelöscht, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüberhinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.<br/><br/>
                            11.2 Versand des E-Mail-Newsletters an Bestandskunden<br/>
                            Wenn Sie uns Ihre E-Mailadresse beim Kauf von Waren bzw. Dienstleistungen zur Verfügung gestellt haben, behalten wir uns vor, Ihnen regelmäßig Angebote zu ähnlichen Waren bzw. Dienstleistungen, wie den bereits gekauften, aus unserem Sortiment per E-Mail zuzusenden. Hierfür müssen wir gemäß § 7 Abs. 3 UWG keine gesonderte Einwilligung von Ihnen einholen. Die Datenverarbeitung erfolgt insoweit allein auf Basis unseres berechtigten Interesses an personalisierter Direktwerbung gemäß Art. 6 Abs. 1 lit. f DSGVO. Haben Sie der Nutzung Ihrer E-Mailadresse zu diesem Zweck anfänglich widersprochen, findet ein Mailversand unsererseits nicht statt. Sie sind berechtigt, der Nutzung Ihrer E-Mailadresse zu dem vorbezeichneten Werbezweck jederzeit mit Wirkung für die Zukunft durch eine Mitteilung an den zu Beginn genannten Verantwortlichen zu widersprechen. Hierfür fallen für Sie lediglich Übermittlungskosten nach den Basistarifen an. Nach Eingang Ihres Widerspruchs wird die Nutzung Ihrer E-Mailadresse zu Werbezwecken unverzüglich eingestellt.<br/><br/>
                            12) Versand von Push-Benachrichtigungen<br/>
                            Sie können sich zum Empfang von unseren Push-Benachrichtigungen anmelden. Sie erhalten über unsere Push-Benachrichtigungen regelmäßig Informationen über unsere angebotenen Leistungen.<br/>
                            Zur Anmeldung müssen Sie den Erhalt von Benachrichtigungen bestätigen bzw. in den Einstellungen Ihres Betriebssystems erlauben. Dieser Prozess wird dokumentiert und gespeichert. Hierzu gehört die Speicherung des Anmeldezeitpunkts sowie Ihre Ihre Gerätekennzeichnung. Die Erhebung dieser Daten ist erforderlich, damit wir zum einen die Push-Benachrichtigungen anzeigen lassen können und zum anderen im Falle eines Missbrauchs die Abläufe nachvollziehen können und dient deshalb unserer rechtlichen Absicherung. Die Verarbeitung dieser Daten erfolgt auf Basis des Art. 6 Abs. 1 lit. a DSGVO.<br/>
                            Ihre Einwilligung in die Speicherung und Verwendung Ihrer personenbezogenen Daten zum Erhalt unserer Push-Benachrichtigungen und der zuvor beschriebenen statistischen Erhebung können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Zum Zwecke des Widerrufs der Einwilligung können Sie die dazu vorgesehene Einstellung zum Erhalt von Push Benachrichtigungen in Ihren Einstellungen der App in Ihrem Betriebssystem abbestellen.<br/>
                            Ihre Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Ihre Daten werden demnach solange gespeichert, wie das Abonnement für unsere Push-Benachrichtigungen aktiv ist.<br/><br/>
                            13) Tools und Sonstiges<br/>
                            Google Maps<br/>
                            In unserer App verwenden wir Google Maps (API) von Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland (“Google”). Google Maps ist ein Dienst zur Darstellung von interaktiven (Land-)Karten, um geographische Informationen visuell darzustellen. Über die Nutzung dieses Dienstes wird Ihnen unser Standort angezeigt und eine etwaige Anfahrt erleichtert.<br/>
                            Bereits beim Aufrufen derjenigen Seiten in unserer App, in die die Karte von Google Maps eingebunden ist, werden Informationen über Ihre Nutzung unserer App (wie z.B. Ihre IP-Adresse) an Server von Google übertragen und dort gespeichert, hierbei kann es auch zu einer Übermittlung an die Server der Google LLC. in den USA kommen. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind oder ob ein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten (selbst für nicht eingeloggte Nutzer) als Nutzungsprofile und wertet diese aus. Die Erhebung, Speicherung und die Auswertung erfolgen gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis des berechtigten Interesses von Google an der Einblendung personalisierter Werbung, Marktforschung und/oder der bedarfsgerechten Gestaltung von Google-Websites. Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich für dessen Ausübung an Google wenden müssen.<br/>
                            Die Nutzungsbedingungen von Google können Sie unter https://www.google.de/intl/de/policies/terms/regional.html einsehen, die zusätzlichen Nutzungsbedingungen für Google Maps finden Sie unter https://www.google.com/intl/de_US/help/terms_maps.html<br/>
                            Ausführliche Informationen zum Datenschutz im Zusammenhang mit der Verwendung von Google Maps finden Sie auf der Internetseite von Google („Google Privacy Policy“): https://www.google.de/intl/de/policies/privacy/<br/>
                            Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben, befolgen Sie bitte die vorstehend geschilderte Möglichkeit zur Vornahme eines Widerspruchs.<br/><br/>
                            14) Rechte des Betroffenen<br/>
                            14.1 Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), über die wir Sie nachstehend informieren:<br/>
                            - Auskunftsrecht gemäß Art. 15 DSGVO: Sie haben insbesondere ein Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien für die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen über die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer bestehen;<br/>
                            - Recht auf Berichtigung gemäß Art. 16 DSGVO: Sie haben ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;<br/>
                            - Recht auf Löschung gemäß Art. 17 DSGVO: Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;<br/>
                            - Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe überwiegen;<br/>
                            - Recht auf Unterrichtung gemäß Art. 19 DSGVO: Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet zu werden.<br/>
                            - Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;<br/>
                            - Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt;<br/>
                            - Recht auf Beschwerde gemäß Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes.<br/><br/>
                            14.2 WIDERSPRUCHSRECHT<br/>
                            WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES ÜBERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN.<br/>
                            MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.<br/>
                            WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE KÖNNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUSÜBEN.<br/>
                            MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.<br/><br/>
                            15) Dauer der Speicherung personenbezogener Daten<br/>
                            Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und – sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).<br/>
                            Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft.<br/>
                            Existieren gesetzliche Aufbewahrungsfristen für Daten, die im Rahmen rechtsgeschäftlicher bzw. rechtsgeschäftsähnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.<br/>
                            Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO ausübt, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.<br/>
                            Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO ausübt.<br/>
                            Sofern sich aus den sonstigen Informationen dieser Erklärung über spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im Übrigen dann gelöscht, wenn sie für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.
                        </p>
                        <a className="btn btn-primary text-white" onClick={() => setPageidx('settings')}>Zurück</a>
                        <p><br/><br/><br/><br/><br/><br/></p>
                    </div>
                </Modal.Body>
            </>
            }
        </IonModal>
    );
}

export { SettingsProfile };
