import React, {useEffect, useState} from 'react';

const ENTRYPOINT_BIGDATA = 'https://api.bigdatacloud.net';

function LocationName(props) {
    const [myProps, setMyProps] = useState({
        className: props.className ? props.className : '',
        style: props.style ? props.style : {},
        latitude: props.latitude,
        longitude: props.longitude,
    });
    const [locationData, setLocationData] = useState({});

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
        };

        fetch(`${ENTRYPOINT_BIGDATA}/data/reverse-geocode-client?latitude=${myProps.latitude}&longitude=${myProps.longitude}&localityLanguage=de`, requestOptions)
            .then(response => response.json())
            .then(data => setLocationData(data))
            .catch();
    }, []);

    return (
        <span className={myProps.className} style={myProps.style}>{
            locationData && <>{locationData.locality + ' '}{locationData.city ? '('+locationData.city+')':''}</>
        }</span>
    );
}

export { LocationName };
