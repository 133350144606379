import React, {useEffect, useState} from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { history } from '../_helpers';
import { alertActions, userActions } from '../_actions';
import {userService} from '../_services';
import {Navi, NaviFooter, PrivateRoute, SettingsProfile} from '../_components';
import {
    HomePage,
    Profile,
    EditProfile,
    Searches,
    Matches,
    Chat,
    ChatConversation,
    RemoveAccount,
    VerifyAccount,
    UserDenounce,
    Searchagent,
    SearchesType3
} from '../HomePage';
import {LoginPage, ResendConfirmationLink, PasswordForgotten, PasswordReset} from '../LoginPage';
import { RegisterPage } from '../RegisterPage';
import { Plugins,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed,
    Capacitor } from '@capacitor/core';
import { useAppState, useAppUrlOpen, useLaunchUrl, availableFeatures } from '@capacitor-community/react-hooks/app';
import {Modal} from "react-bootstrap";

history.listen((location, action) => {
    window.scrollTo(0, 0);
})

function App() {
    const user = useSelector(state => state.authentication.user);
    const alert = useSelector(state => state.alert);
    const appContext = useSelector(state => state.context.appContext);
    const dispatch = useDispatch();
    const [platformClass, setPlatformClass] = useState('');
    const [show, setShow] = useState(false);
    const [locationClass, setLocationClass] = useState('');
    const [updatepopup, setUpdatepopup] = useState({
        force:false,
        title:'Update verfügbar',
        text: 'Es steht eine neue App-Version zur Verfügung. Bitte führe bald ein Update durch, um die App weiter nutzen zu können.'
    });
    const { PushNotifications } = Plugins;
    const {state} = useAppState();

    useEffect(() => {
        setPlatformClass(Capacitor.platform);
    }, []);

    useEffect(() => {
        if (Capacitor.platform !== 'web') {
            PushNotifications.removeAllDeliveredNotifications();
            console.log('sessioncontroller: ' + state);
            if (appContext) {
                let appContextUpdated = appContext;
                let now2compare = new Date();
                if (0 === appContextUpdated.lastUpdateNotification) {
                    let getTime2set = new Date();
                    getTime2set.setHours(getTime2set.getHours() + 1);
                    appContextUpdated.lastUpdateNotification = getTime2set.getTime();
                    dispatch(userActions.setAppContext(appContextUpdated));
                    loadData();
                }
                if (now2compare.getTime() > appContextUpdated.lastUpdateNotification) {
                    let getTime2set = new Date();
                    getTime2set.setHours(getTime2set.getHours() + 1);
                    appContextUpdated.lastUpdateNotification = getTime2set.getTime();
                    appContextUpdated.synch = false;
                    dispatch(userActions.setAppContext(appContextUpdated));
                    loadData();
                }
            }
        }
        if (user) {
            userService.reportSession(state);console.log('userService.reportSession(state)');
        }
    }, [state]);

    const loadData = async () => {
        if (user) {
            const data = await userService.getAppUpdateMatrix();
            console.log('titostate', state, data);
            if (data.forceUpdate || data.recommendUpdate) {
                let text = updatepopup.text;
                let force = false;
                if (data.forceUpdate) {
                    force = true;
                    text = data.forceUpdateMessage;
                }
                if (data.recommendUpdate) {
                    force = false;
                    text = data.recommendUpdateMessage;
                }
                setUpdatepopup(updatepopap => ({...updatepopup, text: text, force: force}));
                setShow(true);
            }
        }
    }

    useEffect(() => {
        if (Capacitor.platform !== 'web') {
            PushNotifications.requestPermission().then((permission) => {
                if (permission.granted) {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
                } else {
                    // No permission for push granted
                }
            });

            PushNotifications.addListener(
                'registration',
                (token: PushNotificationToken) => {
                    console.log('My token: ' + JSON.stringify(token));
                    if (!appContext) {
                        let appContextObject = {
                            token: token.value,
                            synch: false,
                            capacitor: Capacitor,
                            lastUpdateNotification: 0
                        }
                        dispatch(userActions.setAppContext(appContextObject));
                    }
                }
            );

            PushNotifications.addListener('registrationError', (error: any) => {
                console.log('Error: ' + JSON.stringify(error));
            });
/*
            PushNotifications.addListener(
                'pushNotificationReceived',
                async (notification: PushNotification) => {
                    console.log('Push received: ' + JSON.stringify(notification));
                }
            );*/
/*
            PushNotifications.addListener(
                'pushNotificationActionPerformed',
                async (notification: PushNotificationActionPerformed) => {
                    const data = notification.notification.data;
                    console.log('Action performed: ' + JSON.stringify(notification.notification));
                    if (data.detailsId) {
                        this.router.navigateByUrl(`/home/${data.detailsId}`);
                    }
                }
            );*/
        }
    }, []);

    useEffect(() => {
        setLocationClass(history.location.pathname.toString().replace(/\//g, ' '));
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }, []);

    function handleClose() {
        if (updatepopup && !updatepopup.force) {
            setShow(false);
        }
    }

    return (
        <div className={"zammadoo-container " + platformClass + locationClass}>
            <Router history={history}>
                <Navi />
                {alert.message &&
                <div className={`alert ${alert.type}`} dangerouslySetInnerHTML={{__html: alert.message}}/>
                }
                <div className={"main-frame" + (user ? '' : ' not-logged-in')}>
                    <Switch>
                        <PrivateRoute exact path="/" component={HomePage} />
                        <PrivateRoute exact path="/matches" component={Matches} />
                        <PrivateRoute exact path="/profile" component={Profile} />
                        <PrivateRoute exact path="/profile/:profileId" component={Profile} />
                        <PrivateRoute exact path="/editProfile" component={EditProfile} />
                        <PrivateRoute exact path="/searches" component={Searches} />
                        <PrivateRoute exact path="/chat" component={Chat} />
                        <PrivateRoute exact path="/chat/:conversationIdentifier" component={ChatConversation} />
                        <PrivateRoute exact path="/deleteme" component={RemoveAccount} />
                        <PrivateRoute exact path="/verify" component={VerifyAccount} />
                        <PrivateRoute exact path="/denounce/:userId" component={UserDenounce} />
                        <PrivateRoute exact path="/searchagent" component={Searchagent} />
                        <PrivateRoute exact path="/searchagentform" component={SearchesType3} />
                        <Route path="/login/:dblOptIn" component={LoginPage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/loginresendconfirmation" component={ResendConfirmationLink} />
                        <Route path="/register" component={RegisterPage} />
                        <Route path="/pwdfgtn" component={PasswordForgotten} />
                        <Route path="/pwdrst/:hash" component={PasswordReset} />
                        <Redirect from="*" to="/" />
                    </Switch>
                </div>
                <Modal show={show} onHide={handleClose} style={{marginTop:'5rem'}}>
                    <Modal.Body className="text-center">
                        <p></p>
                        <p>{updatepopup.text}</p>
                        <p></p>
                        {'ios' == platformClass && <a className="btn btn-primary rounded-pill text-white" target="_system" href="itms-apps://apps.apple.com/us/app/zammadoo/id1123654354">Jetzt aktualisieren</a> }
                        {'android' == platformClass && <a className="btn btn-primary rounded-pill text-white" target="_system" href="https://play.google.com/store/apps/details?id=com.zammadoo.app3">Jetzt aktualisieren</a> }
                        <p></p>
                    </Modal.Body>
                </Modal>
                <SettingsProfile />
                <NaviFooter />
            </Router>
        </div>
    );
}

export { App };
