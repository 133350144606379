import { ENTRYPOINT } from '../config/entrypoint';
import { authHeader, extractHubURL } from '../_helpers';
import {userService} from "./user.service";

const MIME_TYPE = 'application/ld+json';

export const chatService = {
    subscribeMercure,
    getConversations,
    openConversation,
    getConversation,
    deleteConversation,
    postMessage,
    getNewMessages,
    postImtyping
};

function subscribeMercure() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${ENTRYPOINT}/api/chat/subscr`, requestOptions).then(
    response => {
      return {hubUrl: extractHubURL(response), apiUrl: ENTRYPOINT};
    }
  );
}

function getConversations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/api/chat/conversations`, requestOptions).then(handleResponse);
}

function getConversation(identifier) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/api/chat/${identifier}`, requestOptions).then(handleResponse);
}

function deleteConversation(identifier) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/api/chat/conversations/${identifier}`, requestOptions).then(handleResponse);
}

function openConversation(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
        body: JSON.stringify(data)
    };

    return fetch(`${ENTRYPOINT}/api/chat`, requestOptions).then(handleResponse);
}

function getChat() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/api/chat`, requestOptions).then(handleResponse);
}

function getNewMessages(identifier) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/api/chat/newMessages/${identifier}`, requestOptions).then(handleResponse);
}

function postMessage(postdata) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
    body: JSON.stringify(postdata)
  };

  return fetch(`${ENTRYPOINT}/api/chat/${postdata.conversationIdentifier}`, requestOptions).then(handleResponse);
}

function postImtyping(typingdata) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
    body: JSON.stringify(typingdata)
  };

  return fetch(`${ENTRYPOINT}/api/chat/iamtyping`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
