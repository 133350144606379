import React, { useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { userActions } from '../_actions';
import {Link} from "react-router-dom";

function PasswordReset({ match }) {
    const [inputs, setInputs] = useState({
        hash: '',
        password: '',
        confirmPassword: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const { password, confirmPassword } = inputs;
    const { hash } = match.params;
    const user = useSelector(state => state.users);
    const dispatch = useDispatch();

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (password && password === confirmPassword) {
            dispatch(userActions.passwordReset(hash, password, confirmPassword));
        }
    }

    return (
        <div className="px-2 d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
            <div className="col-lg-8 offset-lg-2">
                <div className="text-center mx-sm-5 px-sm-5 mt-md-5 mb-5"><img src="/svg/zammadoo__z-primary.svg" style={{maxWidth:'280px'}} /></div>
                <form name="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input type="password" placeholder="Passwort" name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
                        {submitted && !password &&
                        <div className="invalid-feedback">Passwort ist pflicht</div>
                        }
                    </div>
                    <div className="form-group">
                        <input type="password" placeholder="Passwort bestätigen" name="confirmPassword" value={confirmPassword} onChange={handleChange} className={'form-control' + (submitted && !confirmPassword ? ' is-invalid' : '')} />
                        {submitted && !confirmPassword &&
                        <div className="invalid-feedback">Passwort bestätigen ist pflicht</div>
                        }
                    </div>
                    <div className="form-group row">
                        <div className="col-1 col-sm-3"></div>
                        <div className="col-10 col-sm-6">
                            <button className="btn btn-primary rounded-pill mb-3 w-100 text-white">
                                {user.loading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Passwort ändern
                            </button><br/>
                            <Link to="/login" className="btn btn-secondary rounded-pill w-100">Login</Link>
                        <div className="col-1 col-sm-3"></div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="text-center w-100 align-self-end">
                <a href="https://www.zammadoo.com/impressum.html" className="text-secondary mt-3">© Zammadoo UG</a>
            </div>
        </div>
    );
}

export { PasswordReset };
