import { geolocationConstants } from '../_constants';

export function geolocation(state = {}, action) {
  switch (action.type) {
    case geolocationConstants.GETGEOLOCATIONDATA_REQUEST:
      return {
        loading: true
      };
    case geolocationConstants.GETGEOLOCATIONDATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case geolocationConstants.GETGEOLOCATIONDATA_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
