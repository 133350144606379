export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    SMS_SUCCESS: 'SMS_LOGIN_SUCCESS',

    LOGINRESEND_REQUEST: 'USERS_LOGINRESEND_REQUEST',
    LOGINRESEND_SUCCESS: 'USERS_LOGINRESEND_SUCCESS',
    LOGINRESEND_FAILURE: 'USERS_LOGINRESEND_FAILURE',

    PWDFGT_REQUEST: 'USERS_PWDFGT_REQUEST',
    PWDFGT_SUCCESS: 'USERS_PWDFGT_SUCCESS',
    PWDFGT_FAILURE: 'USERS_PWDFGT_FAILURE',

    PWDRST_REQUEST: 'USERS_PWDRST_REQUEST',
    PWDRST_SUCCESS: 'USERS_PWDRST_SUCCESS',
    PWDRST_FAILURE: 'USERS_PWDRST_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    GETPROFILE_REQUEST: 'USERS_GETPROFILE_REQUEST',
    GETPROFILE_SUCCESS: 'USERS_GETPROFILE_SUCCESS',
    GETPROFILE_FAILURE: 'USERS_GETPROFILE_FAILURE',

    UPDATEPROFILE_REQUEST: 'USERS_UPDATEPROFILE_REQUEST',
    UPDATEPROFILE_SUCCESS: 'USERS_UPDATEPROFILE_SUCCESS',
    UPDATEPROFILE_FAILURE: 'USERS_UPDATEPROFILE_FAILURE',

    GETSEARCH_REQUEST: 'USERS_GETSEARCH_REQUEST',
    GETSEARCH_SUCCESS: 'USERS_GETSEARCH_SUCCESS',
    GETSEARCH_FAILURE: 'USERS_GETSEARCH_FAILURE',

    POSTSEARCH_REQUEST: 'USERS_POSTSEARCH_REQUEST',
    POSTSEARCH_SUCCESS: 'USERS_POSTSEARCH_SUCCESS',
    POSTSEARCH_FAILURE: 'USERS_POSTSEARCH_FAILURE',

    DELETESEARCH_REQUEST: 'USERS_DELETESEARCH_REQUEST',
    DELETESEARCH_SUCCESS: 'USERS_DELETESEARCH_SUCCESS',
    DELETESEARCH_FAILURE: 'USERS_DELETESEARCH_FAILURE',

    GETFEEDS_REQUEST: 'USERS_GETFEEDS_REQUEST',
    GETFEEDS_SUCCESS: 'USERS_GETFEEDS_SUCCESS',
    GETFEEDS_FAILURE: 'USERS_GETFEEDS_FAILURE',

    GETSEARCHPARAMS_REQUEST: 'USERS_GETSEARCHPARAMS_REQUEST',
    GETSEARCHPARAMS_SUCCESS: 'USERS_GETSEARCHPARAMS_SUCCESS',
    GETSEARCHPARAMS_FAILURE: 'USERS_GETSEARCHPARAMS_FAILURE',

    GETSEARCHAGENTS_REQUEST: 'USERS_GETSEARCHAGENTS_REQUEST',
    GETSEARCHAGENTS_SUCCESS: 'USERS_GETSEARCHAGENTS_SUCCESS',
    GETSEARCHAGENTS_FAILURE: 'USERS_GETSEARCHAGENTS_FAILURE',

    POSTSEARCHAGENTS_REQUEST: 'USERS_POSTSEARCHAGENTS_REQUEST',
    POSTSEARCHAGENTS_SUCCESS: 'USERS_POSTSEARCHAGENTS_SUCCESS',
    POSTSEARCHAGENTS_FAILURE: 'USERS_POSTSEARCHAGENTS_FAILURE',

    HANDLE_LOCAL_CONTEXT: 'USERS_HANDLE_LOCAL_CONTEXT',
    HANDLE_HIGHLIGHTERS: 'USERS_HANDLE_HIGHLIGHTERS',
    HANDLE_APP: 'USERS_HANDLE_APP',

    POSTIMAGE_REQUEST: 'USERS_POSTIMAGE_REQUEST',
    POSTIMAGE_SUCCESS: 'USERS_POSTIMAGE_SUCCESS',
    POSTIMAGE_FAILURE: 'USERS_POSTIMAGE_FAILURE',
};
