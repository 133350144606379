import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {alertActions, userActions} from '../_actions';
import {Modal} from "react-bootstrap";

function RemoveAccount() {
    const localContext = useSelector(state => state.context.local);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
    }, []);

    function handleClose() {
        setShow(false);
    }

    function handleDeleteAccount() {
        setShow(false);
        dispatch(userActions.deleteAccount());
    }

    return (
        <>
        <div className="bg-primary" style={{minHeight:'100vh'}}>
            <div className="first-block text-center pt-3 px-3 px-sm-5">
                <img src="/svg/Icon_DeleteAccount.svg" className="w-25 pb-2 pt-2 pt-sm-4" />
            </div>
            <div className="first-block px-3 px-sm-5">
                <h5 className="text-white font-italic">Du möchtest Zammadoo verlassen?</h5>
                <p className="text-white">Das ist natürlich sehr schade!</p>
                <p className="text-white">Hast Du noch nicht gefunden, was Du suchst? Wir arbeiten intensiv daran Zammadoo bekannt zu machen, damit verabreden und aktiv sein noch einfacher wird. Die App lebt davon, dass User Suchen anlegen und gefunden werden. Gibst Du Zammadoo noch eine Chance?</p>
            </div>
            <div className="first-block text-center px-3 px-sm-5">
                <a className="btn bg-white rounded-pill w-100" href="/searches">Neue Suche anlegen</a><br/><br/>
                <a className="text-white" onClick={() => setShow(true)}><u>Nein, ich möchte meinen <br/>Account jetzt löschen</u></a><br/><br/>
                <a className="text-white" href="/">Abbrechen</a><br/><br/>
            </div>
        </div>
        <Modal show={show} onHide={handleClose} style={{marginTop:'5rem'}}>
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">Account löschen</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                Wenn Du Deinen Zammadoo-Account jetzt löschst, werden alle gespeicherten Profildaten, Matches und Chatdialoge unwiederbringlich mitgelöscht.<br/>
                Um Zammadoo danach wieder zu nutzen, musst Du ein neues Profil anlegen.
            </Modal.Body>
            <Modal.Footer className="text-center justify-content-center">
                <button className="btn btn-primary text-white rounded-pill w-75" onClick={() => handleDeleteAccount()}>Jetz löschen</button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export { RemoveAccount };
