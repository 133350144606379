import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {appointmentActions, chatActions, userActions} from "../_actions";
import {chatService} from "../_services";
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Link} from "react-router-dom";
import styles from '../utils/chatStyles.css';
import {MediaObject} from "../_components";
import Moment from 'react-moment';
import {Modal} from "react-bootstrap";
import {IonModal} from "@ionic/react";
import {Capacitor} from "@capacitor/core";

function ChatConversation({ match }) {
    const today = new Date();
    const weekdays = ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'];
    const localContext = useSelector(state => state.context.local);
    const storage = useSelector(state => state.context.storage);
    const dispatch = useDispatch();
    let dispatching = false;
    let  imtyping = false;
    const [eventSource, setEventSource] = useState(null);
    const [buddyIsTyping, setBuddyIsTyping] = useState(false);
    const user = useSelector(state => state.authentication.user);
    const conversation = useSelector(state => state.chat.conversation);
    const { conversationIdentifier } = match.params;
    const messagesEndRef = useRef(null)
    const bubbleClasses = {
        me: 'float-right',
        you: 'float-left',
        jumbotron: {
            me: 'bg-primary text-white',
            you: 'text-dark'
        },
        tstamp: {
            me: 'text-dark col-12 pr-4 text-right text-light',
            you: 'text-dark col-12'
        }
    };
    const [show, setShow] = useState(false);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    const [platformClass, setPlatformClass] = useState('');
    const searches = useSelector(state => state.searches.matches);
    const appointmentReduced = useSelector(state => state.appointment);
    const [appointmentValidation, setAppointmentValidation] = useState({search:'',hour:'',minute:'',place:''});
    const [appointment, setAppointment] = useState({
        stage: 0,
        searches: []/*,
        stage: 2,
        searches: [],
        you: 'Alguien',
        activity: 'Tennis',
        weekday: 'Freitag',
        day: '14',
        month: '05',
        time: '20:00',
        place: 'Tennispark Tito'*/
    });
    const [showInvitation, setShowInvitation] = useState(false);

    useEffect(() => {
        setPlatformClass(Capacitor.platform);
        dispatch(userActions.getSearches());
        dispatch(appointmentActions.getAppointment(conversationIdentifier));
    }, []);

    useEffect(() => {
        if (conversation && searches && 2 > appointment.stage) {
            searches.map((match) =>
            {
                match.searchMatches.map((search) => {
                    if (search.profile.id === conversation.meta.you.profile.id) {
                        let searchKey = match.searchDetail.id;
                        let searchVal = match.searchDetail;
                        if (2 === match.searchDetail.type) {
                            searchKey = search.id;
                            searchVal = search;
                        }
                        setAppointment(appointment => ({
                            ...appointment,
                            stage:1,
                            conversationIdentifier: conversationIdentifier,
                            me:user.profile.firstname,
                            you: conversation.meta.you.name,
                            requestor:user.id,
                            requested: conversation.meta.you.id,
                            gender:search.profile.gender,
                            searches: {...appointment.searches, ['_' + searchKey]:searchVal},
                            search: match.searchDetail.id,
                            hour:'',
                            minute:'',
                            place:''
                        }));
                        dispatch(appointmentActions.getAppointment(conversationIdentifier));
                    }
                });
                if(Object.entries(appointment.searches).length > 1) {
                    setAppointment(appointment => ({...appointment, search: ''}))
                }
            });
        }
    }, [searches, conversation]);

    useEffect(() => {
        if(appointmentReduced.invitation && appointmentReduced.invitation.requested === user.id && appointmentReduced.invitation.requestor == conversation.meta.you.id) {
            let dateObj = new Date(appointmentReduced.invitation.timepoint.substring(0,19));
            setAppointment(appointment => ({
                ...appointment,
                id:appointmentReduced.invitation.id,
                stage:appointmentReduced.invitation.stage,
                activity: appointmentReduced.invitation.activity,
                place:appointmentReduced.invitation.place,
                search: appointmentReduced.invitation.search,
                weekdayToReviewDueGMT: weekdays[dateObj.getDay()],
                monthToReviewDueGMT: (dateObj.getMonth()+1 < 10 ? '0': '') + (dateObj.getMonth()+1),
                dayToReviewDueGMT: dateObj.getDate(),
                timeToReviewDueGMT: dateObj.getHours() + ':' + dateObj.getMinutes(),
                weekday: weekdays[appointmentReduced.invitation.weekday],
                month: appointmentReduced.invitation.month,
                day: appointmentReduced.invitation.day,
                time: appointmentReduced.invitation.time,
                timepoint: appointmentReduced.invitation.timepoint
            }));
        }
    }, [appointmentReduced]);

    useEffect(() => {
        if ( conversation ) {
            let localContextUpdated = {};
            if (localContext) {
                localContextUpdated = localContext;
            }
            localContextUpdated = {
                ...localContextUpdated,
                showProfile: false,
                showSettings: false,
                title: <div><Link to="/chat" className="btn btn-link float-left"><ArrowBackIcon /></Link><h5 className="pt-3" style={{paddingRight:'3rem'}}><span onClick={() => openProfile(conversation.meta.you.profile.id)}>{conversation.meta.you.profile.profileimage && <MediaObject alt={conversation.meta.you.profile.firstname} mediaObjectUrl={conversation.meta.you.profile.profileimage} className="rounded-zammadoo border border-primary" style={{width:'40px',height:'40px'}} />}{!conversation.meta.you.profile.profileimage && <img alt={conversation.meta.you.profile.firstname} src="/profileImagePlaceholder.jpg" className="rounded-zammadoo border border-primary" style={{width:'40px',height:'40px'}} />}</span> {conversation.meta.you.name}</h5></div>
            }
            dispatch(userActions.setLocalContext(localContextUpdated));
        }
    }, [conversation]);

    useEffect(() => {
        let storageUpdated = {};
        if (storage) {
            storageUpdated = storage;
        }
        storageUpdated.chatLed = false;
        dispatch(userActions.setStorage(storageUpdated));
    }, [storage]);

    useEffect(scrollToBottom, [conversation]);

    useEffect(() => {
        if (! conversation ) {
            dispatch(chatActions.getConversation(conversationIdentifier));
        }
    }, []);

    useEffect(() => {
        chatService.subscribeMercure().then(hubConfig => {
            hubConfig.hubUrl.searchParams.append('topic', 'zammadoo/api/chat/' + conversationIdentifier);
            setEventSource( new EventSource(hubConfig.hubUrl.toString()) );
        });
        return () => {
            if (eventSource) {
                eventSource.close();
            }
            setEventSource( null );
        };
    }, []);

    useEffect(() => {
        if (eventSource) {
            eventSource.onmessage = e => {
                const payload = JSON.parse(e.data);
                if (payload.newMessage) {
                    if ( ! dispatching ) {
                        dispatching = true;
                        dispatch(chatActions.getNewMessages(conversationIdentifier));
                        setTimeout(() => { dispatching = false; }, 500);
                    }
                }
                if (payload.isTyping) {
                    if (user.id !== payload.buddy && !buddyIsTyping) {
                        setBuddyIsTyping(true);
                        setTimeout(() => { setBuddyIsTyping(false) }, 2000);
                    }
                }
                if (payload.appointment) {
                    if ( ! dispatching ) {
                        dispatching = true;
                        dispatch(appointmentActions.getAppointment(conversationIdentifier));
                        dispatch(chatActions.getNewMessages(conversationIdentifier));
                        setTimeout(() => { dispatching = false; }, 500);
                    }
                }
            };
        }
    }, [eventSource]);


    function openProfile(id) {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: true,
            showProfileId: id,
            showSettings: false,
            title: <div><Link to="/chat" className="btn btn-link float-left"><ArrowBackIcon /></Link><h3 className="pt-2" style={{paddingRight:'3rem'}}><span onClick={() => openProfile(conversation.meta.you.profile.id)}>{conversation.meta.you.profile.profileimage && <MediaObject alt={conversation.meta.you.profile.firstname} mediaObjectUrl={conversation.meta.you.profile.profileimage} className="rounded-zammadoo border border-primary" style={{width:'40px',height:'40px'}} />}{!conversation.meta.you.profile.profileimage && <img alt={conversation.meta.you.profile.firstname} src="/profileImagePlaceholder.jpg" className="rounded-zammadoo border border-primary" style={{width:'40px',height:'40px'}} />}</span> {conversation.meta.you.name}</h3></div>
        }
        dispatch(userActions.setLocalContext(localContextUpdated));

        setTimeout(() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }, 500);
    }

    function handleChange(e) {
        if (!imtyping) {
            imtyping = true;
            chatService.postImtyping({conversation:conversationIdentifier});
            setTimeout(() => { imtyping = false; }, 2000);
        }
    }

    function sendMessage(e) {
        e.preventDefault();

        let txtMsg = document.getElementById('txtMsg').value;
        imtyping = false;
        setBuddyIsTyping(false);
        if (txtMsg) {
            dispatch(chatActions.postMessage({conversationIdentifier:conversationIdentifier,txtMsg:txtMsg}));
        }
        document.getElementById('txtMsg').value = '';
    }

    function handleChangeAppointment(e) {
        const { name, value } = e.target;
        setAppointment(appoinment => ({ ...appoinment, [name]: value }));
    }

    function handleAppointmentInvitation(decision) {
        let putdata = {};
        putdata.id = appointment.id;
        putdata.accepted = decision;
        putdata.rejected = !decision;
        putdata.conversationIdentifier = conversationIdentifier;
        putdata.system = 'appointment';
        putdata.txtMsg = 'Verabredung angenommen';
        putdata.sysheader = '';
        putdata.sysmessage = String(appointment.activity).toUpperCase() + ' am ' + appointment.weekday + ', '
        + appointment.day + '.' + appointment.month + '. um ' + appointment.time + '\nTreffpunkt: ' + appointment.place ;
        setAppointment(appointment => ({...appointment, stage: 0}));
        dispatch(appointmentActions.updateAppointment(putdata));
        if (decision) {
            dispatch(chatActions.postMessage(putdata));
        }
        console.log(appointment);
    }

    function handleSubmitAppointment(e) {
        //validate
        let isValid = true;
        if (!appointment.search) {
            setAppointmentValidation(appoinmentValidation => ({ ...appoinmentValidation, search: 'was-validated' }));
            isValid = false;
        }
        if (!/^[0-9]+$/.test(appointment.hour) || 23 < parseInt(appointment.hour)) {
            setAppointmentValidation(appoinmentValidation => ({ ...appoinmentValidation, hour: 'is-invalid' }));
            isValid = false;
        }
        if (!/^[0-9]+$/.test(appointment.minute) || 59 < parseInt(appointment.minute)) {
            setAppointmentValidation(appoinmentValidation => ({ ...appoinmentValidation, minute: 'is-invalid' }));
            isValid = false;
        }
        if (isValid && 0 === appointment.searches['_'+appointment.search].remaining.days) {
            const tsnow = new Date();
            tsnow.setHours(appointment.hour);
            tsnow.setMinutes(appointment.minute);
            if (new Date() > tsnow) {
                setAppointmentValidation(appoinmentValidation => ({ ...appoinmentValidation, hour: 'is-invalid', minute: 'is-invalid', past: 'Termin liegt in der Vergangenheit' }));
                isValid = false;
            }
        }
        if (!isValid) {
            return false;
        }
        dispatch(appointmentActions.postAppointment(
            {
                conversationIdentifier: appointment.conversationIdentifier,
                requested: appointment.requested,
                search: appointment.search,
                place: appointment.place,
                timepoint: appointment.searches['_' + appointment.search].fromtime + ' ' + appointment.hour + ':' + appointment.minute + ':00'
            }
        ));
        setShow(false);
    }

    return (
        <div>
            <ul className="messages">
                {conversation && Object.entries(conversation.messages).map(([k, msg], i) => {
                    return <li key={k}>
                        {(!msg.system || 'welcome' === msg.system) &&
                        <div className="row mx-0">
                            <div className={bubbleClasses.tstamp[msg.wroteby]}>
                                <small>
                                    <strong><Moment format="DD.MM.YYYY" local>{msg.timestamp.raw}</Moment></strong>, <Moment format="HH:mm" local>{msg.timestamp.raw}</Moment> Uhr</small></div>
                            <div className="col">
                                <div className={bubbleClasses[msg.wroteby]}>
                                    <div className="arrow" style={{top:'1px'}}></div>
                                    <div className={'jumbotron mb-3 pt-2 pb-2 ' + bubbleClasses.jumbotron[msg.wroteby]}>
                                        {'Welcome to Zammadoo. Here we are if you need any help.'!==msg.textmessage && msg.textmessage}
                                        {'Welcome to Zammadoo. Here we are if you need any help.'===msg.textmessage &&
                                        <>
                                            Liebe(r) {user.profile.firstname},<br/>
                                            herzlich willkommen bei Zammadoo! Wir möchten Dir helfen, den richtigen
                                            Partner für Deine Aktivitäten zu finden. Du kannst dafür eine neue Suche
                                            anlegen <Link to="/searches" className="btn btn-circle">
                                            <img className="zammadoo-icon" src="/svg/Suche-secondary.svg" alt="Zu die Suche" /></Link> oder
                                            im Newsfeed <Link to="/" className="btn btn-circle">
                                            <img className="zammadoo-icon" src="/svg/zammadoo__z-outline-secondary-large.svg" alt="Zu Newsfeed" /></Link> nach
                                            passenden Kontakten suchen. Um Dich per Chat verabreden zu können, müssen Du
                                            und Dein Kontakt gegenseitig euer Interesse über das <img width="30" height="30" className="zammadoo-icon" src="/svg/Herz_grau_Herz_grau.svg" alt="" /> in
                                            der Trefferliste signalisieren. Falls Du noch Aktivitäten in der Suchauswahl
                                            vermisst oder uns Feedback senden willst, nutze einfach diesen Chat. Und
                                            jetzt viel Spass! Dein Zammadoo Team.
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {'appointment' === msg.system &&
                        <div className="card">
                            <div className="card-header bg-secondary text-white">
                                Verabredung mit {conversation.meta.you.name}
                            </div>
                            <div className="card-body">
                                <p className="card-text text-center p-3 mb-2">{msg.sysmessage.split('\n').map(function(item, key) {
                                    return (
                                        <span key={key}>{item}<br/></span>
                                    )
                                })}</p>
                            </div>
                        </div>
                        }
                    </li>
                })}
                {conversation && Object.entries(conversation.newMessages).map(([k, msg], i) => {
                    return <li key={k}>
                        {!msg.system &&
                        <div className="row mx-0">
                            <div className={bubbleClasses.tstamp[msg.wroteby]}>
                                <small><strong>{msg.timestamp.formatedDate}</strong>, <Moment format="HH:mm"
                                                                                              local>{msg.timestamp.raw}</Moment> Uhr</small>
                            </div>
                            <div className="col">
                                <div className={bubbleClasses[msg.wroteby]}>
                                    <div className="arrow" style={{top: '1px'}}></div>
                                    <div
                                        className={'jumbotron mb-3 pt-2 pb-2 ' + bubbleClasses.jumbotron[msg.wroteby]}>{msg.textmessage}</div>
                                </div>
                            </div>
                        </div>
                        }
                        {'appointment' === msg.system &&
                        <div className="card">
                            <div className="card-header bg-secondary text-white">
                                Verabredung mit {conversation.meta.you.name}
                            </div>
                            <div className="card-body">
                                <p className="card-text text-center p-3 mb-2">{msg.sysmessage.split('\n').map(function(item, key) {
                                    return (
                                        <span key={key}>{item}<br/></span>
                                    )
                                })}</p>
                            </div>
                        </div>
                        }
                    </li>
                })}
            </ul>
            {buddyIsTyping && <div><img align="typing..." src="/typing-animation-3x.gif" height="32" /></div>}
            <div style={{height:'144px'}} ref={messagesEndRef}></div>
            {(!appointmentReduced.invitation || appointmentReduced.invitation.rejected) &&
             !appointmentReduced.loading &&
             !appointmentReduced.error && 1 === appointment.stage && false &&/*HIDE VERABREDUNG BUTTON UNTIL NEXT SPRINT*/
            <div className="position-fixed" style={{bottom:'23vh'}}>
                <button className="btn pr-4" onClick={() => setShow(true)}>
                    <img className="border border-secondary p-2 bg-secondary rounded-circle" src="/svg/addpic.svg" style={{width:'60px', height:'60px'}} />
                </button>
            </div>
            }
            {((appointmentReduced.invitation && false === appointmentReduced.invitation.rejected) || appointmentReduced.loading || appointmentReduced.error) &&
            1 === appointment.stage && false &&/*HIDE VERABREDUNG BUTTON UNTIL NEXT SPRINT*/
            <div className="position-fixed" style={{bottom:'20vh'}}>
                <button className="btn pr-4">
                    <img className="border border-gray p-2 bg-gray rounded-circle" src="/svg/addpic.svg" style={{width:'60px', height:'60px'}} />
                </button>
            </div>
            }
            <nav className="navbar fixed-bottom navbar-light bg-light">
                <form className="w-100 pt-1 pb-5">
                    <div className="input-group mb-3">
                        <input autoComplete="off" type="text" className="form-control" placeholder="Nachricht eingeben"
                               aria-label="Recipient's username" aria-describedby="basic-addon2"
                               id="txtMsg"  onChange={handleChange} />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary pt-0" onClick={sendMessage}>
                                    <SendIcon fontSize="small" style={{color: '#dc9131',cursor: 'pointer'}} />
                                </button>
                            </div>
                    </div>
                </form>
            </nav>
            <IonModal isOpen={show} cssClass={'zammadoo-modal-frombottom zammadoo-'+platformClass}>
                <div className="modal-header border-bottom-0">
                    <h5 className="text-uppercase">Einladung Senden</h5>
                    <button type="button" className="close" onClick={() => setShow(false)}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                {0 < appointment.stage &&
                <Modal.Body className="activity-accordion">
                    <div className="settings d-flex flex-column justify-content-between" style={{minHeight:'87vh'}}>
                        <div className="text-center">
                            <p>{appointment.me}, Du möchtest Dich mit {appointment.you} verabreden?</p>
                            <p>Sende {1===appointment.gender && <>ihm</>}{2===appointment.gender && <>ihr</>} gleich eine Einladung, um einen Termin zu vereinbaren für:</p>
                            {Object.entries(appointment.searches).map(([k, item], i) =>
                            <div key={k} className={'form-group ' + appointmentValidation.search}>
                                <div className="form-check">
                                    {1 === Object.entries(appointment.searches).length &&
                                    <input id={'activity-' + i} type="radio" name="search" value={item.id} onChange={handleChangeAppointment} className="form-check-input" required={true} checked={true} />
                                    }
                                    {1 < Object.entries(appointment.searches).length &&
                                    <input id={'activity-' + i} type="radio" name="search" value={item.id} onChange={handleChangeAppointment} className="form-check-input" required={true} />
                                    }
                                    <label htmlFor={'activity-' + i} className="form-check-label pl-2">
                                        <span className="text-uppercase">{item.activity}</span> am {
                                        weekdays[today.getDay() + item.remaining.days]
                                    }, {item.fromtime.substring(0,6)}
                                    </label>
                                </div>
                            </div>
                            )}
                            <p>Schlage {1===appointment.gender && <>ihn</>}{2===appointment.gender && <>ihr</>} eine Uhrzeit vor:</p>
                            <div className="form-group">
                                <input value={appointment.hour} onChange={handleChangeAppointment} type="text" maxLength="2" tabIndex="1" placeholder="HH" name="hour" className={'w-25 form-control custom-control-inline ' + appointmentValidation.hour} required={true} />&nbsp;:&nbsp;
                                <input value={appointment.minute} onChange={handleChangeAppointment} type="text" maxLength="2" tabIndex="2" placeholder="MM" name="minute" className={'w-25 form-control custom-control-inline ' + appointmentValidation.minute} required={true} />
                            </div>
                            {appointmentValidation.past && <p className="text-danger">{appointmentValidation.past}</p>}
                            <p>Schlage {1===appointment.gender && <>ihn</>}{2===appointment.gender && <>ihr</>} einen Treffpunkt vor:</p>
                            <div className="form-group">
                                <input value={appointment.place} onChange={handleChangeAppointment} type="text" placeholder="Treffpunkt" name="place" className="custom-control form-control" />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary text-white rounded-pill w-50" onClick={handleSubmitAppointment}>Senden</button>
                            </div>
                        </div>
                        <div className="align-self-end text-center w-100 pb-4">
                        </div>
                    </div>
                </Modal.Body>
                }
            </IonModal>
            {2 === appointment.stage &&
            <div className="position-fixed w-100 px-3" style={{top:0, height:'100vh', zIndex:1030, backgroundColor:'rgba(0,0,0,0.85)'}}>
                <div className="position-relative h-100">
                    <div className="modal-content position-absolute" style={{bottom:'15rem', borderRadius:'0.5rem'}}>
                        <div className="modal-header bg-secondary">
                            <div className="w-100 modal-title h4 text-white">Einladung von {appointment.you}</div>
                        </div>
                        <div className="modal-body text-center">{appointment.activity} am {appointment.weekday}, {appointment.day}.{appointment.month}. um {appointment.time}<br/>
                        Treffpunkt: {appointment.place}</div>
                        <div className="text-center justify-content-center modal-footer">
                            <div className="row w-100">
                                <div className="col">
                                    <button onClick={() =>handleAppointmentInvitation(false)} className="btn border-dark rounded-pill w-100">Ablehnen</button>
                                </div>
                                <div className="col">
                                    <button onClick={() =>handleAppointmentInvitation(true)} className="btn btn-primary text-white rounded-pill w-100">Annehmen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>

    );
}

export { ChatConversation };

