import { userConstants } from '../_constants';

export function searchagent(state = {}, action) {
  switch (action.type) {
    case userConstants.GETSEARCHAGENTS_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETSEARCHAGENTS_SUCCESS:
      return {
        items: action.items
      };
    case userConstants.GETSEARCHAGENTS_FAILURE:
      return {
        error: action.error
      };
    case userConstants.POSTSEARCHAGENTS_REQUEST:
      return {
        ...state,
        searching: true
      };
    case userConstants.POSTSEARCHAGENTS_SUCCESS:
      return {
        items: action.items
      };
    case userConstants.POSTSEARCHAGENTS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
