import { locationConstants } from '../_constants';
import { locationService } from '../_services';

export const locationActions = {
    getLocations
};

function getLocations(params) {
    return dispatch => {
        dispatch(request());

      locationService.getLocations(params)
            .then(
                location => dispatch(success(location)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: locationConstants.GETLOCATIONS_REQUEST } }
    function success(location) { return { type: locationConstants.GETLOCATIONS_SUCCESS, location } }
    function failure(error) { return { type: locationConstants.GETLOCATIONS_FAILURE, error } }
}
