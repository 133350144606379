import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    login2,
    passwordForgotten,
    resendConfirmationLink,
    resendConfirmationSMS,
    passwordReset,
    confirm,
    confirmSms,
    logout,
    register,
    getAll,
    getProfile,
    updateProfile,
    updateProfileImage,
    deleteImage,
    getSearches,
    getSearchparams,
    getSearchagents,
    updateSearchparams,
    postSearch,
    pushSearch,
    postImage,
    getFeeds,
    setAppContext,
    setLocalContext,
    setStorage,
    registerApp,
    deleteAccount,
    delete: _delete
};

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));

    userService.login(username, password)
      .then(
        user => {
          dispatch(success(user));
          history.push('/');
        },
        error => {
          let errorString = error.toString();
          dispatch(failure(error.toString()));
          if ('Account is locked.' === errorString) errorString = 'Account inaktiv. Bitte aktiviere Deinen Account über den Bestätigungslink, den Du per E-Mail erhalten hast. <a href="/loginresendconfirmation">Link erneut senden</a>';
          if ('Invalid credentials.' === errorString) errorString = 'Die eingegebenen Anmeldedaten sind ungültig.';
          dispatch(alertActions.error(errorString));
        }
      );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function login2(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
//                    history.push('/');
                },
                error => {
                    let errorString = error.toString();
                    dispatch(failure(error.toString()));
                    if ('Account is locked.' === errorString) errorString = 'Account inaktiv. Bitte aktiviere Deinen Account über den Bestätigungslink, den Du per E-Mail erhalten hast. <a href="/loginresendconfirmation">Link erneut senden</a>';
                    if ('Invalid credentials.' === errorString) errorString = 'Die eingegebenen Anmeldedaten sind ungültig.';
                    dispatch(alertActions.error(errorString));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function confirm(dblOptIn) {
    return dispatch => {
        userService.confirm(dblOptIn)
            .then(
                data => {
                    dispatch(alertActions.success(data.success));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function confirmSms(smsdata) {
    return dispatch => {
      dispatch(request({ smsdata }));
      userService.confirmSms(smsdata)
          .then(
              data => {
                  dispatch(success(data));
              },
              error => {
                  dispatch(failure(error.toString()));
              }
          );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(data) { return { type: userConstants.SMS_SUCCESS, data } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function passwordForgotten(username) {
    return dispatch => {
        userService.passwordForgotten(username)
            .then(
                data => {
                    dispatch(alertActions.success(data.success));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.PWDFGT_REQUEST, user } }
    function success(user) { return { type: userConstants.PWDFGT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.PWDFGT_FAILURE, error } }
}

function resendConfirmationLink(username) {
    return dispatch => {
        userService.resendConfirmationLink(username)
            .then(
                data => {
                    dispatch(alertActions.success(data.success));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGINRESEND_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGINRESEND_REQUEST, user } }
    function failure(error) { return { type: userConstants.LOGINRESEND_REQUEST, error } }
}

function resendConfirmationSMS(userdata) {
    return dispatch => {
      dispatch(request());
        userService.resendConfirmationSMS(userdata)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.LOGINRESEND_REQUEST } }
    function success() { return { type: userConstants.LOGINRESEND_REQUEST } }
    function failure(error) { return { type: userConstants.LOGINRESEND_REQUEST, error } }
}

function passwordReset(hash, password, confirmPassword) {
    return dispatch => {
        userService.passwordReset(hash, password, confirmPassword)
            .then(
                data => {
                    dispatch(alertActions.success(data.success));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.PWDRST_REQUEST, user } }
    function success(user) { return { type: userConstants.PWDRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.PWDRST_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function getProfile(profileId) {
    return dispatch => {
        dispatch(request());

        userService.getProfile(profileId)
            .then(
                profile => dispatch(success(profile)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETPROFILE_REQUEST } }
    function success(profile) { return { type: userConstants.GETPROFILE_SUCCESS, profile } }
    function failure(error) { return { type: userConstants.GETPROFILE_FAILURE, error } }
}

function updateProfile(profile) {
  return dispatch => {
    dispatch(request(profile));

    userService.updateProfile(profile)
      .then(
        profile => {
          dispatch(success(profile));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(profile) { return { type: userConstants.UPDATEPROFILE_REQUEST, profile } }
  function success(profile) { return { type: userConstants.UPDATEPROFILE_SUCCESS, profile } }
  function failure(error) { return { type: userConstants.UPDATEPROFILE_FAILURE, error } }
}

function updateProfileImage(image) {
  return dispatch => {
    dispatch(request());

    userService.updateProfileImage(image)
      .then(
        profile => {
          dispatch(success(profile));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: userConstants.UPDATEPROFILE_REQUEST } }
  function success(profile) { return { type: userConstants.UPDATEPROFILE_SUCCESS, profile } }
  function failure(error) { return { type: userConstants.UPDATEPROFILE_FAILURE, error } }
}

function deleteImage(image) {
  return dispatch => {
    dispatch(request());

    userService.deleteImage(image)
      .then(
        profile => {
          dispatch(success(profile));
          if (image.reload) {
            window.location.reload(false);
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: userConstants.UPDATEPROFILE_REQUEST } }
  function success(profile) { return { type: userConstants.UPDATEPROFILE_SUCCESS, profile } }
  function failure(error) { return { type: userConstants.UPDATEPROFILE_FAILURE, error } }
}

function getSearches() {
  return dispatch => {
    dispatch(request());

    userService.getSearches()
      .then(
        searches => dispatch(success(searches)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: userConstants.GETSEARCH_REQUEST } }
  function success(searches) { return { type: userConstants.GETSEARCH_SUCCESS, searches } }
  function failure(error) { return { type: userConstants.GETSEARCH_FAILURE, error } }
}

function getSearchagents() {
  return dispatch => {
    dispatch(request());

    userService.getSearchagents()
      .then(
        items => dispatch(success(items)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: userConstants.GETSEARCHAGENTS_REQUEST } }
  function success(items) { return { type: userConstants.GETSEARCHAGENTS_SUCCESS, items } }
  function failure(error) { return { type: userConstants.GETSEARCHAGENTS_FAILURE, error } }
}

function getSearchparams() {
  return dispatch => {
    dispatch(request());

    userService.getSearchparams()
      .then(
        searchparams => dispatch(success(searchparams)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: userConstants.GETSEARCHPARAMS_REQUEST } }
  function success(searchparams) { return { type: userConstants.GETSEARCHPARAMS_SUCCESS, searchparams } }
  function failure(error) { return { type: userConstants.GETSEARCHPARAMS_FAILURE, error } }
}

function updateSearchparams(searchparams) {
  return dispatch => {
    dispatch(success(searchparams));
  };

  function success(searchparams) { return { type: userConstants.GETSEARCHPARAMS_SUCCESS, searchparams } }
}

function postSearch(search) {
  return dispatch => {
    dispatch(request(search));

    userService.postSearch(search)
      .then(
        searches => {
          dispatch(success(searches));
//          history.push('/matches');
          if (3 === search.type) {
            window.location = '/searchagent';
          } else {
            window.location = '/matches';
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(search) { return { type: userConstants.POSTSEARCH_REQUEST, search } }
  function success(searches) { return { type: userConstants.POSTSEARCH_SUCCESS, searches } }
  function failure(error) { return { type: userConstants.POSTSEARCH_FAILURE, error } }
}

function pushSearch(search) {
  return dispatch => {
    dispatch(request(search));

    userService.pushSearch(search)
      .then(
        searches => {
          if (3 == search.type) {
            dispatch(success2(searches));
          } else {
            dispatch(success(searches));
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(search) { return { type: userConstants.POSTSEARCH_REQUEST, search } }
  function success(searches) { return { type: userConstants.POSTSEARCH_SUCCESS, searches } }
  function success2(items) { return { type: userConstants.GETSEARCHAGENTS_SUCCESS, items } }
  function failure(error) { return { type: userConstants.POSTSEARCH_FAILURE, error } }
}

function getFeeds(coords) {
    return dispatch => {
        dispatch(request());

        userService.getFeeds(coords)
            .then(
                feeds => dispatch(success(feeds)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETFEEDS_REQUEST } }
    function success(feeds) { return { type: userConstants.GETFEEDS_SUCCESS, feeds } }
    function failure(error) { return { type: userConstants.GETFEEDS_FAILURE, error } }
}

function deleteAccount() {
    return dispatch => {

        userService.deleteAccount()
            .then(
                ok => {
                  dispatch(success(ok));
                  history.push('/register');
                },
              error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
              }
            );
    };

    function success(ok) { return { type: userConstants.DELETE_SUCCESS, ok } }
    function failure(error) { return { type: userConstants.DELETE_FAILURE, error } }
}

function registerApp(appContext) {
    return dispatch => {
        userService.registerApp(appContext);
    };
}

function setAppContext(appContext) {
    return dispatch => {
        dispatch(success(appContext));
        localStorage.setItem('appContext', JSON.stringify(appContext));
    };

    function success(appContext) { return { type: userConstants.HANDLE_APP, appContext } }
}

function setStorage(storage) {
    return dispatch => {
        dispatch(success(storage));
        localStorage.setItem('storage', JSON.stringify(storage));
    };

    function success(storage) { return { type: userConstants.HANDLE_HIGHLIGHTERS, storage } }
}

function setLocalContext(localContext) {
    return dispatch => {
        dispatch(success(localContext));
    };

    function success(localContext) { return { type: userConstants.HANDLE_LOCAL_CONTEXT, localContext } }
}

function postImage(imageForm) {
  return dispatch => {
    dispatch(request());

    userService.postImage(imageForm)
      .then(
        result => {
          dispatch(success(result));
          dispatch(userActions.getProfile());
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: userConstants.POSTIMAGE_REQUEST } }
  function success(result) { return { type: userConstants.POSTIMAGE_SUCCESS, result } }
  function failure(error) { return { type: userConstants.POSTIMAGE_FAILURE, error } }
}
