import { appointmentConstants } from '../_constants';
import { appointmentService } from '../_services';
import { alertActions } from './';

export const appointmentActions = {
    postAppointment,
    updateAppointment,
    getAppointment
};

function getAppointment(identifier) {
  return dispatch => {
    dispatch(request());

    appointmentService.getAppointment(identifier)
      .then(
        appointment => {
          dispatch(success(appointment));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: appointmentConstants.GETAPPOINTMENT_REQUEST } }
  function success(appointment) { return { type: appointmentConstants.GETAPPOINTMENT_SUCCESS, appointment } }
  function failure(error) { return { type: appointmentConstants.GETAPPOINTMENT_FAILURE, error } }
}

function postAppointment(postdata) {
  return dispatch => {
    dispatch(request(postdata));

    appointmentService.postAppointment(postdata)
      .then(
        appointment => {
          dispatch(success(appointment));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data) { return { type: appointmentConstants.POSTAPPOINTMENT_REQUEST, data } }
  function success(appointment) { return { type: appointmentConstants.POSTAPPOINTMENT_SUCCESS, appointment } }
  function failure(error) { return { type: appointmentConstants.POSTAPPOINTMENT_FAILURE, error } }
}

function updateAppointment(putdata) {
  return dispatch => {
    dispatch(request(putdata));

    appointmentService.updateAppointment(putdata)
      .then(
        appointment => {
          dispatch(success(appointment));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(putdata) { return { type: appointmentConstants.POSTAPPOINTMENT_REQUEST, putdata } }
  function success(appointment) { return { type: appointmentConstants.POSTAPPOINTMENT_SUCCESS, appointment } }
  function failure(error) { return { type: appointmentConstants.POSTAPPOINTMENT_FAILURE, error } }
}
