import { ENTRYPOINT } from '../config/entrypoint';
import { authHeader } from '../_helpers';
import React, {useEffect, useState} from 'react';

function MediaObject(props) {
    const [myProps, setMyProps] = useState({
        alt: props.alt ? props.alt : '',
        className: props.className ? props.className : '',
        style: props.style ? props.style : {},
        src: 'https://reactnativecode.com/wp-content/uploads/2018/02/Default_Image_Thumbnail.png',
    });

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        fetch(`${ENTRYPOINT}${props.mediaObjectUrl}`, requestOptions)
            .then(response => response.json())
            .then(data => setMyProps(myProps => ({ ...myProps, ['src']: ENTRYPOINT + data.contentUrl })))
            .catch(setMyProps(myProps => ({ ...myProps, ['src']: 'https://developer.android.com/codelabs/kotlin-android-training-internet-images/img/467c213c859e1904.png' })));
    }, []);

    return (
        <img alt={myProps.alt} src={myProps.src} className={myProps.className} style={myProps.style} />
    );
}

export { MediaObject };
