import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {activityActions, alertActions, chatActions, geolocationActions, userActions} from '../_actions';
import {LocationName, MatchDetails, MediaObject} from "../_components";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ENTRYPOINT} from "../config/entrypoint";
import {IonFab, IonFabButton, IonIcon, IonModal} from "@ionic/react";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Capacitor, Plugins} from "@capacitor/core";
import {add} from "ionicons/icons";
import Card from "react-bootstrap/Card";
import Fade from "react-bootstrap/Fade";
import {searchagent} from "../_reducers/searchagent.reducer";
import {history} from "../_helpers";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#f39325'
        },
        secondary: {
            main: '#878787'
        }
    }
});

function Searchagent() {
    const localContext = useSelector(state => state.context.local);
    const searches = useSelector(state => state.searchagent);
    const storage = useSelector(state => state.context.storage);
    const dispatch = useDispatch();
    const weekdays = ['SO','MO','DI','MI','DO','FR','SA'];
    const today = new Date();
    const geolocation = useSelector(state => state.geolocation);
    const [referrer, setReferrer] = useState(null);
    const [show, setShow] = useState(false);
    const [showExtendedsearch, setShowExtendedsearch] = useState(false);
    const [showHurray, setShowHurray] = useState(false);
    const [expanded, setExpanded] = useState('panel0');
    const [agentTodelete, setAgentTodelete] = useState(false);
    const [modal, setModal] = useState(null);
    const searchparams = useSelector(state => state.searchparams);
    const whatsappButton = <>
            <a className="btn btn-success rounded-pill mb-2" href="https://api.whatsapp.com/send?text=Schau%20mal%20auf%20Zammadoo,%20um%20Partner%20für%20Sport%20und%20Freizeit%20zu%20finden:%20https://www.zammadoo.com" target="_blank">Whats-App <WhatsAppIcon /></a>
        </>
    const sliderClasses = {
        valueLabel: 'MuiSlider-valueLabel--zammadoo',
        thumbColorSecondary: 'MuiSlider-thumbColorSecondary--zammadoo'
    };
    const activity = useSelector(state => state.activity);
    const { Geolocation } = Plugins;
    const [geoLocation, setGeoLocation] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [errorType, setErrorType] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const handleCloseAlert = () => setShowAlert(false);
    const [platformClass, setPlatformClass] = useState('');
    const [open, setOpen] = useState(false);
    const [cardData, setCardData] = useState({});
    let geoLocationWatchId;

    useEffect(() => {
        setPlatformClass(Capacitor.platform);
    }, []);

    useEffect(() => {
        geoLocationWatchId = Geolocation.watchPosition({timeout:1000, enableHighAccuracy: false}, (position, err) => {
            if (!err) {
                setGeoLocation(position);
                Geolocation.clearWatch({id: geoLocationWatchId});
            } else {
                if (!geoLocation || !geoLocation.coords) {
                    setGeoLocation(null);
//                    dispatch(alertActions.error(err.message));
                }
            }
        })
    }, []);

    useEffect(() => {
        if (geoLocation && geoLocation.coords && !geolocation.data && !geolocation.loading) {
            dispatch(geolocationActions.getLocationData(geoLocation.coords));
        }
    }, [geoLocation]);

    useEffect(() => {
        if (localContext && localContext.referrer) {
            setReferrer(localContext.referrer);
        }
        dispatch(userActions.getSearchagents());
        dispatch(userActions.setLocalContext({
            referrer: null,
            title: <h5 className="pt-3">Meine Benachrichtigungen</h5>
        }));
    }, []);

    useEffect(() => {
        dispatch(userActions.getSearchparams());
        dispatch(activityActions.getActivities());
    }, []);

    useEffect(() => {
        if (referrer) {
            if ('superlike' === referrer.type) {
                let idx = 0;
                if (searches.matches) {
                    idx = searches.matches.length - 1;
                }
                setExpanded('panel' + idx);
            }
        }
    }, [searches]);

    useEffect(() => {
        let storageUpdated = {};
        if (storage) {
            storageUpdated = storage;
        }
        storageUpdated.matchesLed = false;
        dispatch(userActions.setStorage(storageUpdated));
    }, [storage]);

    function openProfile(id) {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: true,
            showProfileId: id,
            showSettings: false
        }
        dispatch(userActions.setLocalContext(localContextUpdated));

        setTimeout(() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }, 500);
    }

    function removeAgent(searchAgent) {
        if (agentTodelete) {
            setShow(false);
            setAgentTodelete(null);
        } else {
            setAgentTodelete(searchAgent);
            setModal('confirmDeleteAgent');
            setShow(true);
            return false;
        }

        let o = {
            id: searchAgent.id,
            type: 3,
            type3off: true,
        }

        dispatch(userActions.pushSearch(o));
    }

    function handleConversation(person) {
        let o = {person: person.uid, conversation: person.conversation};

        dispatch(chatActions.openConversation(o));
    }

    function handleClose() {
        setAgentTodelete(null);
        setShow(false);
    }

    const handleAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function handleChange(e) {
        const { name, value } = e.target;
        let params = searchparams.params;
        params[name] = value;
        dispatch(userActions.updateSearchparams(params));
    }

    function handleChangeAge(event, newValue) {
        let params = searchparams.params;
        params.fromage = newValue[0];
        params.toage = newValue[1];
        dispatch(userActions.updateSearchparams(params));
    }

    function handleChangeDistance(event, newValue) {
        let params = searchparams.params;
        params.distance = newValue;
        dispatch(userActions.updateSearchparams(params));
    }

    function handleChangeTime(event, newValue) {
        let params = searchparams.params;
        params.fromtime = newValue[0];
        params.totime = newValue[1];
        dispatch(userActions.updateSearchparams(params));
    }

    function handleSubmit(e) {
        e.preventDefault();

        let params = searchparams.params;
        if (0 == params.offsetdays) {
            if (new Date().setHours(params.totime,0,0,0) < new Date()) {
                setErrorType(1);
                setShowAlert(true);
                return false;
            }
        }
        params.latitude = params.lat;
        params.longitude = params.lon;
        dispatch(userActions.updateSearchparams(params));
        dispatch(userActions.pushSearch(params));

        setShowExtendedsearch(false);
    }

    function openProfile(e,id) {
        e.stopPropagation();
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: true,
            showProfileId: id,
            showSettings: false
        }
        dispatch(userActions.setLocalContext(localContextUpdated));

        setTimeout(() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }, 500);
    }

    function setOpenDatailcard(person) {
        console.log(person);
        setCardData(person);
        setOpen(true);
    }

    return (
        <div className="feeds pl-2">
            {searches.loading && <em>Loading matches...</em>}
            {searches.items && 0 === searches.items.length && <div className="text-center px-4 pb-4 pt-3"><p>Du hast noch keine Benachrichtigungen angelegt.
                Lass Dich informieren, sobald jemand eine neue Suche einstellt, die Dich interessieren könnte!</p></div>}
            {searches.items && searches.items[0] &&
            <div className="pt-3">
                {searches.items.map((searchagent, index) =>
                    <div key={index} className="feed-row row no-gutters align-items-center py-1 mb-2" style={{boxShadow:'0 4px 2px -2px rgba(0, 0, 0, 0.05)', fontSize:'0.9rem'}} aria-controls="example-fade-text" aria-expanded={open}>
                        <div className="col">
                            <div className="row no-gutters">
                                <div className="col-8 text-uppercase text-dark h6 pl-3 pt-2"><span className="" style={{hyphens:'auto'}}>{searchagent.activity}</span></div>
                            </div>
                            <div className="row no-gutters text-primary">
                                <div className="col text-center" style={{maxWidth:'30%'}}>
                                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                        <img className="zammadoo-icon" src="/svg/Icon_Geschlecht.svg" alt="" />
                                    </div>
                                    <div className="align-middle" style={{lineHeight:'1.2', height:'30px'}}>
                                        <table className="h-100 w-100">
                                            <tbody>
                                            <tr>
                                                <td className="align-middle"><span className=" text-uppercase" style={{hyphens:'auto'}}>
                                                    {3 === searchagent.gender && 'egal'}
                                                    {2 === searchagent.gender && 'Frau'}
                                                    {1 === searchagent.gender && 'Mann'}
                                                </span></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col text-center" style={{maxWidth:'25%'}}>
                                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                        <img className="zammadoo-icon" src="/svg/Icon_Alter.svg" alt=""  />
                                    </div>
                                    <div style={{lineHeight:'1.2', height:'30px'}}>
                                        <table className="h-100 w-100">
                                            <tbody>
                                            <tr>
                                                <td className="align-middle"><span className="text-nowrap text-capitalize">{searchagent.fromage} - {searchagent.toage}</span></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col text-center" style={{maxWidth:'50%'}}>
                                    <div className="pl-1 pr-1 pl-md-4 pr-md-4">
                                        <img className="zammadoo-icon" src="/svg/Icon_Ort.svg" alt="" title="Wann?"/>
                                    </div>
                                    <div style={{lineHeight:'1.2', minHeight:'30px'}}>
                                        <table className="h-100 w-100" style={{minHeight: '30px'}}>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle pr-3"><span className="text-break">
                                                        <LocationName latitude={searchagent.latitude} longitude={searchagent.longitude} />, Umkr. {searchagent.distance}km
                                                    </span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <button type="button" className="btn zammadoo-feeds-button position-absolute rounded-circle bg-secondary p-1 text-white" onClick={() => removeAgent(searchagent)} style={{left:'calc(100% - 1.5rem)',top:'0'}}>
                                        <img className="zammadoo-icon" src="/svg/addpic.svg" alt="" style={{transform:'rotate(45deg)', padding:'0.25rem'}}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            }
            <IonFab vertical="bottom" horizontal="center" slot="fixed" style={{position:'fixed'}}>
                <IonFabButton color="dark" className="new-search" href="/searchagentform" style={{"--ion-color-base":'var(--secondary)'}}>
                    <IonIcon icon={add} className="text-white" />
                </IonFabButton>
                <div className="separator" style={{height:'0.5rem', marginBottom:'5.5rem'}}></div>
            </IonFab>
            {false && searches.matches && searches.matches[0] && 0 === searches.matches[0].searchMatches.length
            && false === searches.matches[0].searchDetail.expired && <div className="text-center px-4 pb-4"><p className="pt-4">Leider gibt es noch keine Treffer zu Deiner Suche.
                <br/>
                Teile Zammadoo doch mit Deinen Freunden und erweitere Deine Suche, um einen Partner zu finden.</p> {whatsappButton}</div>}
            <div className="separator" style={{marginBottom:'4.5rem'}}></div>
            {agentTodelete &&
            <Modal show={show} onHide={handleClose} className="zammadoo-modal-fromtop">
                <Modal.Header className="text-center" closeButton>
                    {'confirmDeleteAgent' === modal && <Modal.Title className="w-100">Benachrichtigung löschen</Modal.Title>}
                </Modal.Header>
                {'confirmDeleteAgent' === modal &&
                <Modal.Body>
                    Möchtest Du diese Benachrichtigung wirklich löschen?<br/>
                </Modal.Body>
                }
                {'confirmDeleteAgent' === modal &&
                <Modal.Footer className="text-center justify-content-center">
                    <button className="btn btn-primary text-white rounded-pill" onClick={() => removeAgent(agentTodelete)}>Löschen</button>
                </Modal.Footer>
                }
            </Modal>
            }
            {searchparams.params &&
            <IonModal isOpen={showExtendedsearch} cssClass={'zammadoo-modal-frombottom zammadoo-'+platformClass}>
                <div className="modal-header border-bottom-0">
                    <div className="w-100 text-center modal-title h6 text-uppercase position-absolute" style={{left:'0'}}>Suche erweitern</div>
                    <button type="button" className="close" onClick={() => setShowExtendedsearch(false)} style={{zIndex:'1'}}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <Modal.Body className="activity-accordion">
                    <MuiThemeProvider theme={theme}>
                        {searchparams.loading && <em>Loading params...</em>}
                        {searchparams.params &&
                        <div className="col pt-3" style={{height:'100vh',overflow:'scroll'}}>
                            <div className="row pb-4">
                                <div className="col-2">
                                    <img src="/svg/Icon_Geschlecht.svg" alt="" width="40" height="40" title="Wem?"/>
                                </div>
                                <div className="col-10">
                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label className="btn text-uppercase btn-radiobtn--zammadoo active text-primary">
                                            {searchparams.params.gender == 1 && <span>Mann</span>}
                                            {searchparams.params.gender == 2 && <span>Frau</span>}
                                            {searchparams.params.gender == 3 && <span>egal</span>}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Alter.svg" alt="" width="40" height="40" title="Wie alt?"/>
                                </div>
                                <div className="col pt-2">
                                    <Slider
                                        value={[searchparams.params.fromage, searchparams.params.toage]}
                                        onChange={handleChangeAge}
                                        min={18}
                                        max={99}
                                        aria-labelledby="age-slider"
                                        color="secondary"
                                        classes={sliderClasses}
                                        valueLabelDisplay="on"
                                    />
                                </div>
                                <div className="col-2 pt-2">
                                    <Typography id="age-slider" className="text-darkgray" gutterBottom>
                                        <small>JAHRE</small>
                                    </Typography>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Ort.svg" alt="" width="40" height="40" title="Wo?"/>
                                </div>
                                <div className={"col pt-2"}>
                                    <Typography className="text-uppercase" gutterBottom>
                                        <LocationName latitude={searchparams.params.lat} longitude={searchparams.params.lon} />
                                    </Typography>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Entfernung.svg" alt="" width="40" height="40" title="Wie weit?"/>
                                </div>
                                <div className={"col pt-2"}>
                                    <Slider
                                        value={searchparams.params.distance}
                                        onChange={handleChangeDistance}
                                        aria-labelledby="distance-slider"
                                        valueLabelDisplay="on"
                                        color="secondary"
                                        classes={sliderClasses}
                                        min={1}
                                        max={100}
                                    />
                                </div>
                                <div className={"col-2 pt-2"}>
                                    <Typography id="distance-slider" className="text-uppercase text-darkgray" gutterBottom>
                                        <small>KM</small>
                                    </Typography>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Datum.svg" alt="" width="40" height="40" title="Wann?"/>
                                </div>
                                <div className={"col"}>
                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label className="btn text-uppercase btn-radiobtn--zammadoo active text-primary">
                                            {searchparams.params.offsetdays == 0 && <span>Heute</span>}
                                            {searchparams.params.offsetdays == 1 && <span>Morgen</span>}
                                            {searchparams.params.offsetdays == 2 && <span>{weekdays[(today.getDay() + 2) % 7]}</span>}
                                            {searchparams.params.offsetdays == 3 && <span>{weekdays[(today.getDay() + 3) % 7]}</span>}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon_Uhrzeit.svg" alt="" width="40" height="40" title="Wieviel Uhr?"/>
                                </div>
                                <div className={"col pt-2"}>
                                    <Slider
                                        value={[searchparams.params.fromtime, searchparams.params.totime]}
                                        onChange={handleChangeTime}
                                        aria-labelledby="time-slider"
                                        valueLabelDisplay="on"
                                        color="secondary"
                                        classes={sliderClasses}
                                        min={0}
                                        max={24}
                                        step={1}
                                    />
                                </div>
                                <div className={"col-2 pt-2"}>
                                    <Typography id="time-slider" className="text-uppercase text-darkgray" gutterBottom>
                                        <small>Uhr</small>
                                    </Typography>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className={"col-2"}>
                                    <img src="/svg/Icon-description.svg" alt="" width="40" height="40" title="Comments to your search"/>
                                </div>
                                <div className={"col pt-2"}>
                                    <textarea name="description" className="form-control" placeholder="Leave a comment here" onChange={handleChange} value={searchparams.params.description}  maxLength="140">
                                    </textarea>
                                </div>
                            </div>

                            {activity.items && geoLocation &&
                            <div className="text-center mb-5">
                                <button type="button" className="btn btn-primary rounded-pill text-white w-50" onClick={handleSubmit}>
                                    {submitted && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Übernehmen
                                </button>
                            </div>
                            }

                            <div className="separator" style={{height:'4.5rem'}}></div>
                        </div>
                        }
                        <Modal show={showAlert} onHide={handleCloseAlert} className="zammadoo-modal-fromtop">
                            <Modal.Header closeButton>
                                <Modal.Title className="w-100 text-center">Bitte anpassen</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                                {1===errorType && <>Falscher Zeitraum: Deine Suche liegt in der Vergangenheit.<br/></>}
                                {2===errorType && <>Keine Aktivität: Bitte eine Aktivität auswählen.<br/></>}
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                                <button className="btn btn-secondary rounded-pill" onClick={handleCloseAlert}>
                                    Suche anpassen
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </MuiThemeProvider>
                </Modal.Body>
            </IonModal>
            }
        </div>
    );
}

export { Searchagent };
