import { locationConstants } from '../_constants';

export function location(state = {}, action) {
  switch (action.type) {
    case locationConstants.GETLOCATIONS_REQUEST:
      return {
        loading: true
      };
    case locationConstants.GETLOCATIONS_SUCCESS:
      return {
        items: action.location
      };
    case locationConstants.GETLOCATIONS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
