import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {alertActions, userActions} from '../_actions';
import {Modal} from "react-bootstrap";
import dialingcodes from "../data/dialingcode.json";
import md5 from "md5";
import { history } from '../_helpers';

function VerifyAccount() {
    const auth = useSelector(state => state.authentication);
    const authUser = useSelector(state => state.authentication.user);
    const [show, setShow] = useState(false);
    const [step, setStep] = useState(1);
    const [countryIso, setCountryIso] = useState('de');
    const [user, setUser] = useState({
        dialingcode: '49',
        mobilephone: '',
        email: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const [smsFieldDisabled, setSmsFieldDisabled] = useState(false);
    const options = {
        cssClass: 'my-custom-interface'
    };
    const dispatch = useDispatch();

    useEffect(() => {
        if (auth.smsconfirmed) {
            dispatch(userActions.login2(md5('+' + user.dialingcode + user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '')) + '@zammadoo.com','hereTheDeviceToken'));
        }
    }, [auth]);

    useEffect(() => {
        if (authUser && '@zammadoo.com' === authUser.toString().substr(32,13)) {
            history.push('/');
        }
    }, [authUser]);

    function handleClose() {
        setShow(false);
    }

    function handleDeleteAccount() {
        setShow(false);
        dispatch(userActions.deleteAccount());
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
    }

    function handleDialingcode(e) {
        const { name } = e.target;
        const { id, value } = e.target.options[e.target.selectedIndex];
        setCountryIso(id.toString().toLowerCase());
        setUser(user => ({ ...user, [name]: value }));
    }

    function handleChangeSmsCode(e) {
        let smsdata = {};
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
        if (5 <= value.toString().length) {
            setSmsFieldDisabled(true);
            smsdata.smsCode = value;
            smsdata.email = md5('+49' + user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '')) + '@zammadoo.com';
            smsdata.userup = authUser.username;
            smsdata.verify = true;
            dispatch(userActions.confirmSms(smsdata));
        }
    }

    function requestSms() {
        if (! user.mobilephone) {
            setSubmitted(true);
            return;
        } else {
            let params = {};
            params.mobilephone = '+' + user.dialingcode + user.mobilephone.toString().replace(/^0/i, '').replace(/[^0-9]/g, '');
            params.username = authUser.username;
            setSmsFieldDisabled(false);
            dispatch(userActions.resendConfirmationSMS(params));
        }
        setSubmitted(false);
        setStep(2);
    };

    return (
        <>
        <div className="bg-primary" style={{minHeight:'100vh'}}>
            <div className="first-block text-center pt-3 px-3 px-sm-5">
                <img src="/svg/Icon_Schloss_white.svg" className="w-25 w-md-50 py-2" />
            </div>
            {1 === step &&
                <>
                <div className="first-block px-3 px-sm-5">
                    <h5 className="text-white font-italic">Du möchtest Deinen Account verifizieren?</h5>
                    <p className="text-white">Um die Sicherheit für Dich und andere User zu erhöhen und die Echtheit der Profile auf Zammadoo zu gewährleisten, werden alle Accounts verifiziert. Im nächsten Schritt erhälst Du hierzu einen Bestätigungscode auf Dein Mobiltelefon. Im Falle einer Sperrung kannst Du Deinen Account so auch reaktivieren.</p>
                </div>
                <div className="first-block px-3 px-sm-5">
                    <div className="form-group">
                        <div className="row no-gutters text-center">
                            <div className="col-2 pb-1 pr-2">
                                <img src={'/flags/4x3/'+countryIso+'.svg'} className="w-100"/>
                            </div>
                            <div className="col-3">
                                <select className="form-select w-100 dialing" name="dialingcode" aria-label="Default select example" onChange={handleDialingcode}>
                                    {dialingcodes && dialingcodes.map((dialingcode, index) =>
                                        <option key={index} value={dialingcode.Dial} style={{display:dialingcode.Dial?'':'none'}} selected={'49'==dialingcode.Dial} id={dialingcode.ISO3166_1_Alpha_2}>
                                            {dialingcode.ISO3166_1_Alpha_2} &nbsp;&nbsp;&nbsp;&nbsp;{dialingcode.ISO4217_Currency_Country_Name ? dialingcode.ISO4217_Currency_Country_Name : dialingcode.Country_Name}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div className="col pl-1"><input type="number" placeholder="012345" name="mobilephone" value={user.mobilephone} onChange={handleChange} className={'form-control' + (submitted && !user.mobilephone ? ' is-invalid' : '')} /></div>
                        </div>
                        {submitted && !user.mobilephone &&
                        <div className="invalid-feedback">Mobilfunknummer ist pflicht</div>
                        }
                    </div>
                </div>
                <div className="first-block text-center px-3 px-sm-5">
                    <a className="btn bg-white rounded-pill w-100" onClick={requestSms}>Account verifizieren</a><br/><br/>
                </div>
                <div className="first-block text-center px-3 px-sm-5">
                    <a className="text-white" href="/">Abbrechen</a><br/><br/>
                </div>
                </>
            }
            {2 === step &&
                <>
                <div className="first-block px-3 px-sm-5">
                    <p><br/></p>
                    <p className="text-white">Wir haben einen Bestätigungscode an {user.mobilephone} geschickt. Bitte gib ihn hier ein:</p>
                    <h5 className="text-white text-left ml-auto">Bestätigungscode</h5>
                    <div className="form-group text-center">
                        <input type="text" placeholder="" name="smscode" value={user.smscode} onChange={handleChangeSmsCode} className={'form-control w-50' + (submitted && !user.smscode ? ' is-invalid' : '')} disabled={smsFieldDisabled} />
                        {submitted && !user.smscode &&
                        <div className="invalid-feedback">SMS-Code ist pflicht</div>
                        }
                    </div>
                    <div className="text-white text-left">
                        <p>Du hast den Code nicht erhalten?<br/>
                            <a onClick={() => setStep(1)} className="text-secondary">Erneut senden</a>
                        </p>
                    </div>
                </div>
                {authUser && '@zammadoo.com' === authUser.username.toString().substr(32,13) &&
                    <div className="first-block text-center px-3 px-sm-5">
                        <p><br/></p>
                        <p>Die Verifizierung war erfolgreich.</p>
                        <p><br/></p>
                        <a className="btn bg-white rounded-pill w-100" href="/">Weiter</a><br/><br/>
                    </div>
                }
                </>
            }
        </div>
        <Modal show={show} onHide={handleClose} style={{marginTop:'5rem'}}>
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">Account löschen</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                Wenn Du Deinen Zammadoo-Account jetzt löschst, werden alle gespeicherten Profildaten, Matches und Chatdialoge unwiederbringlich mitgelöscht.<br/>
                Um Zammadoo danach wieder zu nutzen, musst Du ein neues Profil anlegen.
            </Modal.Body>
            <Modal.Footer className="text-center justify-content-center">
                <button className="btn btn-primary text-white rounded-pill w-75" onClick={() => handleDeleteAccount()}>Jetz löschen</button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export { VerifyAccount };
