import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import imageCompression from "browser-image-compression";

import {alertActions, userActions} from '../_actions';
import {MediaObject} from "../_components";

function EditProfile() {
    const localContext = useSelector(state => state.context.local);
    const profileData = useSelector(state => state.profile.profileData);
    const [profile, setProfile] = useState(profileData);
    const [submitted, setSubmitted] = useState(false);
    const [preferences, setPreferences] = useState({
        EMAIL_ON: false
    });
    const updating = useSelector(state => state.profile.updating);
    const dispatch = useDispatch();

    useEffect(() => {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            title: <h3 className="text-uppercase pt-2">Editieren</h3>
        }
        dispatch(userActions.setLocalContext(localContextUpdated));
    }, []);

    useEffect(() => {
        if (profileData) {
            setProfile(profileData);
            for (const [index, value] of profileData.preferences.entries()) {
                setPreferences(preferences => ({...preferences, [value]: true}))
            }
        } else {
            dispatch(userActions.getProfile());
        }
    }, [profileData]);

    function handleChange(e) {
        const { name, value } = e.target;
        setProfile(profile => ({ ...profile, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        dispatch(userActions.updateProfile(profile));
    }

    function handleChangeBirthday(date) {
        setProfile(profile => ({ ...profile, ['birthday']: date.toISOString() }));
    };

    function handleChangePreferences(e) {
        const { name, checked } = e.target;
        let preferences2array = [];
        setPreferences(preferences => ({ ...preferences, [name]: checked }));
        Object.keys(preferences).map(function(keyName, keyIndex) {
            if (name == keyName) {
                if (true == checked) {
                    preferences2array.push(keyName);
                }
            } else
            if (true == preferences[keyName]) {
                preferences2array.push(keyName);
            }
        });
        setProfile(profile => ({ ...profile, ['preferences']: preferences2array }));
    };

    function handleUpload(e) {
        const files = e.target.files;
        const imageForm = new FormData();
        let file;
        for(var x = 0; x<files.length; x++) {
            file = files[x];
            if (file.size > 1024) {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 640,
                    useWebWorker: true
                };
                imageCompression(file, options).then(x => {
                    file = x;
                    imageForm.append('file', file, file.name);
                    dispatch(userActions.postImage(imageForm));
                });
            } else {
                imageForm.append('file', file, file.name);
                dispatch(userActions.postImage(imageForm));
            }
        }
    }

    function handleDeleteImage(imagePath) {
        let doReload = false;
        if(imagePath === profile.profileimage) {
            doReload = true;
        }
        dispatch(userActions.deleteImage({image:imagePath, reload:doReload}));
    }

    function handleProfileImage(imagePath) {
        dispatch(userActions.updateProfileImage({image:imagePath}));
    }

    return (
        <>
        {profile &&
        <div className="px-1 pt-2">
            <div className="row">
                <div className="col-4 text-center">
                    {profile.profileimage && <><MediaObject mediaObjectUrl={profile.profileimage} className="img-fluid img-thumbnail" /></>}
                </div>
            {profile.images && profile.images.map((image, index) => {
                return (
                    <div key={index} className="col-4 text-center">
                        <MediaObject mediaObjectUrl={image} className="img-fluid img-thumbnail" />
                        <HighlightOffIcon onClick={() => handleDeleteImage(image)} fontSize="small" />
                        {(image === profile.profileimage) && <RadioButtonCheckedIcon fontSize="small" />}
                        {(image !== profile.profileimage) && <RadioButtonUncheckedIcon onClick={() => handleProfileImage(image)} fontSize="small" />}
                    </div>
                )
            })}
            </div>
            <div className="uploadButton btn btn-lg btn-outline-primary w-100" style={{position:'relative'}}>
                Upload file
                <input
                    type="file"
                    accept="image/*"
                    className="upload"
                    onChange={handleUpload}
                />
            </div>
            <form name="form" onSubmit={handleSubmit}>
                <div className="form-group pt-2">
                    <textarea type="text" placeholder="Text" name="axiom" value={profile.axiom}
                           onChange={handleChange} rows="5" maxLength="255"
                           className={'form-control' + (submitted && !profile.axiom ? ' is-invalid' : '')}>
                    </textarea>
                    <small>Noch {255 - profile.axiom.length} freie Zeichen.</small>
                    {submitted && !profile.axiom &&
                    <div className="invalid-feedback">Dieser Text ist pflicht</div>
                    }
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label className={'btn btn-outline-primary' + (profile.gender === 1 ? ' active text-white' : '')}
                           htmlFor="genderBoy">
                        <input type="radio" name="gender" id="genderBoy" autoComplete="off" value="1"
                               onChange={handleChange}/> Mann
                    </label>
                    <label className={'btn btn-outline-primary' + (profile.gender === 2 ? ' active text-white' : '')}
                           htmlFor="genderGirl">
                        <input type="radio" name="gender" id="genderGirl" autoComplete="off" value="2"
                               onChange={handleChange}/> Frau
                    </label>
                </div>
                <div className="form-group pt-2">
                    <input type="text" placeholder="Vorname" name="firstname" value={profile.firstname}
                           onChange={handleChange}
                           className={'form-control' + (submitted && !profile.firstname ? ' is-invalid' : '')}/>
                    {submitted && !profile.firstname &&
                    <div className="invalid-feedback">Vorname ist pflicht</div>
                    }
                </div>
                <div className="form-group">
                    <input type="text" placeholder="Name" name="lastname" value={profile.lastname}
                           onChange={handleChange}
                           className={'form-control' + (submitted && !profile.lastname ? ' is-invalid' : '')}/>
                    {submitted && !profile.lastname &&
                    <div className="invalid-feedback">Nachname is pflicht</div>
                    }
                </div>
                <div className="form-group">
                    <DatePicker
                        selected={new Date(profile.birthday)}
                        onChange={handleChangeBirthday}
                        dateFormat="dd. MMMM yyyy"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={60}
                        scrollableYearDropdown
                        className="form-control"
                    />
                </div>
                <div className="form-group custom-control custom-switch">
                    {profile.preferences &&
                    <>
                        <input type="checkbox" className="custom-control-input" name="EMAIL_ON" checked={preferences.EMAIL_ON} value="1" id="emailOn" onChange={handleChangePreferences} />
                        <label className="custom-control-label" htmlFor="emailOn">Email Benachrichtigungen einschalten</label>
                    </>
                    }
                </div>
                <div className="form-group">
                    <button className="btn btn-primary">
                        {updating && <span className="spinner-border spinner-border-sm mr-1"></span>}Speichern
                    </button>
                </div>
            </form>
            <div className="separator" style={{marginBottom:'4.5rem'}}></div>
        </div>
        }
        </>
    );
}

export { EditProfile };
