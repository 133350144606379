import { userConstants } from '../_constants';

export function searches(state = {}, action) {
  switch (action.type) {
    case userConstants.GETSEARCH_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETSEARCH_SUCCESS:
      return {
        matches: action.searches
      };
    case userConstants.GETSEARCH_FAILURE:
      return {
        error: action.error
      };
    case userConstants.POSTSEARCH_REQUEST:
      return {
        ...state,
        searching: true
      };
    case userConstants.POSTSEARCH_SUCCESS:
      return {
        matches: action.searches
      };
    case userConstants.POSTSEARCH_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
