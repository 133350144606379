import { ENTRYPOINT } from '../config/entrypoint';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {activityActions, alertActions, userActions, geolocationActions, locationActions} from '../_actions';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IonModal } from '@ionic/react';

import {Capacitor, Plugins} from '@capacitor/core';
import { history } from '../_helpers';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#f39325'
        },
        secondary: {
            main: '#878787'
        }
    }
});

function SearchesType3() {
    const localContext = useSelector(state => state.context.local);
    const activity = useSelector(state => state.activity);
    const searchparams = useSelector(state => state.searchparams);
    const geolocation = useSelector(state => state.geolocation);
    const searches = useSelector(state => state.searches.matches);
    const locations = useSelector(state => state.location.items);
    const locationsAreLoading = useSelector(state => state.location.loading);
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showOverwrite, setShowOverwrite] = useState(false);
    const [errorType, setErrorType] = useState(null);
    const [category, setCategory] = useState(null);
    const { Geolocation } = Plugins;
    const [geoLocation, setGeoLocation] = useState(null);
    const [expanded, setExpanded] = useState('panel0');
    const [showActivities, setShowActivities] = useState(false);
    const [showLevel, setShowLevel] = useState(false);
    let actIdx = 0;
    let geoLocationWatchId;

    const dispatch = useDispatch();
    const [platformClass, setPlatformClass] = useState('');
    const [overwrite, setOverwrite] = useState(false);
    const [activityChanged, setActivityChanged] = useState(0);

    useEffect(() => {
        setPlatformClass(Capacitor.platform);
    }, []);

    useEffect(() => {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            title: <div>
                <button type="button" className="btn float-left text-white" onClick={() => history.goBack()}><span aria-hidden="true" className="h2">×</span><span className="sr-only">Close</span></button><h5 className="pt-3" style={{paddingRight:'3rem'}}>Neue Benachrichtigung</h5></div>
        }
        dispatch(userActions.setLocalContext(localContextUpdated));
    }, []);

    useEffect(() => {
        geoLocationWatchId = Geolocation.watchPosition({timeout:1000, enableHighAccuracy: false}, (position, err) => {
            if (!err) {
                setGeoLocation(position);
                Geolocation.clearWatch({id: geoLocationWatchId});
            } else {
                if (!geoLocation || !geoLocation.coords) {
                    setGeoLocation(null);
//                    dispatch(alertActions.error(err.message));
                }
            }
        })
    }, []);

    useEffect(() => {
        if (geoLocation && geoLocation.coords && !geolocation.data && !geolocation.loading) {
            dispatch(geolocationActions.getLocationData(geoLocation.coords));
        }
        if (geoLocation && geoLocation.coords && !activity.items) {
            dispatch(activityActions.getActivities(geoLocation.coords, 1));
        }
    }, [geoLocation]);

    useEffect(() => {
        dispatch(userActions.getSearchparams());
        dispatch(userActions.getSearches());
    }, []);

    useEffect(() => {
        if (activity.items && searchparams.params) {
            handleShowLevel(activity.items[searchparams.params.category_id].activities[searchparams.params.activity_id]);
        }
    }, [activity]);

    const weekdays = ['SO','MO','DI','MI','DO','FR','SA'];
    const today = new Date();
    const sliderClasses = {
        valueLabel: 'MuiSlider-valueLabel--zammadoo',
        thumbColorSecondary: 'MuiSlider-thumbColorSecondary--zammadoo'
    };

    const handleClose = () => setShowActivities(false);
    const handleCloseAlert = () => setShowAlert(false);
    const handleCloseOverwrite = () => {
        setSubmitted(false);
        setOverwrite(false);
        setShowOverwrite(false);
    }

    function handleOpenActivities(categoryId) {
        setCategory(categoryId);
        setExpanded('panel'+categoryId);
        //setShow(true);
        setShowActivities(true);
    }

    function handleChangeActivity(e) {
        const { name, value } = e.target;
        let params = searchparams.params;
        params[name] = value;
        params.category_id = category;
        dispatch(userActions.updateSearchparams(params));
        setCategory(null);
        setShow(false);
    }

    function handleShowLevel(activity) {
            setShowLevel(false);
        if (activity.properties) {
            const found = activity.properties.find(element => element == 'haslevel');
            if (found) {
                setShowLevel(true);
            }
            return found;
        }
    }

    function handleChangeActivity2(category, activity) {
        let params = searchparams.params;
        if (activity.id != params.activity_id) {
            setActivityChanged(activityChanged + 1);
            setShowLevel(false);
            params.activitylevel = null;
            if (activity.properties) {
                const found = handleShowLevel(activity);
                if (found) {
                    params.activitylevel = null;
                }
            }
        }
        params.activity_id = activity.id;
        params.category_id = category.id;
        dispatch(userActions.updateSearchparams(params));
        setCategory(null);
        setShowActivities(false);
    }

    function handleChangeAge(event, newValue) {
        let params = searchparams.params;
        params.fromage = newValue[0];
        params.toage = newValue[1];
        dispatch(userActions.updateSearchparams(params));
    }

    function handleChangeTime(event, newValue) {
        let params = searchparams.params;
        params.fromtime = newValue[0];
        params.totime = newValue[1];
        dispatch(userActions.updateSearchparams(params));
    }

    function handleChangeDistance(event, newValue) {
        let params = searchparams.params;
        params.distance = newValue;
        dispatch(userActions.updateSearchparams(params));
    }

    function handleChange(e) {
        const { name, value } = e.target;
        let params = searchparams.params;
        params[name] = value;
        dispatch(userActions.updateSearchparams(params));
    }

    function clearActivity(e) {
        let params = searchparams.params;
        params.activity_id = null;
        params.category_id = null;
        dispatch(userActions.updateSearchparams(params));
        setCategory(null);
        setShowActivities(false);
    }

    function handleSubmit(e) {
        e.preventDefault();

        let params = searchparams.params;
        if ( !submitted ) {
            if (!params.category_id || !params.activity_id) {
                setErrorType(2);
                setShowAlert(true);
                return false;
            }
            setSubmitted(true);
        }

        params.type = 3;
        params.offsetdays = 1;
        params.fromtime = 1;
        params.totime = 10;
        params.latitude = geoLocation.coords.latitude;
        params.longitude = geoLocation.coords.longitude;
        params.location_id = null;
        params.activitylevel = null;

        dispatch(userActions.updateSearchparams(params));
        dispatch(userActions.postSearch(params));
    }

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (

        <MuiThemeProvider theme={theme}>
            {searchparams.loading && <em>Loading params...</em>}
            {searchparams.params &&
            <div className="col pt-3">
                <div className="row pb-4">
                    <div className="col-2">
                        <img src="/svg/Icon_Geschlecht.svg" alt="" width="40" height="40" title="Wem?"/>
                    </div>
                    <div className="col-10">
                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                            <label
                                className={'btn text-uppercase btn-radiobtn--zammadoo' + (searchparams.params.gender == 1 ? ' active text-primary' : ' text-darkgray')}
                                htmlFor="genderBoy">
                                <input type="radio" name="gender" id="genderBoy" autoComplete="off" value="1"
                                       onChange={handleChange}/> Mann
                            </label>
                            <label
                                className={'btn text-uppercase btn-radiobtn--zammadoo' + (searchparams.params.gender == 2 ? ' active text-primary' : ' text-darkgray')}
                                htmlFor="genderGirl">
                                <input type="radio" name="gender" id="genderGirl" autoComplete="off" value="2"
                                       onChange={handleChange}/> Frau
                            </label>
                            <label
                                className={'btn text-uppercase btn-radiobtn--zammadoo' + (searchparams.params.gender == 3 ? ' active text-primary' : ' text-darkgray')}
                                htmlFor="genderWhatever">
                                <input type="radio" name="gender" id="genderWhatever" autoComplete="off" value="3"
                                       onChange={handleChange}/> egal
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row pb-4">
                    <div className={"col-2"}>
                        <img src="/svg/Icon_Alter.svg" alt="" width="40" height="40" title="Wie alt?"/>
                    </div>
                    <div className="col pt-2">
                        <Slider
                            value={[searchparams.params.fromage, searchparams.params.toage]}
                            onChange={handleChangeAge}
                            min={18}
                            max={99}
                            aria-labelledby="age-slider"
                            color="secondary"
                            classes={sliderClasses}
                            valueLabelDisplay="on"
                        />
                    </div>
                    <div className="col-2 pt-2">
                        <Typography id="age-slider" className="text-darkgray" gutterBottom>
                            <small>JAHRE</small>
                        </Typography>
                    </div>
                </div>

                <div className="row pb-4">
                    <div className={"col-2"}>
                        <img src="/svg/Icon_Ort.svg" alt="" width="40" height="40" title="Wo?"/>
                    </div>
                    <div className={"col pt-2"}>
                        <Typography className="text-uppercase" gutterBottom>
                            {geolocation.data && (geolocation.data.locality ? geolocation.data.locality : 'PLZ' + geolocation.data.postcode)
                            }&nbsp;
                            {geolocation.data && (geolocation.data.city ? '(' + geolocation.data.city + ')' : '')
                            }
                        </Typography>
                    </div>
                </div>

                <div className="row pb-4">
                    <div className={"col-2"}>
                        <img src="/svg/Icon_Entfernung.svg" alt="" width="40" height="40" title="Wie weit?"/>
                    </div>
                    <div className={"col pt-2"}>
                        <Slider
                            value={searchparams.params.distance}
                            onChange={handleChangeDistance}
                            aria-labelledby="distance-slider"
                            valueLabelDisplay="on"
                            color="secondary"
                            classes={sliderClasses}
                            min={1}
                            max={100}
                        />
                    </div>
                    <div className={"col-2 pt-2"}>
                        <Typography id="distance-slider" className="text-uppercase text-darkgray" gutterBottom>
                            <small>KM</small>
                        </Typography>
                    </div>
                </div>

                <div className="row pb-4">
                    <div className="col-2">
                        <img src="/svg/Icon_Aktivitaet.svg" alt="" width="40" height="40" title="Wieviel Uhr?"/>
                    </div>
                    <div className="col-8 pt-0">
                    {activity.items &&
                        <>
                            {searchparams.params.category_id && activity.items[searchparams.params.category_id].activities[searchparams.params.activity_id] && activity.items[searchparams.params.category_id].activities[searchparams.params.activity_id].name &&
                            <ButtonGroup aria-label="Activity" className="btn-group-round w-100">
                                <button className="btn btn-primary text-white w-75" onClick={() => handleOpenActivities(searchparams.params.category_id)} style={{paddingLeft:'25%'}}>
                                    {searchparams.params.category_id && activity.items[searchparams.params.category_id].activities[searchparams.params.activity_id] && activity.items[searchparams.params.category_id].activities[searchparams.params.activity_id].name}
                                </button>
                                {searchparams.params.category_id &&
                                <button className="btn btn-outline-primary" onClick={clearActivity}>x</button>
                                }
                            </ButtonGroup>
                            }
                            {(!searchparams.params.category_id || !activity.items[searchparams.params.category_id].activities[searchparams.params.activity_id]) &&
                            <button className="btn btn-primary text-white rounded-pill w-100" onClick={() => handleOpenActivities(searchparams.params.category_id)}>
                                Aktivität auswählen
                            </button>
                            }
                        </>
                    }
                    </div>
                </div>

                {activity.items && geoLocation &&
                <div className="text-center mb-5">
                    <button type="button" className="btn btn-primary rounded-pill" onClick={handleSubmit}>
                        {submitted && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        <img src="/svg/Glocke_Weiss.svg" alt="" width="40" height="40" title="Suchen"/>
                    </button>
                </div>
                }
                <div className="separator" style={{height:'4.5rem'}}></div>
            </div>
            }
            <Modal show={showAlert} onHide={handleCloseAlert} className="zammadoo-modal-fromtop">
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">Bitte anpassen</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {1===errorType && <>Falscher Zeitraum: Deine Suche liegt in der Vergangenheit.<br/></>}
                    {2===errorType && <>Keine Aktivität: Bitte eine Aktivität auswählen.<br/></>}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button className="btn btn-secondary rounded-pill" onClick={handleCloseAlert}>
                        Suche anpassen
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal show={showOverwrite} onHide={handleCloseOverwrite} className="zammadoo-modal-fromtop">
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">Achtung!</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    Achtung, mit einer neuen Suche überschreibst Du Deine aktuelle Suche. Mögliche Treffer und Einladungen gehen dadurch verloren.
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button className="btn btn-secondary rounded-pill" onClick={handleSubmit}>
                        Neue Suche anlegen
                    </button>
                </Modal.Footer>
            </Modal>
            {activity.items && searchparams.params && <Modal show={show} onHide={handleClose} className="activity">
                <Modal.Header closeButton className="text-white">
                    <Modal.Title>Aktivität wählen</Modal.Title>
                </Modal.Header>
                <Modal.Body className="activity-accordion">
                    {Object.entries(activity.items).map(([k, category], i) => {
                        return <Accordion key={k} expanded={expanded === 'panel'+category.id} onChange={handleAccordionChange('panel'+category.id)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={'panel'+category.id+'bh-content'}
                                id={'panel1'+category.id+'bh-header'}
                            >{category.name}</AccordionSummary>
                            <AccordionDetails>
                                <div className="row w-100 py-2" style={{backgroundColor:'rgba(0,0,0,0.25)',borderRadius:'1.5rem',height:'40vh',overflow:'scroll'}}>
                                    {Object.entries(category.activities).map(([k, activity], i) => {
                                        return <div key={k} value={activity.id} onClick={() => handleChangeActivity2(category, activity)} name="activity_id" className="text-center col-4 p-3">
                                            <img
                                                className="w-100"
                                                src={ENTRYPOINT+'/media/activities/'+activity.iconfilename}
                                                alt="First slide"
                                            /><h6 className="text-primary pt-1" style={{lineHeight:'1'}}>{activity.name}</h6>
                                        </div>
                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    })}
                </Modal.Body>
            </Modal>}
            {activity.items && searchparams.params &&
            <IonModal isOpen={showActivities} cssClass={'zammadoo-modal-frombottom activity zammadoo-bg-secondary zammadoo-'+platformClass}>
                <div className="modal-header border-bottom-0">
                    <div className="w-100 text-uppercase text-center modal-title h6 position-absolute" style={{left:'0'}}>Aktivität wählen</div>
                    <button type="button" className="close" onClick={() => setShowActivities(false)} style={{zIndex:'1'}}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <Modal.Body className="activity-accordion">
                    {Object.entries(activity.items).map(([k, category], i) => {
                        return <Accordion key={k} expanded={expanded === 'panel' + category.id}
                                          onChange={handleAccordionChange('panel' + category.id)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls={'panel' + category.id + 'bh-content'}
                                id={'panel1' + category.id + 'bh-header'}
                            >{category.name}</AccordionSummary>
                            <AccordionDetails>
                                <div className="row w-100 py-2" style={{
                                    backgroundColor: 'var(--white)',
                                    borderRadius: '1.5rem',
                                    height: '40vh',
                                    overflow: 'scroll'
                                }}>
                                    {Object.entries(category.activities).map(([k, activity], i) => {
                                        return <div key={k} value={activity.id}
                                                    onClick={() => handleChangeActivity2(category, activity)}
                                                    name="activity_id" className="text-center col-4 p-3">
                                            <img
                                                className="w-100"
                                                src={ENTRYPOINT + '/media/activities/' + activity.iconfilename}
                                                alt="First slide"
                                            /><h6 className="text-primary pt-1"
                                                  style={{lineHeight: '1'}}>{activity.name}</h6>
                                        </div>
                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    })}
                </Modal.Body>
            </IonModal>
            }
        </MuiThemeProvider>
    );
}

export { SearchesType3 };
