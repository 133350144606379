import { userConstants } from '../_constants';

export function profile(state = {}, action) {
  switch (action.type) {
    case userConstants.GETPROFILE_REQUEST:
      return {

        loading: true
      };
    case userConstants.GETPROFILE_SUCCESS:
      return {

        loading: false,
        profileData: action.profile
      };
    case userConstants.GETPROFILE_FAILURE:
      return {
        error: action.error
      };
    case userConstants.UPDATEPROFILE_REQUEST:
      return {
        ...state,
        profileData: false,
        updating: true
      };
    case userConstants.UPDATEPROFILE_SUCCESS:
      return {
        updating: false,
        updated: true
      };
    case userConstants.UPDATEPROFILE_FAILURE:
      return {
        error: action.error
      };
    case userConstants.POSTIMAGE_REQUEST:
      return {

        lastUpload: null,
        imageUploading: true
      };
    case userConstants.POSTIMAGE_SUCCESS:
      return {

        imageUploading: false,
        lastUpload: action.result
      };
    case userConstants.POSTIMAGE_FAILURE:
      return {

        imageUploading: false,
        lastUpload: null,
        error: action.error
      };
    default:
      return state
  }
}
