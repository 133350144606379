import React, {useEffect, useState} from 'react';
import {alertActions, chatActions, userActions} from "../_actions";
import {useDispatch, useSelector} from "react-redux";
import {chatService, userService} from "../_services";
import {MediaObject} from "../_components";
import { history } from '../_helpers';

import { IonList, IonItemSliding, IonItem, IonLabel, IonItemOptions, IonItemOption, IonIcon, IonNote } from '@ionic/react';
import {Modal} from "react-bootstrap";

function Chat() {
    const localContext = useSelector(state => state.context.local);
    const storage = useSelector(state => state.context.storage);
    const dispatch = useDispatch();
    const conversations = useSelector(state => state.chat.conversations);
    const [eventSource, setEventSource] = useState(null);
    const [buddyIsTyping, setBuddyIsTyping] = useState({});
    const [show, setShow] = useState(false);
    const [confirmBlaklist, setConfirmBlaklist] = useState(false);
    const [unlikeData, setUnlikeData] = useState({});
    const [blacklist, setBlacklist] = useState({});
    let dispatching = false;

    useEffect(() => {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: false,
            showSettings: false,
            title: <h5 className="pt-3">Chats</h5>
        }
        dispatch(userActions.setLocalContext(localContextUpdated));
    }, []);

    useEffect(() => {
        let storageUpdated = {};
        if (storage) {
            storageUpdated = storage;
        }
        storageUpdated.chatLed = false;
        dispatch(userActions.setStorage(storageUpdated));
    }, [storage]);

    useEffect(() => {
        dispatch(chatActions.getConversations());
    }, []);

    useEffect(() => {
        if (conversations) {
            if (!eventSource) {
                chatService.subscribeMercure().then(hubConfig => {
                    conversations.map(function(conversation, index){
                        hubConfig.hubUrl.searchParams.append('topic', 'zammadoo/api/chat/' + conversation.identifier);
                    })
                    setEventSource( new EventSource(hubConfig.hubUrl.toString()) );
                });
            }
        }
        return () => {
            if (eventSource) {
                eventSource.close();
            }
            setEventSource( null );
        };
    }, [conversations]);

    useEffect(() => {
        if (eventSource) {
            eventSource.onmessage = e => {
                const payload = JSON.parse(e.data);
                if (payload.newMessage) {
                    if ( ! dispatching ) {
                        dispatching = true;
                        dispatch(chatActions.getConversations());
                        setTimeout(() => { dispatching = false; }, 500);
                    }
                }
                if (payload.isTyping) {
                    if (!buddyIsTyping[payload.conversation]) {
                        setBuddyIsTyping(buddyIsTyping => ({ ...buddyIsTyping, [payload.conversation]: true }));
                        setTimeout(() => { setBuddyIsTyping(buddyIsTyping => ({ ...buddyIsTyping, [payload.conversation]: false })) }, 2000);
                    }
                }
            };
        }
    }, [eventSource]);

    function openProfile(id) {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            showProfile: true,
            showProfileId: id,
            showSettings: false
        }
        dispatch(userActions.setLocalContext(localContextUpdated));

        setTimeout(() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }, 500);
    }

    function handleConversation(conversation) {
        let o = {person:conversation.you.uid, conversation:conversation.identifier};

        dispatch(chatActions.openConversation(o));
    }

    function handleClose() {
        setShow(false);
        setUnlikeData({});
    }

    function handleCloseBlacklist() {
        setConfirmBlaklist(false);
        setBlacklist({});
    }

    function handleLikeConfirmed() {
        if (unlikeData) {
            dispatch(userActions.pushSearch(unlikeData));
            dispatch(chatActions.deleteConversation(unlikeData.conversation));
            setUnlikeData({});
        }
        setShow(false);
    }

    function handleBlacklist(e, conversation) {
        if (e.target.parentNode.parentNode.close) {
            e.target.parentNode.parentNode.close();
        } else {
            e.target.parentNode.parentNode.parentNode.close();
        }
        setBlacklist(conversation.you);
        let o = {
            person: conversation.you.id,
            name: conversation.you.name,
            ilike: false,
            conversation:conversation.identifier
        }
        setUnlikeData(o);console.log(conversation.you);
        setConfirmBlaklist(true);
    }

    function handleBlacklistConfirmed() {
        if (blacklist) {
            setConfirmBlaklist(false);
            userService.blacklist({blocked:blacklist.id}).then(
                data => {
                    if(data.success) {
                        if (unlikeData) {
                            dispatch(userActions.pushSearch(unlikeData));
                            dispatch(chatActions.deleteConversation(unlikeData.conversation));
                            setUnlikeData({});
                        }
                    } else {
                        dispatch(alertActions.error('Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal'));
                    }
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
            setBlacklist({});
        }
        setConfirmBlaklist(false);
    }

    function handleDenounce(e, conversation) {
        if (e.target.parentNode.parentNode.close) {
            e.target.parentNode.parentNode.close();
        } else {
            e.target.parentNode.parentNode.parentNode.close();
        }
        history.push('/denounce/' + conversation.you.id);
    }

    function handleLike(e, conversation) {
        let o = {
            person: conversation.you.id,
            name: conversation.you.name,
            ilike: false,
            conversation:conversation.identifier
        }
        setUnlikeData(o);
        setShow(true);
        e.target.parentNode.parentNode.close();
    }

    return (
        <>
            <IonList>
            {conversations && conversations.map((conversation, index) =>
                <IonItemSliding key={index}>

                    <IonItem>
                        <div className="row no-gutters pt-2 w-100" style={{boxShadow:'0 4px 2px -2px rgba(0, 0, 0, 0.05)'}}>
                            <div className="position-relative col-2 p-1">
                                <a onClick={() => openProfile(conversation.you.profile.id)}>
                                    {0 < conversation.newMessages && <span className="position-absolute badge badge-pill badge-primary" style={{right: '-0.25rem'}}>{conversation.newMessages}</span>}
                                    {conversation.you.profile.profileimage && <MediaObject alt={conversation.you.profile.firstname} mediaObjectUrl={conversation.you.profile.profileimage} className="rounded-zammadoo border border-primary" style={{width:'50px',height:'50px'}} />}
                                    {!conversation.you.profile.profileimage && <img alt={conversation.you.profile.firstname} src="/profileImagePlaceholder.jpg" className="rounded-zammadoo border border-primary" style={{width:'50px',height:'50px'}} />}
                                </a>
                            </div>
                            <div className="col text-dark pl-2"
                                 style={{
                                     lineHeight: '1',
                                     maxHeight: '60px',
                                     overflow: 'hidden',
                                     textOverflow: 'ellipsis'
                                 }}
                                 onClick={() => handleConversation(conversation)}>
                                <span className="text-uppercase h5">{conversation.you.name}</span>
                                {conversation.lastpost && <span className="small"><br />{buddyIsTyping[conversation.identifier] ? '...' : conversation.lastpost}</span>}
                            </div>
                            <div className="col-2 text-right pr-2" onClick={() => handleConversation(conversation)}>
                                <button type="button" className="btn p-0">&gt;</button>
                            </div>

                        </div>
                    </IonItem>

                    <IonItemOptions side="end">
                        <IonItemOption className="bg-secondary" onClick={(e) => handleBlacklist(e, conversation)}><span style={{opacity:'0.5'}}>User<br/>blockieren</span></IonItemOption>
                        <IonItemOption className="bg-lightgray text-muted" onClick={(e) => handleDenounce(e, conversation)}><span style={{opacity:'0.5'}}>User<br/>melden</span></IonItemOption>
                        <IonItemOption className="bg-primary" onClick={(e) => handleLike(e, conversation)}>Match<br/>auflösen</IonItemOption>
                    </IonItemOptions>
                </IonItemSliding>

            )}
            </IonList>
            <div className="separator" style={{height:'4.5rem'}}></div>

            {unlikeData &&
            <Modal show={show} onHide={handleClose} className="zammadoo-modal-fromtop">
                <Modal.Header className="text-center" closeButton>
                    <Modal.Title className="w-100">Match und Chat löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Möchtest Du Dein Match mit {unlikeData.name}<br/>
                    wirklich aufheben und Euren Chatverlauf<br/>
                    löschen?
                </Modal.Body>
                <Modal.Footer className="text-center justify-content-center">
                    <button className="btn btn-primary text-white rounded-pill" onClick={() => handleLikeConfirmed()}>Match auflösen</button>
                </Modal.Footer>
            </Modal>
            }

            {blacklist &&
            <Modal show={confirmBlaklist} onHide={handleCloseBlacklist} className="zammadoo-modal-fromtop">
                <Modal.Header className="text-center" closeButton>
                    <Modal.Title className="w-100">User blockieren</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Möchtest Du {blacklist.name} wirklich blockieren? Euer Chatverlauf wird dadurch gelöscht
                    und {blacklist.profile && 1===blacklist.profile.gender && 'er'}{blacklist.profile && 2===blacklist.profile.gender && 'sie'} wird
                    Dir nicht mehr als Treffer oder in der Übersicht angezeigt.
                </Modal.Body>
                <Modal.Footer className="text-center justify-content-center">
                    <button className="btn btn-primary text-white rounded-pill" onClick={() => handleBlacklistConfirmed()}>User blockieren</button>
                </Modal.Footer>
            </Modal>
            }
        </>

    );
}

export { Chat };

