export const chatConstants = {
    GETCHATSTATUS_REQUEST: 'CHAT_GETCHATSTATUS_REQUEST',
    GETCHATSTATUS_SUCCESS: 'CHAT_GETCHATSTATUS_SUCCESS',
    GETCHATSTATUS_FAILURE: 'CHAT_GETCHATSTATUS_FAILURE',

    GETCONVERSATIONS_REQUEST: 'CHAT_GETCONVERSATIONS_REQUEST',
    GETCONVERSATIONS_SUCCESS: 'CHAT_GETCONVERSATIONS_SUCCESS',
    GETCONVERSATIONS_FAILURE: 'CHAT_GETCONVERSATIONS_FAILURE',

    GETCONVERSATION_REQUEST: 'CHAT_GETCONVERSATION_REQUEST',
    GETCONVERSATION_SUCCESS: 'CHAT_GETCONVERSATION_SUCCESS',
    GETCONVERSATION_FAILURE: 'CHAT_GETCONVERSATION_FAILURE',

    DELETECONVERSATION_REQUEST: 'CHAT_DELETECONVERSATION_REQUEST',
    DELETECONVERSATION_SUCCESS: 'CHAT_DELETECONVERSATION_SUCCESS',
    DELETECONVERSATION_FAILURE: 'CHAT_DELETECONVERSATION_FAILURE',

    GETNEWMESSAGES_REQUEST: 'CHAT_GETNEWMESSAGES_REQUEST',
    GETNEWMESSAGES_SUCCESS: 'CHAT_GETNEWMESSAGES_SUCCESS',
    GETNEWMESSAGES_FAILURE: 'CHAT_GETNEWMESSAGES_FAILURE',

    POSTNEWMESSAGES_REQUEST: 'CHAT_POSTNEWMESSAGES_REQUEST',
    POSTNEWMESSAGES_SUCCESS: 'CHAT_POSTNEWMESSAGES_SUCCESS',
    POSTNEWMESSAGES_FAILURE: 'CHAT_POSTNEWMESSAGES_FAILURE',

    CHAT_MERCURE_OPEN: 'CHAT_MERCURE_OPEN',
    CHAT_LIST_RESET: 'CHAT_LIST_RESET',
};
