import { ENTRYPOINT } from '../config/entrypoint';
import { authHeader } from '../_helpers';

const MIME_TYPE = 'application/ld+json';

export const userService = {
    login,
    passwordForgotten,
    resendConfirmationLink,
    resendConfirmationSMS,
    passwordReset,
    confirm,
    confirmSms,
    logout,
    register,
    getAll,
    getProfile,
    updateProfile,
    updateProfileImage,
    deleteImage,
    getSearches,
    getSearchparams,
    getSearchagents,
    postSearch,
    pushSearch,
    postImage,
    getFeeds,
    registerApp,
    getById,
    update,
    getAppUpdateMatrix,
    deleteAccount,
    reportSession,
    denounce,
    blacklist,
    delete: _delete
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': MIME_TYPE },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${ENTRYPOINT}/login`, requestOptions)
        .then(response => {
          return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              const error = (data && data.error) || response.statusText;
              return Promise.reject(error);
            }

            return data;
          });

        })
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function confirm(dblOptIn) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': MIME_TYPE },
    };

    return fetch(`${ENTRYPOINT}/confirm/${dblOptIn}`, requestOptions)
        .then(handleResponse);
}

function confirmSms(smsdata) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': MIME_TYPE },
        body: JSON.stringify(smsdata)
    };

    return fetch(`${ENTRYPOINT}/confirm/sms`, requestOptions)
        .then(handleResponse);
}

function passwordForgotten(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': MIME_TYPE },
        body: JSON.stringify({ username })
    };

    return fetch(`${ENTRYPOINT}/pwdfgt`, requestOptions)
        .then(handleResponse);
}

function resendConfirmationLink(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': MIME_TYPE },
      body: JSON.stringify({ username })
    };

    return fetch(`${ENTRYPOINT}/loginconfirmationresend`, requestOptions)
        .then(handleResponse);
}

function resendConfirmationSMS(userdata) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': MIME_TYPE },
      body: JSON.stringify(userdata)
    };

    return fetch(`${ENTRYPOINT}/loginconfirmationresendsms`, requestOptions)
        .then(handleResponse);
}

function passwordReset(hash, password, confirmPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': MIME_TYPE },
        body: JSON.stringify({ hash, password, confirmPassword })
    };

    return fetch(`${ENTRYPOINT}/pwdrst`, requestOptions)
        .then(handleResponse);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('highlighters');
    localStorage.removeItem('appContext');
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
        body: JSON.stringify(user)
    };

    return fetch(`${ENTRYPOINT}/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': MIME_TYPE },
        body: JSON.stringify(user)
    };

    return fetch(`${ENTRYPOINT}/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/users/${id}`, requestOptions).then(handleResponse);
}

function deleteAccount() {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/api/killme`, requestOptions).then(handleResponse);
}

function getProfile(profileId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  const extendUrl = profileId ? '/' + profileId : '';

  return fetch(`${ENTRYPOINT}/api/profile${extendUrl}`, requestOptions).then(handleResponse);
}

function updateProfile(profile) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE },
    body: JSON.stringify(profile)
  };

  return fetch(`${ENTRYPOINT}/api/profile`, requestOptions).then(handleResponse);
}

function updateProfileImage(image) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE },
    body: JSON.stringify(image)
  };

  return fetch(`${ENTRYPOINT}/api/profileImage`, requestOptions).then(handleResponse);
}

function deleteImage(image) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE },
    body: JSON.stringify(image)
  };

  return fetch(`${ENTRYPOINT}/api/profileImage`, requestOptions).then(handleResponse);
}

function getSearches() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${ENTRYPOINT}/api/searches`, requestOptions).then(handleResponse);
}

function getSearchparams() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${ENTRYPOINT}/api/searchparams`, requestOptions).then(handleResponse);
}

function getSearchagents() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${ENTRYPOINT}/api/searchagents`, requestOptions).then(handleResponse);
}

function pushSearch(search) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
    body: JSON.stringify(search)
  };

  return fetch(`${ENTRYPOINT}/api/search`, requestOptions).then(handleResponse);
}

function postSearch(search) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
    body: JSON.stringify(search)
  };

  if (3 === search.type) {
    return fetch(`${ENTRYPOINT}/api/beepme`, requestOptions).then(handleResponse);
  }
  return fetch(`${ENTRYPOINT}/api/search`, requestOptions).then(handleResponse);
}

function getFeeds(coords) {
    const requestOptions = {
        method: 'POST',
      headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
      body: JSON.stringify(coords)
    };

    return fetch(`${ENTRYPOINT}/api/feeds`, requestOptions).then(handleResponse);
}

function postImage(imageForm) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Accept': 'application/json' },
    body: imageForm
  };

  return fetch(`${ENTRYPOINT}/media_objects`, requestOptions).then(handleResponse);
}

function registerApp(appContext) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
    body: JSON.stringify(appContext)
  };

  return fetch(`${ENTRYPOINT}/api/registerApp`, requestOptions).then(handleResponse);
}

function getAppUpdateMatrix() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ENTRYPOINT}/api/updateApp`, requestOptions).then(handleResponse);
}

function reportSession(statu) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (statu) {
      return fetch(`${ENTRYPOINT}/api/countSess/on`, requestOptions).then(handleResponse);
    } else {
      return fetch(`${ENTRYPOINT}/api/countSess/off`, requestOptions).then(handleResponse);
    }
}

function denounce(denounceData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
    body: JSON.stringify(denounceData)
  };

  return fetch(`${ENTRYPOINT}/api/denounce`, requestOptions).then(handleResponse);

}

function blacklist(blocked) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': MIME_TYPE, 'Accept': MIME_TYPE },
    body: JSON.stringify(blocked)
  };

  return fetch(`${ENTRYPOINT}/api/blacklist`, requestOptions).then(handleResponse);

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || (data && data['hydra:description']) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
