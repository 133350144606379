import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { userActions } from '../_actions';
import {MediaObject} from "../_components/MediaObject";

function Profile({ match }) {
    const localContext = useSelector(state => state.context.local);
    const profile = useSelector(state => state.profile);
    const dispatch = useDispatch();
    const { profileId } = match.params;

    useEffect(() => {
        let localContextUpdated = {};
        if (localContext) {
            localContextUpdated = localContext;
        }
        localContextUpdated = {
            ...localContextUpdated,
            title: <h3 className="text-uppercase pt-2">Profil</h3>
        }
        dispatch(userActions.setLocalContext(localContextUpdated));
    }, []);

    useEffect(() => {
        dispatch(userActions.getProfile(profileId));
    }, []);

    function age(dateString){
        const birthConst = new Date(dateString);
        let birth = new Date(dateString);
        let now = new Date();
        let beforeBirth = 0;
        birth.setMonth(now.getMonth());
        birth.setDate(now.getDate());
        beforeBirth = birth.getTime() < birthConst.getTime() ? 1 : 0;
        return now.getFullYear() - birth.getFullYear() - beforeBirth;
    }

    return (
        <div className="pt-2">
            {profile.loading && <em>Loading Data...</em>}
            {profile.profileData &&
            <div style={{position:'relative'}}>
                <div className="row no-gutters p-1">
                    <div className="col-2 col-md-1 text-center">
                        {profile.profileData.profileimage && <MediaObject alt={profile.profileData.firstname} mediaObjectUrl={profile.profileData.profileimage} className="rounded-zammadoo profile border border-primary" />}
                        {!profile.profileData.profileimage && <img alt={profile.profileData.firstname} src="/profileImagePlaceholder.jpg" className="rounded-zammadoo profile border border-primary" />}
                    </div>
                    <div className="col my-auto">
                        <h4 className="text-uppercase">{profile.profileData.firstname} {age(profile.profileData.birthday)}</h4>
                    </div>
                    <div className="col-3 col-md-2  my-auto text-right">
                        {!profileId && <Link className="btn btn-outline-primary p-0 m-0" to="/editProfile"><EditIcon /></Link>}
                    </div>
                </div>
                {profile.profileData && profile.profileData.images.map((image, index) => {
                    return <div key={index}><MediaObject mediaObjectUrl={image} className="w-100 h-auto" /></div>
                })}
                <div className="profile-axiom fixed-bottom" style={{backgroundColor:'rgba(0, 0, 0, 0.75)'}}>
                    <div className="overflow-auto" style={{height: '115px'}}>
                        <h5 className="text-white p-3 mb-2">{profile.profileData.axiom}</h5>
                    </div>
                    <div className="separator" style={{height:'4.5rem'}}></div>
                </div>
            </div>
            }
            <div className="separator profile-view" style={{marginBottom:'12.5rem'}}></div>
        </div>
    );
}

export { Profile };
